import { Link, useLocation, useNavigate } from "react-router-dom";
import { CooperativeKYC } from "../../Pages/App/Dashboard/DashboardIndex";
import { useState } from "react";

const NavLink = ({
  children,
  icon = "",
  to,
  isKyc = 1,
  isComingSoon,
  ...props
}) => {
  if (isComingSoon) {
    to = "#";
  }

  if (!isKyc) {
    to = "#";
  }

  const location = useLocation();

  return (
    <>
      <Link
        className={`w-full border border-[#590da41a] rounded h-[40px] relative capitalize flex items-center justify-start pl-[14.96px] font-[500] text-[14px] leading-[21px] hover:text-[#590da4] hover:rounded-[8px] hover:bg-[#e5dced] transition duration-300 ease-in-out cursor-pointer ${
          location.pathname === props.to
            ? "rounded-[8px] text-[#590da4] font-[700]"
            : ""
        }`}
        to={to}
        {...props}
      >
        <div className="flex items-center font-bold text-fedacash-header">
          <img src={icon} className="w-5 " alt="" />
          <p className="ml-[16px] whitespace-normal w-full">{children}</p>
        </div>
        {isComingSoon && (
          <span className="absolute top-0 right-0 text-xs bg-yellow-100/70 text-yellow-700 rounded-full px-1.5 py-0.5">
            Coming Soon
          </span>
        )}
        {!isKyc && (
          <span className="absolute top-0 right-0 text-xs bg-blue-100/70 text-blue-700 rounded-full px-1.5 py-0.5">
            KYC Pending
          </span>
        )}
      </Link>
      {/* <CooperativeKYC isOpen={isOpen} toggleModal={toggleModal} /> */}
    </>
  );
};

export default NavLink;

//This one should work for loan and cooperative kyc
export const LoanKYCLink = ({
  children,
  icon = "",
  to,
  isKyc = 1,
  isComingSoon,
  ...props
}) => {
  const navigate = useNavigate();

  //state to handle state modal
  const [isOpen, setIsOpen] = useState(false);
  function toggleModal() {
    setIsOpen((prev) => !prev);
  }

  const handleVerificationClick = () => {
    if (!isKyc) {
      toggleModal();
      // console.log("no kyc", isKyc, isOpen);
    } else {
      navigate(to);
      // console.log("kyc", isKyc);
    }
  };

  const location = useLocation();

  return (
    <>
      <button
        onClick={handleVerificationClick}
        className={`w-full border border-[#590da41a] rounded h-[40px] relative capitalize flex items-center justify-start pl-[14.96px] font-[500] text-[14px] leading-[21px] hover:text-[#590da4] hover:rounded-[8px] hover:bg-[#e5dced] transition duration-300 ease-in-out cursor-pointer ${
          location.pathname === props.to
            ? "rounded-[8px] text-[#590da4] font-[700]"
            : ""
        }`}
      >
        <div className="flex items-center font-bold text-fedacash-header">
          <img src={icon} className="w-5 " alt="" />
          <p className="ml-[16px] whitespace-normal w-full">{children}</p>
        </div>
        {isComingSoon && (
          <span className="absolute top-0 right-0 text-xs bg-yellow-100/70 text-yellow-700 rounded-full px-1.5 py-0.5">
            Coming Soon
          </span>
        )}
        {!isKyc && (
          <span className="absolute top-0 right-0 text-xs bg-blue-100/70 text-blue-700 rounded-full px-1.5 py-0.5">
            KYC Pending
          </span>
        )}
      </button>

      <CooperativeKYC isOpen={isOpen} toggleModal={toggleModal} />
    </>
  );
};
