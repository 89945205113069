const TextArea = ({ label, error, ...props }) => {
    return (
        <div className="flex flex-col w-full justify-start items-start relative mb-5 max-h-fit">
            <label className="mb-1 capitalize text-gray-700 text-base w-full" style={{ fontWeight: 700, fontSize: '18px' }}>
                {label}
            </label>
            <div className="flex items-center mt-1 justify-start w-full border-none">
                <textarea
                    className="px-4 form-input focus:outline-none text-gray-800  tracking-wide border bg-transparent focus:border-purple-800 py-4 text-base placeholder:text-gray-400 font-semibold placeholder:capitalize w-full"
                    {...props}
                ></textarea>
            </div>
            <div
                className={`text-red-500 text-sm font-semibold tracking-wide ${error ? '' : 'text-transparent'}`}
            >
                {Array.isArray(error) && error.length > 1 && (
                    <ul className="list-disc ml-5">
                        {error.map((e, k) => (
                            <li key={k} >{e}</li>
                        ))}
                    </ul>
                )}
                {Array.isArray(error) && error.length === 1 && error}
            </div>
        </div>
    )
};

export default TextArea;