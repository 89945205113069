import { json, redirect } from "react-router-dom";
import {
  assert,
  object,
  string,
  nonempty,
  StructError,
  number,
  coerce,
  pattern,
  refine,
} from "superstruct";
import { z } from 'zod';

import http from "../../Store/baseHttp";
import ResponseObject from "../../DataObjects/ResponseObject";
import UserObject from "../../DataObjects/UserObject";

export const CompleteOnboard = async (data) => {
  const { request, params } = data;
  // console.log("params",params)
  try {
    const userdata = Object.fromEntries(await request.formData());
    let onboardResponse = await http.post(
      "/salaryadvance/staff/onboard/complete",
      userdata
    );
    onboardResponse = ResponseObject.from(onboardResponse);
    return onboardResponse;
  } catch (error) {
    console.error(error);
    let onboardResponseError = ResponseObject.from(error?.response);
    // console.log("onboard error", onboardResponseError);
    return onboardResponseError;
  }
};
// Define a custom string to number coercion function
const parseNumber = (val) => {
  const parsed = parseFloat(val);
  return isNaN(parsed) ? undefined : parsed;
};
// Define a custom type for NumberString
const NumberString = z.preprocess(
  z.string(),
  parseNumber
);
// Define a schema for positive numbers
const Positive = z
  .preprocess(NumberString, (val) => {
    if (val >= 1000 && val <= 50000) {
      return val;
    } else {
      throw new Error('The amount must be at least 1000 or at most 50000 Naira');
    }
  })
  .optional();

// Define a schema for account numbers
const AccountNumber = z.string().regex(/^\d{10}$/);

// Define the form schema
const formSchema = z.object({
  amount: Positive.refine((val) => val !== undefined, 'Amount is required'),
  description: z.string().min(1, 'Description is required'),
  duration: z.string().min(1, 'Duration is required'),
  repayment_amount: z.number(),
  interest_given: z.number(),
  bank_name: z.string().min(1, 'Bank name is required'),
  bank_code: z.string(),
  recipient_account_name: z.string(),
  recipient_account_number: AccountNumber.optional(),
});

export const SalaryAdvanceRequest =
  (queryClient) =>
  async ({ request }) => {
    let formData = Object.fromEntries(await request.formData());
    // const validatedData = formSchema.parse(formData);
    // Handle valid data
    // console.log('Valid data:', validatedData);
    try {
      let createLoanRequestResponse = await http.post(
        "/loan/add/salary/request",
        formData
      );
      createLoanRequestResponse = ResponseObject.from(
        createLoanRequestResponse
      );
      queryClient.invalidateQueries({ queryKey: ["loanrequest", 'loantable'] });
      return { createLoanRequestResponse };
    } catch (error) {
      console.error(error);
      let createLoanRequestResponse = ResponseObject.from(error?.response);
      return { createLoanRequestResponse };
    }
  };

export const SalaryRequestQuery = () => ({
  queryKey: ["salaryrequest", "salarytable"],
  queryFn: async () => {
    let LoanRequestResponse;
    try {
      LoanRequestResponse = await http.get("/loan/my/salaryadvance/requests");
      let bankDetails = await http.get("/user/list-bank-details");
      let companyProfile = await http.get("/salaryadvance/company/profile");

      bankDetails = ResponseObject.from(bankDetails);
      LoanRequestResponse = ResponseObject.from(LoanRequestResponse);
      companyProfile = ResponseObject.from(companyProfile);

      return { LoanRequestResponse, bankDetails, companyProfile };
    } catch (error) {
      console.error(error);
      LoanRequestResponse = ResponseObject.from(error?.response);
      return { LoanRequestResponse };
    }
  },
  staleTime: 1000 * 40,
});
export const SalaryRequestLoader =
  (queryClient) =>
  async ({}) => {
    const { role } = UserObject.getUser();
    if (
      role !== "Salary Advance HR Manager" &&
      role !== "salary advance staff"
    ) {
      return redirect("/dashboard");
    }
    const query = SalaryRequestQuery();
    // console.log("query fn", query);
    // ⬇️ return data or fetch it
    return (
      queryClient.getQueryData(query.queryKey) ??
      (await queryClient.fetchQuery(query))
    );
  };
