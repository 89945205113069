function formatNaira(amount) {
  // Check if the input is a valid number
  if (isNaN(amount)) {
    return "Invalid Amount";
  }
  // Convert the amount to a string
  const amountStr = amount.toString();

  // Split the amount into integer and decimal parts
  const parts = amountStr.split(".");

  // Format the integer part with commas
  const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Reconstruct the formatted amount with the decimal part (if exists)
  const formattedAmount = parts.length === 2 ? integerPart + "." + parts[1] : integerPart;

  // Add the Naira symbol (₦) and return the formatted amount
  return "₦" + formattedAmount;

}
function formatAsNaira(number) {
  const formatter = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  });
  return formatter.format(number);
}
// Export the function as a named export
export { formatNaira, formatAsNaira };