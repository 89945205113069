import {
  Await,
  useLoaderData,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useState, Fragment, useEffect } from "react";
import { Tab, Dialog, Transition } from "@headlessui/react";
import { usePaystackPayment } from "react-paystack";
import { AiOutlineClose } from "react-icons/ai";
/////file import
import { joinPlan } from "../../../Services/Dashboard/CooperativeService";
import { formatNaira } from "../../../Components/Utils/FormatMoney";
import paymentImage from "../../../Assets/images/payment-image.png";
import UserObject from "../../../DataObjects/UserObject";
import {
  PAYSTACK_KEY,
  calculateFinalAmount,
  calculatePaystackCharges,
} from "../../../Components/Utils/PaystackFunctions";
import { StatusDialog } from "../../../Components/Dashboard/Shared/StatusDialog";

const JoinSharedPlan = () => {
  const data = useLoaderData();
  let location = useLocation();
  let params = useParams();
  useEffect(() => {
    const addToLocalStorage = (token, redirectUrl) => {
      const data = { token, redirectUrl };
      localStorage.setItem("myData", JSON.stringify(data));
    };
    addToLocalStorage(params.token, location.pathname);
  }, []);

  return (
    <section>
      <section>
      <PlanModal plan={data?.SharedPlanResponse?.data} />
      </section>
    </section>
  );
};

export default JoinSharedPlan;
const Overview = () => {
  return (
    <section>

    </section>
  );
};
const PlanModal = ({ plan }) => {
  const navigate = useNavigate();
  const [isStatusOpen, setIsStatusOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [message, setMessage] = useState("");
  const [checked, setChecked] = useState(true);
  function closeStatusModal() {
    setIsStatusOpen(false);
    setChecked(false);
  }
  function togglePlanModal() {
    setIsOpen((prev) => !prev);
  }
  function closePlanModal() {
    togglePlanModal();
    setChecked(false);
  }
  function openStatusModal() {
    setIsStatusOpen(true);
  }

  const config = {
    reference: new Date().getTime().toString(),
    email: UserObject.getUser().email,
    amount: parseInt(calculateFinalAmount(plan?.amount_to_contribute) * 100), //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: PAYSTACK_KEY,
    channels: ["card"],
  };
  const removeRedirectUrlFromLocalStorage = () => {
    localStorage.removeItem('myData');
  };
  const onSuccess = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    joinPlan({
      package_token: plan?.token,
      amount_to_contribute: plan?.amount_to_contribute,
      reference: reference.reference,
    }).then((res) => {
      setMessage(res);
      openStatusModal();
      closePlanModal();
      removeRedirectUrlFromLocalStorage()
      navigate("/dashboard/cooperative/saveandpack");
    });
  };

  // you can call this function anything
  const onPaystackClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    // console.log("closed");
  };
  const initializePayment = usePaystackPayment(config);
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={togglePlanModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-fedacash-header bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full rounded-md max-w-xl transform overflow-hidden bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="flex gap-3 justify-evenly text-lg font-bold leading-6 text-[#475467]"
                  >
                    <span>Join {plan?.title}</span>
                    <button
                      type="button"
                      className=""
                      onClick={() => togglePlanModal()}
                    >
                      <AiOutlineClose />
                    </button>
                  </Dialog.Title>
                  <div className="flex flex-col justify-center items-center">
                    <img src={paymentImage} alt="" />
                  </div>
                  <div className="mt-2">
                    <p className="font-bold text-[#98A2B3] text-center">
                      Join Save and Pack Scheme
                    </p>
                  </div>

                  <div className="mt-4 text-sm font-medium divide-y divide-black">
                    <div className="flex justify-between py-2">
                      <p>Amount</p>
                      <p>{formatNaira(plan?.total_savings_amount)}</p>
                    </div>
                    <div className="flex justify-between py-2">
                      <p>Contribution</p>
                      <p>{formatNaira(plan?.amount_to_contribute)}</p>
                    </div>
                    <div className="flex justify-between py-2">
                      <p>Charges</p>
                      <p>
                        {formatNaira(
                          calculatePaystackCharges(plan?.amount_to_contribute)
                        )}
                      </p>
                    </div>
                    <div className="flex justify-between py-2">
                      <p>Duration</p>
                      <p>{`${plan?.duration} ${durationToTime(
                        plan?.contribution_frequency
                      )}`}</p>
                    </div>
                    <div className="py-2">
                      <p className="max-w-xs">
                        Please note that you would not be debited until the
                        package plan starts
                      </p>
                    </div>
                  </div>
                  <div className="flex gap-x-3 mt-3">
                    <input
                      type="checkbox"
                      value={checked}
                      onChange={() => setChecked((prev) => !prev)}
                    />
                    I agree to be debited instantly for this Contribution
                  </div>
                  <div className="mt-4">
                    <button
                      onClick={() =>
                        initializePayment(onSuccess, onPaystackClose)
                      }
                      disabled={checked}
                      className="text-white bg-fedacash-header text-center w-full py-3 px-2 rounded-md"
                    >
                      Proceed
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <StatusDialog
        closeModal={closeStatusModal}
        isOpen={isStatusOpen}
        message={message}
      />
    </>
  );
};
//hoisted
function durationToTime(durations) {
  switch (durations) {
    case "monthly":
      return "months";
    case "weekly":
      return "weeks";
    case "daily":
      return "days";
    case "annually":
      return "years";
    default:
      return null;
  }
}
