export const EmptyState = ({ imagesrc, text, children }) => {
  return (
    <section>
      <div className="flex flex-col items-center justify-center py-12">
        <div className="flex flex-col justify-center items-center text-center max-w-lg">
          <p className="font-medium py-4">{text}</p>
          {children}
        </div>
        <img
          src={imagesrc}
          alt=""
          className="w-64 h-64"
          width={224}
          height={224}
        />
      </div>
    </section>
  );
};
