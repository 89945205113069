import {
  assert,
  object,
  string,
  nonempty,
  StructError,
  number,
  coerce,
  pattern,
  refine,
} from "superstruct";

import { json } from "react-router-dom";

import http from "../../Store/baseHttp";
import ResponseObject from "../../DataObjects/ResponseObject";

export const loanRequestQuery = (id) => ({
  queryKey: ["singleLoan", id],
  queryFn: async (id) => {
    // console.log("useparams", id);
    const paramsId = id.queryKey[1];
    // console.log("useparams", id);
    try {
      const response = await http.get(`/loan/my/request/${paramsId}`);
      // console.log(response);
      return ResponseObject.from(response);
    } catch (error) {
      // console.log(error);

      // console.log("error2", error);
      throw ResponseObject.from(error?.response);
    }
  },
});
//TODO://change the name of this function
export const mainLoanRequestQuery = () => ({
  queryKey: ["loanrequest", "loantable"],
  queryFn: async () => {
    let LoanRequestResponse;
    try {
      LoanRequestResponse = await http.get("/loan/my/requests");
      let paidLoans = await http.get("/loan/my/paid/requests ");
      let bankDetails = await http.get("/user/list-bank-details");

      bankDetails = ResponseObject.from(bankDetails);
      LoanRequestResponse = ResponseObject.from(LoanRequestResponse);
      paidLoans = ResponseObject.from(paidLoans);

      return { LoanRequestResponse, paidLoans, bankDetails };
    } catch (error) {
      console.error(error);
      LoanRequestResponse = ResponseObject.from(error?.response);
    }
  },
  staleTime: 1000 * 40
});
export const LoanRequestLoader =
  (queryClient) =>
  async ({}) => {
    const query = mainLoanRequestQuery();
    // console.log("query fn", query);
    // ⬇️ return data or fetch it
    return (
      queryClient.getQueryData(query.queryKey) ??
      (await queryClient.fetchQuery(query))
    );
  };

// ⬇️ needs access to queryClient
export const SingleLoanRequestLoader =
  (queryClient) =>
  async ({ params }) => {
    const query = loanRequestQuery(params.id);
    // ⬇️ return data or fetch it
    const fetchedData = await queryClient.fetchQuery(query);
    // console.log("fetched Data", fetchedData);
    return (
      queryClient.getQueryData(query.queryKey) ??
      (await queryClient.fetchQuery(query))
    );
  };

// Define a custom type for numbers that are stored as strings
const NumberString = coerce(string(), number(), (value) => {
  parseFloat(value);
});
// Define a schema for positive numbers
const AccountNumber = pattern(string(), /^\d{10}$/);
const Positive = refine(NumberString, "positive", (value) => {
  if (value >= 1000 || value > 50000) {
    return true; // Return the value if it meets the condition
  } else {
    return "The amount must be at least 1000 or at most 50000 Naira"; // Throw an error otherwise
  }
});

const formSchema = object({
  amount: nonempty(Positive),
  description: nonempty(string(), "This Field is Required"),
  duration: nonempty(string()),
  repayment_amount: nonempty(string()),
  interest_given: string(),
  bank_name: nonempty(string()),
  bank_code: string(),
  recipient_account_name: string(),
  recipient_account_number: AccountNumber,
});
export const CreateLoanRequest = (queryClient) => async (formvalues) => {
  // const form = await request.formData();
  // const formToJSON = {};
  // for (const [key, value] of [...form.entries()]) {
  //   formToJSON[key] = value;
  // }
  // console.log("formvalue", formToJSON);
  // try {
  //   assert(formToJSON, formSchema);
  // } catch (err) {
  //   if (err instanceof StructError) {
  //     const fieldsErrors = err.failures().reduce(
  //       (acc, { key, message }) => ({
  //         ...acc,
  //         [key]: message,
  //       }),
  //       {}
  //     );
  //     console.log(fieldsErrors)
  //     return json(fieldsErrors);
  //   }
  //   // console.error(err);
  // }

  try {
    let createLoanRequestResponse = await http.post(
      "/loan/add/request",
      formvalues
    );

    createLoanRequestResponse = ResponseObject.from(createLoanRequestResponse);
    queryClient.invalidateQueries({ queryKey: ["loanrequest", "loantable"] });

    return { createLoanRequestResponse };
  } catch (error) {
    console.error(error);
    let createLoanRequestResponse = ResponseObject.from(error?.response);
    return { createLoanRequestResponse };
  }
};
export const SendLoanRequest = async (formvalues) => {
  try {
    let createLoanRequestResponse = await http.post(
      "/loan/add/request",
      formvalues
    );

    createLoanRequestResponse = ResponseObject.from(createLoanRequestResponse);
    // queryClient.invalidateQueries({ queryKey: ["loanrequest", 'loantable'] });

    return { createLoanRequestResponse };
  } catch (error) {
    console.error(error);
    let createLoanRequestResponse = ResponseObject.from(error?.response);
    return { createLoanRequestResponse };
  }
};

export const LoanRepayment = async (data) => {
  try {
    let RepaymentResponse = await http.post("/loan/repayment", data);
    RepaymentResponse = ResponseObject.from(RepaymentResponse);
    return RepaymentResponse;
  } catch (e) {
    return ResponseObject.from(e?.response);
  }
};
