import React from "react";
import { Link, Outlet } from "react-router-dom";
import Footer from "../../../Components/ui/footer/index.footer";
import Navbar from "../../../Components/ui/navbar/index.navbar";

const Layout = () => {
  return (
    <main className="font-montserrat bg-[#F7F9FC]">
      <Navbar/>
      <Outlet />
      <Footer />
    </main>
  );
};

export default Layout;
