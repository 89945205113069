import { Link, useFetcher, useFormAction, useNavigate } from "react-router-dom";
import Input from "../../Components/Forms/Input";
import Submit from "../../Components/Forms/Submit";
import { useEffect, useState } from "react";
import HeaderLogo from "../../Components/LandingPage/HeaderLogo";
import useTitle from "../../Hooks/useTitle";
import UserObject from "../../DataObjects/UserObject";
import useForm from "../../Hooks/useForm";

const ForgetPassword = () => {
  useTitle("Forgot Password | FedaCash SMEs");
  const { formAction, data, state, Form, formData } = useFetcher();

  const initialState = {
    email: "",
  };
  const [form, setForm] = useForm(initialState);

  const navigate = useNavigate();

  useEffect(() => {
    if (data?.success) {
      navigate("/auth/password-reset-verify?email=" + form?.email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return (
    <div className="w-full h-full">
      <HeaderLogo />
      <div className="md:mb-60 mb-20 pt-10 mt-6">
        <Link to="/">
          <span style={{ color: "#590DA4", fontSize: "18px", fontWeight: 800 }}>
            Go Back Home
          </span>
        </Link>
      </div>
      <div className="my-10">
        <h1
          className="mb-20 mt-5"
          style={{ fontSize: "42px", fontWeight: 800 }}
        >
          Forgot Password
        </h1>
      </div>

      <Form method="post" action={formAction} formAction={useFormAction("put")}>
        <Input
          onChange={setForm}
          label="Email address"
          name="email"
          placeholder="Enter your email"
          error={data?.errors?.email}
        />
        <Submit isLoading={state !== "idle"}>Reset Password</Submit>
      </Form>
    </div>
  );
};

export default ForgetPassword;
