import targetMarket from "../../Assets/images/target-market.jpg";
import cooperative from "../../Assets/images/cooperative-image.jpg";
import SME from "../../Assets/images/sme-image.jpg";
import SME2 from "../../Assets/images/sme-image2.jpg";

import { FADE_UP_ANIMATION_VARIANTS } from "../../utils/framer-variants";

////
import { useRef } from "react";
import { motion, useInView } from "framer-motion";

const TargetMarket = () => {
  const targetRefOne = useRef(null);
  const isInView = useInView(targetRefOne, { once: true });
  const targetRefTwo = useRef(null);
  const isInViewTwo = useInView(targetRefTwo, { once: true });
  const targetRefThree = useRef(null);
  const isInViewThree = useInView(targetRefThree, { once: true });
  return (
    <section className="mt-6">
      <motion.section
        className=" mt-4 bg-white shadow-section rounded-[32px]"
        initial="hidden"
        animate={isInView ? "show" : "hide"}
        viewport={{ once: true }}
        variants={{
          hidden: {},
          show: {
            transition: {
              staggerChildren: 0.15,
            },
          },
        }}
        ref={targetRefOne}
      >
        <div className="flex lg:flex-row flex-col gap-x-14 gap-y-4 lg:items-center justify-evenly overflow-hidden px-4 lg:py-6  lg:pb-24 pb-6">
          <motion.img
            src={targetMarket}
            variants={FADE_UP_ANIMATION_VARIANTS}
            alt="target Market"
            className="lg:w-1/2 rounded-[20px] lg:!rotate-[-12.01deg]"
          />
          <div className="flex flex-col gap-3 max-w-xl lg:w-1/2">
            <motion.h3
              variants={FADE_UP_ANIMATION_VARIANTS}
              className="lg:text-3xl md:text-2xl text-sm font-semibold text-fedacash-header"
            >
              Nano/Micro Enterprises
            </motion.h3>
            <motion.p
              variants={FADE_UP_ANIMATION_VARIANTS}
              className="md:text-lg text-xs"
            >
              The majority of NMEs in Nigeria face survival challenges, with
              66.7% identifying start-up and working capital as major hurdles.
              FedaCash focuses on this segment, offering micro-working capital
              finance to ensure sustainable inventory levels.
            </motion.p>
          </div>
        </div>
      </motion.section>
      <section className="flex flex-col gap-6">
        <motion.section
          className=" bg-white shadow-section rounded-[32px] mt-4"
          initial="hidden"
          animate={isInViewTwo ? "show" : "hide"}
          viewport={{ once: true }}
          variants={{
            hidden: {},
            show: {
              transition: {
                staggerChildren: 0.15,
              },
            },
          }}
          ref={targetRefTwo}
        >
          <div className="flex lg:flex-row flex-col gap-x-14 gap-y-4 lg:items-center justify-evenly overflow-hidden px-4 lg:py-16  lg:pb-24 pb-6">
            <div className="flex justify-evenly lg:justify-between lg:w-1/2 px-6 lg:py-12 py-6 rounded gray-bg">
              <div className="w-24 lg:w-60 bg-white">
                <motion.img
                  src={SME}
                  alt="target Market"
                  className=""
                  variants={FADE_UP_ANIMATION_VARIANTS}
                />
                <div className="my-4 flex flex-col gap-y-4 items-center justify-center text-[10px] lg:text-base">
                  <p className="text-white bg-[#6936F5] p-1 rounded-full text-[8px] lg:text-xs">
                    Emma & Tim
                  </p>
                  <p className="font-medium">E&T Bakery</p>
                  <p>Mon-Fri</p>
                </div>
              </div>
              <div className="w-24 lg:w-60 bg-white">
                <motion.img
                  src={SME}
                  alt="target Market"
                  className=""
                  variants={FADE_UP_ANIMATION_VARIANTS}
                />
                <div className="my-4 flex flex-col gap-y-4 items-center justify-center text-[10px] lg:text-base">
                  <p className="text-white bg-[#6936F5] p-1 rounded-full text-[8px] lg:text-xs">
                    Joan
                  </p>
                  <p className="font-medium">ACH Business</p>
                  <p>Mon-Fri</p>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-y-6 max-w-3xl lg:w-1/2">
              <motion.h3
                variants={FADE_UP_ANIMATION_VARIANTS}
                className="lg:text-3xl md:text-2xl text-sm font-semibold text-fedacash-header"
              >
                Small/Medium Enterprises (SMEs)
              </motion.h3>
              <motion.p
                variants={FADE_UP_ANIMATION_VARIANTS}
                className="md:text-lg text-xs"
              >
                Building on our SME community, we provide invoice discounting
                solutions for instant liquidity. By removing cash flow
                constraints, SMEs can expand and scale their operations more
                easily.
              </motion.p>
            </div>
          </div>
        </motion.section>
        <motion.section
          initial="hidden"
          animate={isInViewThree ? "show" : "hide"}
          viewport={{ once: true }}
          variants={{
            hidden: {},
            show: {
              transition: {
                staggerChildren: 0.15,
              },
            },
          }}
          className="mt-4 bg-white shadow-section rounded-[32px]"
          ref={targetRefThree}
        >
          <div className="flex lg:flex-row flex-col gap-x-14 gap-y-4 lg:items-center justify-evenly overflow-hidden px-4 lg:py-6  lg:pb-24 pb-6">
            <motion.img
              variants={FADE_UP_ANIMATION_VARIANTS}
              src={cooperative}
              alt="target Market"
              className="rounded-[20px] lg:w-1/2 lg:!rotate-[-12.454deg]"
            />
            <div className="flex flex-col gap-y-4 gap-x-6 max-w-2xl lg:w-1/2">
              <motion.h3
                variants={FADE_UP_ANIMATION_VARIANTS}
                className="lg:text-3xl md:text-2xl text-sm font-semibold text-fedacash-header"
              >
                Large Corporations
              </motion.h3>
              <motion.p
                className="md:text-lg text-xs"
                variants={FADE_UP_ANIMATION_VARIANTS}
              >
                FedaCash partners with large corporations (anchors) to encourage
                their SME vendors to use our platform. This collaboration
                streamlines payments and promotes financial inclusion, leading
                to growth and a robust vendor ecosystem.
              </motion.p>
            </div>
          </div>
        </motion.section>
      </section>
    </section>
  );
};

export default TargetMarket;
