import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
///file import
import { products } from "./constants";
// Import Swiper styles
import "swiper/css";
import "swiper/css/autoplay";

const Products = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === products.length - 1 ? 0 : prevSlide + 1
    );
  };

  const handlePrevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? products.length - 1 : prevSlide - 1
    );
  };

  return (
    <div className="flex flex-col w-full">
      <section className="flex overflow-hidden">
        <Swiper
          slidesPerView={"auto"}
          spaceBetween={100}
          modules={[Autoplay]}
          autoplay={{ delay: 8000 }}
        >
          {products.map((product, index) => {
            return (
              <SwiperSlide key={index}>
                {" "}
                <section className="flex flex-col gap-y-8 bg-white shadow-section rounded-xl py-12 px-2 lg:mt-8 transition-all">
                  <div className="flex flex-col lg:flex-row items-center justify-evenly gap-y-4 md:gap-x-12 gap-x-4 lg:px-24 md:px-8 px-3">
                    <img
                      src={product.image}
                      alt="Invoice Product screen"
                      className="lg:w-1/2 bg-[#F7F9FC]"
                    />
                    <div className="flex flex-col gap-y-4 gap-x-8 lg:w-1/2">
                      <h3 className="text-fedacash-header md:text-3xl text-xs font-medium">
                        {product.name}
                      </h3>
                      <p className="lg:text-lg md:text-base text-[10px]">
                        {product.description}
                      </p>
                      <div>
                        <button className="md:text-base text-[8px] text-left text-fedacash-header font-medium border border-fedacash-header rounded-lg md:py-4 p-2 md:px-8">
                          Learn More
                        </button>
                      </div>
                    </div>
                  </div>
                </section>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </section>
      <div className="hidden w-full justify-between gap-10 mt-5">
        <div className="justify-start items-start gap-2 flex">
          <div className="w-2 h-2 bg-black rounded-full" />
          <div className="w-2 h-2 bg-gray-400 rounded-full" />
          <div className="w-2 h-2 bg-gray-400 rounded-full" />
        </div>
        <div className="justify-start items-start gap-3.5 flex">
          <div
            className="p-3 rounded-3xl border border-gray-300 justify-center items-center gap-2 flex"
            onClick={handlePrevSlide}
          >
            <AiOutlineArrowLeft />
          </div>
          <div
            className="p-3 rounded-3xl border border-gray-300 justify-center items-center gap-2 flex"
            onClick={handleNextSlide}
          >
            <AiOutlineArrowRight />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
