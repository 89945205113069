import { Link, Outlet, useLocation } from "react-router-dom";
import Img from "./../Assets/images/auth.png";
import LoginScreen from "./../Assets/images/login-screen.png";
import SignupScreen from "./../Assets/images/signup-screen.png";
import Logo from "./../Assets/images/fedacash-white-logo.png";


import ScrollToTop from "../Components/LandingPage/NavBar/ScrollToTop";
import Toast from "../Components/Dashboard/Notification/Toast";

const AuthLayout = () => {
  const {pathname} = useLocation();
  const paths =['/auth/verify', '/auth/resend', '/auth/complete/onboard/:id', '/auth/register']
  const getPath =paths?.includes(pathname)
  // console.log(paths.includes(pathname),pathname);
  return (
    <div className="font-montserrat grid grid-cols-1 lg:grid-cols-2 h-screen w-full overflow-hidden">
      <Toast />
      <div className="products-bg w-full border-2 h-full p-5 bg-[#380b64] hidden lg:block lg:col-span-1">
        <ScrollToTop />
        <div className="">
            <img src={Logo} alt="White Logo"/>
          {" "}
          {
            getPath ?  (<Signup/>):(<Login/>)
          }
        </div>
      </div>
      <div className="h-screen overflow-auto">
        <div className="w-full px-5">
          <div className="max-w-md px-10 md:p-5 mx-auto">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;

const Signup = () => {
  return (
    <div className="flex flex-col my-32 gap-y-10 items-center justify-center">
      {" "}
      <h2 className="text-3xl font-light text-white">
        Create An Account with us
      </h2>
      <Link to="/">
        <img src={SignupScreen} alt="signup illustration" className="object-fill" />
      </Link>
    </div>
  );
};
const Login = () => {
  return (
    <div className="flex flex-col items-center justify-center">
      {" "}
      <Link to="/">
        <img src={LoginScreen} alt="login illustration" className="object-fill" />
      </Link>
    </div>
  );
};
