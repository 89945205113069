import invoiceFull from "../../../Assets/images/invoice-full.png";
import coop from "../../../Assets/images/coop.png";
import forum from "../../../Assets/images/forum.png";
import generateInvoice from "../../../Assets/images/generate-invoice.png";

export const products = [
  {
    name: "Generate Invoice",
    description:"Create, customize, and share invoices effortlessly. Seamlessly send invoices to your customers after signing up on FedaCash.",
    link: "services/generate-invoice",
    image: generateInvoice,
  },
  {
    name: "Invoice Discounting",
    description:"Easily access funds by uploading invoices. Receive a portion of the total value upfront, enhancing your business's cash flow.",
    link: "services/invoice-discount",
    image: invoiceFull,
  },
  {
    name: "Cooperatives",
    description:
    "Join our cooperative to harness collective resources for sustainable growth. An affordable platform for small businesses across Nigeria.",
    link: "services/cooperative",
    image: coop,
  }

];
