import React from "react";

const Skeleton = () => {
  return (
    <section className="grid grid-cols-1 xl:grid-cols-2 gap-12 mt-4">
      {Array.from({ length: 4 }).map((_, index) => (
        <Frame key={index}/>
      ))}
    </section>
  );
};

export default Skeleton;

const Frame = () => (
  <section className="bg-white rounded-md py-4 drop-shadow-xl">
    <div className="flex flex-col gap-y-8 animate-pulse py-8">
      <div className="px-4">
        <div className="flex justify-between">
          <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
        </div>
        <div className="flex gap-x-5 mt-3 text-xs">
          <p className="px-2 py-[10px] bg-gray-200 w-24 rounded-md"></p>
          <p className="px-2 py-[10px] bg-gray-200 w-24 rounded-md"></p>
          <p className="px-2 py-[10px] bg-gray-200 w-24 rounded-md"></p>
        </div>
        <div className="flex justify-between mt-3 text-xs">
          <p className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></p>
          <p className="px-2 py-[10px] bg-gray-200 w-24 rounded-md"></p>
        </div>
      </div>
    </div>
  </section>
);
