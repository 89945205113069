import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";
import { useFetcher, useActionData } from "react-router-dom";
import {
  assert,
  object,
  string,
  nonempty,
  StructError,
  number,
  coerce,
  pattern,
  refine,
} from "superstruct";
import Input from "../../Forms/Input";
import Select from "../../Forms/Select";
import Submit from "../../Forms/Submit";
import loanImage from "../../../Assets/images/loan-form-image.png";
import { Link } from "react-router-dom";
import UserObject from "../../../DataObjects/UserObject";
import { StatusDialog } from "../Shared/StatusDialog";
import ToastContext from "../../../Routes/ToastContext";
import {
  SalaryAdvanceRequest,
  SalaryRequestQuery,
} from "../../../Services/Dashboard/SalaryAdvanceService";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { BiCheck } from "react-icons/bi";

//TODO://add extra validation in the action funtion
export const SalaryAdvanceForm = () => {
  const { first_name, last_name } = UserObject.getUser();
  const { flashToast } = useContext(ToastContext);
  const { data: usequerydata } = useQuery(SalaryRequestQuery());
  const { bankDetails, companyProfile } = usequerydata;
  // console.log(bankDetails, "SalaryRequestQuery", usequerydata)
  const INTEREST_RATE = companyProfile?.data?.salary_advance_interest_rate;
  const { formAction, Form, state, data: actiondata, formData } = useFetcher();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checkbox, setCheckbox] = useState(false);
  const [bankIndex, setBankIndex] = useState("");
  const [formValues, setFormValues] = useState({
    recipient_account_number: "",
    bank_code: "",
    bank_name: "",
    recipient_account_name: "",
  });
  const [plan, setPlan] = useState({
    amount: "",
    description: "",
    duration: 30,
  });

  const onChange = (e) => {
    setPlan((plan) => ({ ...plan, [e.target.name]: e.target.value }));
  };

  function toggleCreatePlanModal() {
    setIsOpen((prev) => !prev);
  }
  function calculateInterest(amount) {
    const parsedAmount = parseFloat(amount);
    const parsedInterestRate = parseFloat(INTEREST_RATE);
    if (
      isNaN(parsedAmount) ||
      isNaN(parsedInterestRate) ||
      isNaN(plan?.duration)
    ) {
      return { repaymentAmount: 0, rate: 0 };
    }
    const months = plan?.duration / 30;
    // Calculate base monthly payment without interest
    //1000/4months =250
    const baseMonthlyPayment = parsedAmount / months;
    ///1000*(10/100)=100
    // Calculate monthly interest
    const interestAmount = parsedAmount * (parsedInterestRate / 100);
    // Total monthly payment including interest
    //250+100=350
    const totalMonthlyPayment = baseMonthlyPayment + interestAmount;
    const repaymentAmount = totalMonthlyPayment * months;
    return {
      repaymentAmount,
      rate: parsedInterestRate,
      totalMonthlyPayment,
      months,
    };
  }
  const addBankDetails = (bank, index) => {
    setBankIndex(index);
    for (const key in bank) {
      if (Object.hasOwnProperty.call(bank, key)) {
        const value = bank[key];
        // Update state for each key-value pair in the bankObject
        //dont edit this function it has a breaking change, becuse of the key it recives ffrom the bank object isnt the same as the values boject thank to our backend dev
        // console.log(key, value)
        setFormValues((formValue) => ({
          ...formValue,
          [key]: value,
        }));
      }
    }
  };
  const { repaymentAmount, rate, totalMonthlyPayment, months } =
    calculateInterest(plan.amount, plan?.duration);
  useEffect(() => {
    flashToast(
      actiondata?.createLoanRequestResponse?.success,
      actiondata?.createLoanRequestResponse?.message
    );
  }, [actiondata]);
  // console.log("action", actiondata);
  return (
    <>
      <button
        className="flex items-center gap-2 mt-2 text-sm bg-black p-2 text-white rounded-md"
        onClick={toggleCreatePlanModal}
      >
        <AiOutlinePlus />
        <span>Salary Advance Request</span>
      </button>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={toggleCreatePlanModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-fedacash-header bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full rounded-md max-w-2xl transform overflow-hidden bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="flex flex-col gap-y-3 justify-center text-center items-center text-lg font-bold leading-6 text-[#475467] py-4"
                  >
                    <span className="text-[#4B5768] font-medium text-lg md:text-xl">
                      Loan Request
                    </span>
                    <hr className="bg-fedacash-header w-32 h-1" />
                    <span className="text-sm md:text-lg font-medium">
                      How much Loan do you want to request for?
                    </span>
                  </Dialog.Title>
                  <div className="bg-[#EAECF0] rounded-2xl flex items-center justify-center py-4">
                    <img
                      src={loanImage}
                      alt=""
                      className="w-32 h-32"
                      width={288}
                      height={288}
                    />{" "}
                  </div>
                  <Form className="w-full pt-6" method="post">
                    <Input
                      label="Enter Amount"
                      placeholder="Enter Amount"
                      name="amount"
                      type="number"
                      normalize={true}
                      error={
                        actiondata?.createLoanRequestResponse?.errors?.errors
                          ?.amount
                      }
                      onChange={onChange}
                      value={plan?.amount}
                    />
                    <Input
                      label="Enter Description"
                      placeholder="Enter Description"
                      name={"description"}
                      normalize={true}
                      type="text"
                      required
                      // error={actiondata?.createLoanRequestResponse?.errors?.errors?.description}
                      // onChange={onChange}
                      // value={plan?.description}
                    />
                    <p className="mb-1 capitalize text-gray-700 w-full text-lg font-bold">
                      Loan Duration
                    </p>
                    <Select
                      label="Loan Duration"
                      placeholder="Loan Duration"
                      name={"duration"}
                      required
                      error={
                        actiondata?.createLoanRequestResponse?.errors?.errors
                          ?.duration
                      }
                      onChange={onChange}
                      value={plan?.duration}
                    >
                      <option>--Select Option--</option>
                      <option value="30">30 Days</option>
                      <option value="60">2 Months</option>
                      <option value="90">3 Months</option>
                      <option value="120">4 Months</option>
                    </Select>
                    <MonthlyRepayment
                      month={months}
                      amount={totalMonthlyPayment}
                    />
                    <Input
                      label="Total Repayment Amount"
                      placeholder="Total Repayment Amount"
                      normalize={true}
                      name={"repayment_amount"}
                      type="number"
                      readOnly
                      // onChange={onChange}
                      value={repaymentAmount ?? 0}
                      // error={actiondata?.createLoanRequestResponse?.errors?.errors?.repayment_amount}
                    />
                    <Input
                      label="Interest"
                      placeholder="interest"
                      normalize={true}
                      name={"interest_given"}
                      type="number"
                      readOnly
                      value={INTEREST_RATE ?? 0}
                      // error={errors?.errors?.interest_given}
                    />
                    <input
                      value={`${first_name} ${last_name}`}
                      name="employee_name"
                      className="hidden"
                    />
                    {/* TODO://fix hidden input */}
                    {/* I used hidden inputs to send the data to the backend beeucse I tried using formdata and appending it but it didnt work */}
                    <div className="hidden">
                      <input
                        type="text"
                        className="border rounded-md p-2 mb-2"
                        name="bank_name"
                        value={formValues.bank_name}
                      />
                      <input
                        type="text"
                        className="border rounded-md p-2 mb-2"
                        name="bank_code"
                        value={formValues.bank_code}
                      />
                      <input
                        type="text"
                        className="border rounded-md p-2 mb-2"
                        name="recipient_account_name"
                        value={formValues.account_name}
                      />
                      <input
                        type="text"
                        className="border rounded-md p-2 mb-2"
                        name="recipient_account_number"
                        value={formValues.account_number}
                      />
                    </div>
                    <BankDetails
                      {...{ addBankDetails, bankDetails, bankIndex }}
                    />
                    <div className="flex gap-x-4 items-center py-4">
                      <input
                        type="checkbox"
                        className="rounded-md"
                        onChange={() => setCheckbox((prev) => !prev)}
                        checked={checkbox}
                      />
                      <p className="font-medium">
                        View and Accept{" "}
                        <Link
                          to={`/terms-and-conditions`}
                          target="_blank"
                          className="text-fedacash-header"
                        >
                          Terms & Conditions
                        </Link>
                      </p>
                    </div>
                    <div className="flex items-end justify-end gap-x-4">
                      <button
                        onClick={(event) => {
                          event.preventDefault();
                          toggleCreatePlanModal();
                        }}
                        className="text-center w-full py-2 md:py-3 md:px-2 rounded-md text-sm lg:text-base disabled:opacity-50 border border-gray-300"
                      >
                        <span>Cancel</span>
                      </button>
                      <Submit
                        type="submit"
                        isLoading={state !== "idle"}
                        disabled={!checkbox || state !== "idle"}
                      >
                        Submit
                      </Submit>
                    </div>
                  </Form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
const BankDetails = ({ addBankDetails, bankDetails, bankIndex }) => {
  let actiondata = useActionData();

  if (!bankDetails)
    return (
      <div className="text-red-600 font-medium">Error Loading Bank Details</div>
    );
  return (
    <div className="space-y-3 max-h-[300px] overflow-y-auto">
      <h3 className="text-lg font-medium">
        Select your preferred bank account
      </h3>
      {bankDetails?.data?.bank_object?.length !== 0 ? (
        bankDetails?.data?.bank_object?.map((bank, index) => {
          return (
            <div
              className={`flex flex-row items-center gap-x-4 justify-start px-2 py-3 `}
              key={index}
              onClick={(e) => addBankDetails(bank, index)}
            >
              <div
                className={`w-4 h-4 rounded-md ${
                  bankIndex === index
                    ? "bg-fedacash-header"
                    : " border-2 border-fedacash-primary flex items-center justify-center"
                }`}
              >
                {bankIndex === index && (
                  <BiCheck className="text-white font-medium" />
                )}
              </div>
              <div
                className={`w-full p-3 rounded-md ${
                  bankIndex === index ? "bg-[#DECFED]" : "bg-[#F6F8FA]"
                }`}
              >
                <p className="text-lg font-medium">
                  {bank?.account_number}
                  {/* {console.log(bank)} */}
                </p>
                <p className="">{bank?.bank_name}</p>
                <p className="">{bank?.account_name}</p>
              </div>
            </div>
          );
        })
      ) : (
        <div>
          <p>You haven't added any account with us</p>
          <p>
            Go to your{" "}
            <Link
              to={`/dashboard/settings`}
              className="text-fedacash-header font medium cursor-pointer"
            >
              profile
            </Link>{" "}
            to add
          </p>
        </div>
      )}

      <div className="mb-3">
        {actiondata?.createLoanRequestResponse?.errors?.errors
          ?.recipient_account_number && (
          <ErrorFunction
            error={
              actiondata?.createLoanRequestResponse?.errors?.errors
                ?.recipient_account_number
            }
          />
        )}
      </div>
      <div className="mb-3">
        {actiondata?.createLoanRequestResponse?.errors?.errors
          ?.recipient_account_name && (
          <ErrorFunction
            error={
              actiondata?.createLoanRequestResponse?.errors?.errors
                ?.recipient_account_name
            }
          />
        )}
      </div>
    </div>
  );
};
const MonthlyRepayment = ({ month = 0, amount }) => {
  function getOrdinalWord(number) {
    const numberToWordMap = {
      1: "First",
      2: "Second",
      3: "Third",
      4: "Fourth",
    };
    return numberToWordMap[number] || "Invalid number";
  }
  return (
    <div className="mb-2">
      <p className="mb-1 capitalize text-gray-700 w-full text-lg font-bold">
        Monthly Loan Repayment
      </p>

      <div className="space-y-2 px-4 form-input focus:outline-none text-gray-800 tracking-wide border bg-transparent focus:border-purple-800 py-4 text-base placeholder:text-gray-400 font-semibold w-full">
        {Array.from({ length: month }).map((_, index) => {
          if (month <= 0) return "No Repayment";
          const fixedAmount= amount.toFixed(2)
          return (
            <div className="flex gap-x-2">
              <p>{getOrdinalWord(index + 1)}&nbsp;Month</p>
              <p>&#8358;{fixedAmount}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
function ErrorFunction({ error }) {
  return (
    <div
      className={`text-red-500 text-sm font-semibold tracking-wide ${
        error ? "" : "text-transparent"
      }`}
    >
      {Array.isArray(error) && error.length > 1 && (
        <ul className="list-disc ml-5">
          {error.map((e, k) => (
            <li key={k}>{e}</li>
          ))}
        </ul>
      )}
      {Array.isArray(error) && error.length === 1 && error}
    </div>
  );
}
