export const Table = ({ children }) => {
    return (
      <div>
        <table className="w-full table-auto overflow-x-auto">{children}</table>
      </div>
    );
  };
  
  export const ColumnHeader = ({ children, className }) => {
    return (
      <th className={`h-[54px] leading-4 whitespace-nowrap px-[25.5px] py-[17.5px] capitalize ${className}`}>
        {children}
      </th>
    );
  };
  
  export const RowHeader = ({ children }) => {
    return (
      <thead>
        <tr className="bg-[#f7f8f9] text-left rounded-[8px] text-[#0d0f11] text-[14px] font-[700] leading-[14px]">
          {children}
        </tr>
      </thead>
    );
  };
  
  export const Column = ({ children, classname }) => {
    return <td className={`h-[56px] font-[500] px-[25.5px] py-[17.5px] ${classname}`}>{children}</td>;
  };
  
  export const Row = ({ children }) => {
    return (
      <tr className="text-left whitespace-nowrap rounded-[8px] text-[#323a46] text-[14px] font-[700] leading-[21px]">
        {children}
      </tr>
    );
  };
  
  export const Fallback = () => {
    return (
      <>
        {Array(5)
          .fill(5)
          .map((_, i) => (
            <tr key={i} className="blur-lg animate-pulse text-left rounded-[8px] text-[#323a46] text-[14px] font-[700] leading-[21px]">
              <th className="h-[56px] appearance-none font-[500] px-[25.5px] py-[17.5px]"></th>
            </tr>
          ))}
      </>
    );
  };
  
  export const Body = ({ data, children }) => {
    return (
      <tbody className="divide-y-[1.5px]">
        {children(data)}
      </tbody>
    );
  };
  