import Logo from "../../Assets/logo/Logo.png";
import Home from "../../Assets/dashboard/Home.svg";
import Transaction from "../../Assets/dashboard/Transaction.svg";
import Invoice from "../../Assets/dashboard/Invoice.svg";
import Loan from "../../Assets/dashboard/loan.svg";
import saveandgo from "../../Assets/dashboard/saveandgo.svg";
import Setting from "../../Assets/dashboard/Setting.svg";
import Help from "../../Assets/dashboard/Help.svg";
import Cooperative from "../../Assets/dashboard/Cooperative.svg";
import TalkToAnExpert from "../../Assets/dashboard/TalktoAnExpert.svg";
import CreateRequest from "../../Assets/dashboard/CreateRequest.svg";
import SaveVector from "../../Assets/dashboard/save-vector.svg";

import NavLink, { LoanKYCLink } from "./NavLink";
import { FiX } from "react-icons/fi";
import NavDropdown from "./NavDropdown";
import UserObject from "../../DataObjects/UserObject";
import { Link } from "react-router-dom";

const NavBar = ({ value, click }) => {
  const user = UserObject.getUser();
  const userRole = user?.role;
  return (
    <aside
      className={`lg:relative z-50 w-[250px] h-full bg-white absolute top-0 left-0 ${
        value ? "" : "hidden lg:block"
      }`}
      style={{ borderRight: "1.5px solid #E7EAEE" }}
    >
      <div className="">
        <button
          className="absolute p-1 bg-red-50 top-0 right-0 lg:hidden"
          onClick={click}
        >
          <FiX className="w-6 h-6" />
        </button>
        <div className="w-[250px] h-full">
          <Link to="">
            <img
              src={Logo}
              alt="logo"
              className="w-[135.82px] h-[40px] mx-auto mt-[31px] mb-[55.55px]"
            />
          </Link>

          <div className="w-full h-full flex flex-col items-start justify-start pl-[14.96px] pr-[20.42px] space-y-[20px]">
            <NavLink to="/dashboard" onClick={click} icon={Home}>
              Dashboard
            </NavLink>
            <hr className="border-t border-gray-500/50 w-full lg:hidden" />
            <NavDropdown name="Generate Invoice" icon={Cooperative}>
              <NavLink
                onClick={click}
                to="/dashboard/invoice/create"
                icon={Invoice}
              >
                Generate Invoice
              </NavLink>
              <NavLink onClick={click} to="/dashboard/invoice" icon={Invoice}>
                All Invoices
              </NavLink>
            </NavDropdown>
            <NavDropdown name="Invoice Discount" icon={Invoice}>
              <NavLink
                onClick={click}
                to="/dashboard/transaction/create"
                icon={CreateRequest}
                isKyc={user.isKYCVerified()}
              >
                Create Request
              </NavLink>
              <NavLink
                onClick={click}
                to="/dashboard/transaction"
                icon={Transaction}
                isKyc={user.isKYCVerified()}
              >
                Transaction history
              </NavLink>
            </NavDropdown>
            <NavDropdown name="Cooperative" icon={Cooperative}>
              {/* <NavLink
                onClick={click}
                isComingSoon={true}
                to="/dashboard/cooperative"
                icon={Invoice}
              >
                Cooperative Dashboard
              </NavLink> */}
              <NavLink
                onClick={click}
                // isComingSoon={true}
                to="/dashboard/cooperative/saveandgo"
                icon={saveandgo}
              >
                Save and Go
              </NavLink>
              <LoanKYCLink
                onClick={click}
                // isComingSoon={true}
                to="/dashboard/cooperative/saveandpack"
                icon={SaveVector}
                isKyc={user.isNinVertified() || user.isBvnVertified()}
              >
                Save and Pack
              </LoanKYCLink>
            </NavDropdown>
            <LoanKYCLink
              onClick={click}
              isKyc={user.isNinVertified() || user.isBvnVertified()}
              to="/dashboard/loan/request"
              icon={Loan}
            >
              Loan
            </LoanKYCLink>
            {(userRole === "Salary Advance HR Manager" ||
              userRole === "salary advance staff") && (
              <NavLink
                onClick={click}
                isKyc={true}
                to="/dashboard/salary/request"
                icon={Loan}
              >
                Salary Advance
              </NavLink>
            )}

            <NavDropdown name="Talk to an Expert" icon={TalkToAnExpert}>
              <NavLink
                onClick={click}
                isComingSoon={true}
                to="/dashboard/transaction-history-create"
                icon={Invoice}
              >
                Home
              </NavLink>
              <NavLink
                onClick={click}
                isComingSoon={true}
                to="/dashboard/transaction-history"
                icon={Transaction}
              >
                Consultation history
              </NavLink>
              <NavLink
                onClick={click}
                isComingSoon={true}
                to="/dashboard/invoice"
                icon={Invoice}
              >
                Generate Invoice
              </NavLink>
            </NavDropdown>
            <NavDropdown name="Settings" icon={Setting}>
              <NavLink onClick={click} to="/dashboard/settings" icon={Setting}>
                User Settings
              </NavLink>
              <NavLink
                onClick={click}
                to="/dashboard/help-and-support"
                icon={Help}
              >
                Help & Support
              </NavLink>
            </NavDropdown>
            {/* <NavLink onClick={click} to="/dashboard/audit-log" icon={AuditLog}>
              Audit Log
            </NavLink> */}
            {/* <NavLink onClick={click} to="#" icon={Apply}>
              Apply To Join Cooperative
            </NavLink> */}
          </div>
        </div>
      </div>
    </aside>
  );
};

export default NavBar;
