import { useState, Fragment, Suspense, useEffect } from "react";
import { Tab, Dialog, Transition } from "@headlessui/react";
import {
  AiFillCheckCircle,
  AiFillCloseCircle,
  AiOutlineArrowDown,
  AiOutlineClose,
  AiOutlineEyeInvisible,
  AiOutlinePlus,
} from "react-icons/ai";
import { usePaystackPayment } from "react-paystack";
import {
  Await,
  Form,
  useFetcher,
  useLoaderData,
  useNavigate,
  useSubmit,
} from "react-router-dom";
import moment from "moment/moment";

///file import
import paymentImage from "../../../Assets/images/payment-image.png";
import UserObject from "../../../DataObjects/UserObject";
import ResponseObject from "../../../DataObjects/ResponseObject";
import http from "../../../Store/baseHttp";
import { formatNaira } from "../../../Components/Utils/FormatMoney";
import useTable from "../../../Components/Dashboard/Table/useTable";
import usePagination from "../../../Components/Dashboard/Table/usePagination";
import walletimage from "../../../Assets/dashboard/EmptyStatewallet.svg";
import emptyState from "../../../Assets/images/empty-state.png";
import Skeleton from "../../../Components/Dashboard/cooperative/skelenton";
import WithdrawlForm from "../../../Components/Dashboard/cooperative/withdrawalForm";
import Select from "../../../Components/Forms/Select";
import TextArea from "../../../Components/Forms/TextArea";
import Submit from "../../../Components/Forms/Submit";
import Input from "../../../Components/Forms/Input";
import { BsPlus } from "react-icons/bs";
import {
  CreateSharedPlan,
  joinPlan,
} from "../../../Services/Dashboard/CooperativeService";
import { StatusDialog } from "../../../Components/Dashboard/Shared/StatusDialog";
import { PAYSTACK_KEY, calculateFinalAmount, calculatePaystackCharges } from "../../../Components/Utils/PaystackFunctions";

const SaveandPack = () => {
  return (
    <section>
      <div className="">
        <WalletCard />
      </div>
      <div className="tabs mt-4 md:mt-8 lg:mt-12">
        <Tab.Group>
          <Tab.List
            className={`flex border justify-evenly h-12 text-xs md:text-base w-full bg-white font-bold`}
          >
            <Tab
              className={`ui-selected:border-fedacash-header ui-selected:border-b-2 w-1/3`}
            >
              Overview
            </Tab>
            <Tab
              className={`ui-selected:border-fedacash-header ui-selected:border-b-2 w-1/3`}
            >
              Subscribed Packages
            </Tab>
            <Tab
              className={`ui-selected:border-fedacash-header ui-selected:border-b-2 w-1/3`}
            >
              Transaction History
            </Tab>
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel className={`overview`}>
              <section>
                <AvailablePlans />
              </section>
            </Tab.Panel>
            <Tab.Panel>
              <section className="">
                <MyPlans />
              </section>
            </Tab.Panel>
            <Tab.Panel>
              <TransactionHistory />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
        {/* <ViewPlanModal isViewOpen={isViewOpen} closeModal={closeViewModal} /> */}
      </div>
    </section>
  );
};

export default SaveandPack;

const AvailablePlans = () => {
  const data = useLoaderData();
  const [isOpen, setIsOpen] = useState(false);
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  function closeModal() {
    setIsOpen(false);
    setSelectedItem("");
  }
  function openModal(title) {
    setIsOpen(true);
    setSelectedItem(title);
  }
  //TODO://use usememo to save performance from expesnsive calculation
  return (
    <Suspense fallback={<Skeleton />}>
      <Await resolve={data?.data}>
        {(packages) => {
          // console.log("packages listed", packages);
          const packagesData = packages?.packages?.data?.data;
          return (
            <div>
              {packagesData?.length > 0 ? (
                <div className="grid grid-cols-1 xl:grid-cols-2 grid-rows-2 gap-12 mt-4">
                  <CreatePlan />
                  {packagesData.map((item, index) => (
                    <SinglePlan
                      item={item}
                      key={index}
                    />
                  ))}
                </div>
              ) : (
                <EmptyState
                  title={`Available Packages`}
                  description={`No package available currently`}
                />
              )}
            </div>
          );
        }}
      </Await>
    </Suspense>
  );
};

const MyPlans = () => {
  const data = useLoaderData();
  const [isOpen, setIsOpen] = useState(false);
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  function openViewModal(title) {
    setIsViewOpen((prev) => !prev);
    setSelectedItem(title);
  }
  function closeViewModal() {
    setIsViewOpen((prev) => !prev);
    setSelectedItem("");
  }
  return (
    <Suspense fallback={<Skeleton />}>
      <Await resolve={data?.data}>
        {(packages) => {
          // console.log(packages?.packages?.data?.data);
          // const packagesData = packages?.packages?.data?.data;
          const packagesData = packages?.activePackages?.data?.data;
          // console.log(packagesData);

          return (
            <div>
              {packagesData?.length > 0 ? (
                <div className="grid grid-cols-1 xl:grid-cols-2 gap-12 mt-4">
                  {packagesData?.map((item, index) => (
                    <ViewPlan
                      item={item}
                      key={index}
                      openViewModal={openViewModal}
                    />
                  ))}
                </div>
              ) : (
                <EmptyState
                  title={`Active Packages`}
                  description={`You haven't subscribed to any plan`}
                />
              )}
              <ViewPlanModal
                isViewOpen={isViewOpen}
                closeViewModal={closeViewModal}
                selectedItem={selectedItem}
                items={packagesData}
              />
            </div>
          );
        }}
      </Await>
    </Suspense>
  );
};
const TransactionHistory = () => {
  const data = useLoaderData();
  const { Column, ColumnHeader, Row, RowHeader, Table } = useTable();
  const {
    Pagination,
    Button,
    Prev,
    Next,
    setPage,
    searchParams,
    PageSize,
    Description,
  } = usePagination();
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10; // Number of items per page

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  //I wrote another function because the general function kept returning a null error
  function formatAsNaira(number) {
    const formatter = new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
    });
    return formatter.format(number);
  }
  return (
    <Suspense loading={<div>Loading...</div>}>
      <Await resolve={data?.data}>
        {(transactionHistory) => {
          // console.log(transactionHistory)
          const transactionHistorydata =
            transactionHistory?.transactionHistory?.data?.data;
          return (
            <div className="w-full p-[15px] rounded-[16px] bg-white overflow-x-auto">
              <Table>
                <RowHeader>
                  <ColumnHeader>ID</ColumnHeader>
                  <ColumnHeader>Reference</ColumnHeader>
                  <ColumnHeader>Status</ColumnHeader>
                  <ColumnHeader>Amount</ColumnHeader>
                  <ColumnHeader>Date</ColumnHeader>
                  <ColumnHeader>Plan</ColumnHeader>

                  {/* Add more table headers as needed */}
                </RowHeader>
                <tbody className="divide-y-[1.5px]">
                  {transactionHistorydata?.length > 0 ? (
                    transactionHistorydata
                      .sort((a, b) => a.transaction_date - b.transaction_date)
                      .map((item) => (
                        <Row key={item.id}>
                          <Column>{item.id}</Column>
                          <Column>{item.reference}</Column>
                          <Column className="capitalize">
                            {item.status ?? "N/A"}
                          </Column>
                          <Column>
                            {formatAsNaira(item?.cooperative_amount) ?? "N/A"}
                          </Column>
                          <Column>
                            {moment(item.transaction_date).format(
                              "dddd, Do MMMM YYYY: h:mmA"
                            )}
                          </Column>
                          <Column>{item?.package_name ?? "N/A"}</Column>
                        </Row>
                      ))
                  ) : (
                    <tr>
                      <td colSpan="4">No data available</td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <Pagination>
                <Suspense fallback={<p></p>}>
                  <Await
                    resolve={data?.data}
                    errorElement={<span>Loading</span>}
                  >
                    {(data) => {
                      const { transactionHistory } = data;
                      // console.log(transactionHistory);
                      return (
                        <>
                          <Prev
                            onClick={() => {
                              setPage(1);
                            }}
                          />
                          {Array(transactionHistory?.data?.last_page)
                            .fill(5)
                            .map((v, i) => {
                              return (
                                <Button
                                  key={i}
                                  isActive={
                                    transactionHistory?.data?.current_page ===
                                    i + 1
                                  }
                                  onClick={() => {
                                    setPage(i + 1);
                                  }}
                                >
                                  {i + 1}
                                </Button>
                              );
                            })}
                          <Next
                            onClick={() => {
                              setPage(data?.data?.last_page);
                            }}
                          />
                        </>
                      );
                    }}
                  </Await>
                </Suspense>
              </Pagination>
            </div>
          );
        }}
      </Await>
    </Suspense>
  );
};

//TODO Wrap all the wallet card as one
const WalletCard = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [checked, setChecked] = useState(true);
  const data = useLoaderData();
  const { paystack_customer_account } = UserObject.getUser();

  // const [status, setStatus]= useState=("")
  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div>
      <div className="h-[230px] lg:w-[60%] xl:w-[45%] flex flex-col gap-y-6 justify-between bg-gradient-to-r from-[#FAB2FF] to-[#6704E5] text-white rounded-[8px] pt-[20px] pb-[24px] px-[20px]">
        <div>
          <h2 className="font-bold leading-[24px]">Fedacash Cooperative</h2>
          <Suspense loading={<div>Loading...</div>}>
            <Await resolve={data?.data}>
              {(transactionHistory) => {
                const { walletBalance } = transactionHistory;
                return (
                  <div>
                    <div className="font-semibold text-4xl leading-[32.5px] tracking-[-2%] flex items-center italic space-x-2 ">
                      {isVisible
                        ? formatNaira(
                            walletBalance?.data?.available_balance ?? 0
                          )
                        : "*****"}
                      <div onClick={handleClick}>
                        <AiOutlineEyeInvisible size={32} />
                      </div>{" "}
                    </div>
                    <div className="flex items-center gap-x-4 text-sm lg:text-base">
                      <p>Book balance:</p>
                      <div className="font-semibold leading-[32.5px]">
                        {isVisible
                          ? formatNaira(walletBalance?.data?.book_balance ?? 0)
                          : "*****"}
                      </div>
                    </div>
                    <div>
                      <div className="flex items-center gap-x-4 text-sm lg:text-base">
                        <p>Account Number:</p>
                        <div className="font-semibold">
                          {paystack_customer_account
                            ? paystack_customer_account
                            : "*****"}
                        </div>
                      </div>
                      <p>Bank Name</p>
                    </div>
                  </div>
                );
              }}
            </Await>
          </Suspense>
        </div>
        <div className="flex justify-between items-center">
          <FundWallet />
          <WithdrawlForm />
        </div>
      </div>
    </div>
  );
};

const SinglePlan = ({ item, openModal }) => {
  return (
    <section className="bg-white rounded-md py-4 drop-shadow-xl">
      <div className="flex flex-col gap-y-8 ">
        <div className="px-4">
          <div className="flex justify-between">
            <h3 className="text-lg font-medium">{item?.title}</h3>
            {item?.is_complete === "COMPLETE" ? (
              ""
            ) : (
              <PlanModal items={item} singleItem={item} />
            )}
          </div>
          <div className="flex gap-x-5 mt-3 text-xs">
            <p className="px-2 py-[10px] bg-[#DECFED] text-fedacash-header font-bold rounded-md border-2 border-fedacash-header">
              {formatNaira(item?.total_savings_amount)}
            </p>
            <p className="px-2 py-[10px] rounded-md capitalize border">
              {`${item?.duration} ${durationToTime(
                item?.contribution_frequency
              )}`}
            </p>
            <p className="px-2 py-[10px] rounded-md border">
              {item?.size_of_savings_group} people
            </p>
          </div>
          <div className="flex justify-between mt-3 text-xs">
            <p className="text-fedacash-header underline space-y-4">
              Contribute{" "}
              <span className="font-bold">
                {formatNaira(item?.amount_to_contribute)}
              </span>{" "}
              {/* {`${item?.duration} ${durationToTime(item?.contribution_frequency)}`} */}
            </p>
            <p className="number font-bold p-3 text-sm bg-[#F6F8FA] rounded-full">
              {item?.members?.length}/{item?.size_of_savings_group}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

const ViewPlan = ({ openViewModal, item }) => {
  return (
    <section className="w-full">
      <div className="flex flex-col gap-y-8 bg-white rounded-md py-4 shadow-xl">
        <div className="px-4">
          <div className="flex justify-between">
            <h3 className="text-lg font-medium w-3/4">{item.title}</h3>
            <button
              onClick={() => openViewModal(item.title)}
              className="flex gap-2 text-xs bg-fedacash-header p-2 w-28 justify-center items-center h-10 text-white rounded-md"
            >
              <AiOutlineArrowDown />
              <span>View More</span>
            </button>
          </div>
          <div className="flex gap-x-5 mt-3 text-xs">
            <p className="px-2 py-[10px] bg-[#DECFED] text-fedacash-header font-bold rounded-md border-2 border-fedacash-header">
              {formatNaira(item?.total_savings_amount)}
            </p>
            <p className="px-2 py-[10px] rounded-md capitalize border">
              {item?.duration} {durationToTime(item?.contribution_frequency)}
            </p>
            <p className="px-2 py-[10px] rounded-md border">
              {item?.size_of_savings_group} people
            </p>
          </div>
          <div className="flex justify-between mt-3 text-xs">
            <p className="text-fedacash-header underline space-y-4">
              Contribute{" "}
              <span className="font-bold">
                {formatNaira(item?.amount_to_contribute)}
              </span>{" "}
              {item?.contribution_frequency}
            </p>
            {/* <p className="number font-bold p-3 text-sm bg-[#F6F8FA] rounded-full">
              You are 10th
            </p> */}
          </div>
        </div>

        <div className="table text-sm font-semibold overflow-y-auto divide-y"></div>
      </div>
    </section>
  );
};
const PlanModal = ({singleItem }) => {
  const navigate = useNavigate();
  const [isStatusOpen, setIsStatusOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [checked, setChecked] = useState(true);
  function closeStatusModal() {
    setIsStatusOpen(false);
    setChecked(false);
  }
  function togglePlanModal() {
    setIsOpen((prev) => !prev);
  }
  function closePlanModal() {
    togglePlanModal();
    setChecked(false);
  }
  function openStatusModal() {
    setIsStatusOpen(true);
  }

  const config = {
    reference: new Date().getTime().toString(),
    email: UserObject.getUser().email,
    amount: parseInt(
      calculateFinalAmount(singleItem.amount_to_contribute) * 100
    ), //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: PAYSTACK_KEY,
    channels: ["card"],
  };
  // console.log(config);
  // you can call this function anything
  const onSuccess = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    joinPlan({
      package_token: singleItem.token,
      amount_to_contribute: singleItem.amount_to_contribute,
      reference: reference.reference,
    }).then((res) => {
      setMessage(res);
      openStatusModal();
      closePlanModal();
      navigate(".");
    });
  };

  // you can call this function anything
  const onPaystackClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    // console.log("closed");
  };
  const initializePayment = usePaystackPayment(config);

  return (
    <>
      <button
        className="flex gap-2 text-xs bg-fedacash-header p-2 text-white rounded-md h-8 items-center"
        onClick={() => togglePlanModal()}
      >
        <AiOutlinePlus />
        <span>Join</span>
      </button>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={togglePlanModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-fedacash-header bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                  <Dialog.Panel className="w-full rounded-md max-w-xl transform overflow-hidden bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                      as="h3"
                      className="flex gap-3 justify-evenly text-lg font-bold leading-6 text-[#475467]"
                    >
                      <span>Join {singleItem.title}</span>
                      <button
                        type="button"
                        className=""
                        onClick={() => togglePlanModal()}
                      >
                        <AiOutlineClose />
                      </button>
                    </Dialog.Title>
                    <div className="flex flex-col justify-center items-center">
                      <img src={paymentImage} alt="" />
                    </div>
                    <div className="mt-2">
                      <p className="font-bold text-[#98A2B3] text-center">
                        Join Save and Pack Scheme
                      </p>
                    </div>

                    <div className="mt-4 text-sm font-medium divide-y divide-black">
                      <div className="flex justify-between py-2">
                        <p>Amount</p>
                        <p>{formatNaira(singleItem?.total_savings_amount)}</p>
                      </div>
                      <div className="flex justify-between py-2">
                        <p>Contribution</p>
                        <p>{formatNaira(singleItem?.amount_to_contribute)}</p>
                      </div>
                      <div className="flex justify-between py-2">
                        <p>Charges</p>
                        <p>
                          {formatNaira(
                            calculatePaystackCharges(
                              singleItem.amount_to_contribute
                            )
                          )}
                        </p>
                      </div>
                      <div className="flex justify-between py-2">
                        <p>Duration</p>
                        <p>{`${singleItem?.duration} ${durationToTime(
                          singleItem?.contribution_frequency
                        )}`}</p>
                      </div>
                      <div className="py-2">
                        <p className="max-w-xs">
                          Please note that you would not be debited until the
                          package plan starts
                        </p>
                      </div>
                      {/* <div className="flex justify-between py-2">
                        <p>Position</p>
                        <p>{singleItem?.position}</p>
                      </div> */}
                      <div className="flex justify-between py-2">
                            <div>
                              <p>Invite Friends</p>
                              <p className="text-xs">
                                (so that the cycle can be completed faster...){" "}
                              </p>
                            </div>
                            <p className="text-fedacash-header font-bold underline cursor-pointer" onClick={()=>navigator.clipboard.writeText(`https://fedacash.com/auth/dashboard/cooperative/join/${singleItem?.token}`)}>
                              Copy link
                            </p>
                          </div>
                          <p className="text-fedacash-header font-bold underline text-xs md:text-sm" onClick={()=>navigator.clipboard.writeText(`https://fedacash.com/auth/dashboard/cooperative/join/${singleItem?.token}`)}>https://fedacash.com/dashboard/cooperative/join/{singleItem?.token}</p>
                    </div>
                    <div className="flex gap-x-3 mt-3">
                      <input
                        type="checkbox"
                        value={checked}
                        onChange={() => setChecked((prev) => !prev)}
                      />
                      I agree to be debited instantly for this Contribution
                    </div>
                    <div className="mt-4">
                      <button
                        onClick={() =>
                          initializePayment(onSuccess, onPaystackClose)
                        }
                        disabled={checked}
                        className="text-white bg-fedacash-header text-center w-full py-3 px-2 rounded-md"
                      >
                        Proceed
                      </button>
                    </div>
                  </Dialog.Panel>
         
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <StatusDialog
        closeModal={closeStatusModal}
        isOpen={isStatusOpen}
        message={message}
      />
    </>
  );
};
const FundWallet = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [status, setStatus] = useState("idle"); //three status - idle/ processing/completed

  const [deposit, setDeposit] = useState("");
  const [message, setMessage] = useState({});
  const [isStatusOpen, setIsStatusOpen] = useState(false);
  function closeStatusModal() {
    setIsStatusOpen(false);
  }

  function openStatusModal() {
    setIsStatusOpen(true);
  }
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  async function fundWallet(data) {
    try {
      let response = await http.post("/cooperative/add/wallet", data);
      response = ResponseObject.from(response);
      return response;
    } catch (e) {
      return ResponseObject.from(e?.response);
    }
  }
  const paystackCharges =
    deposit > 1999 ? deposit * 0.015 + 100 : deposit * 0.015;
  const finalAmount = parseFloat(deposit) + parseFloat(paystackCharges);
  const config = {
    reference: new Date().getTime().toString(),
    email: UserObject.getUser().email,
    amount: parseInt(finalAmount * 100), //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey:PAYSTACK_KEY,
  };
  const onSuccess = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    console.log(reference);
    fundWallet({
      reference: reference?.reference,
      amount: parseFloat(deposit),
    }).then((res) => {
      setMessage(res);
      // console.log(res);
      openStatusModal();
      closeModal();
    });
  };

  // you can call this function anything
  const onPaystackClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    // console.log("closed");
  };
  const initializePayment = usePaystackPayment(config);
  useEffect(() => {
    const timer = setTimeout(() => {
      if (message?.success) {
        // Refresh the page with React Router in a few seconds
        window.location.reload();
      }
    }, 3000);
    return () => clearTimeout(timer);
  }, [message?.success]);
  return (
    <div>
      <button
        onClick={openModal}
        name="fund wallet"
        className="flex items-center py-2 px-5 space-x-2 bg-[#120321] text-white rounded-lg  font-semibold text-xs md:text-sm  leading-[21px] "
      >
        <AiOutlinePlus />
        <span>Fund</span>
      </button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-fedacash-header bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full rounded-md max-w-xl transform overflow-hidden bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="flex gap-3 justify-evenly text-lg font-bold leading-6 text-[#475467]"
                  >
                    Fund Your Wallet
                    <button
                      type="button"
                      className=""
                      onClick={() => closeModal()}
                    >
                      <AiOutlineClose />
                    </button>
                  </Dialog.Title>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      initializePayment(onSuccess, onPaystackClose);
                    }}
                    className="py-4 space-y-3"
                  >
                    <div>
                      <label>Enter Amount</label>
                      <input
                        placeholder="Amount"
                        value={deposit}
                        required
                        type="number"
                        onChange={(e) => setDeposit(e.target.value)}
                        className="font-bold w-full text-[12px] leading-[18px] py-[11px] px-[19px] placeholder:text-[#b8c0cc] border-[1.5px] rounded-[8px] border-[#b8c0cc] focus:outline-none"
                      />
                    </div>
                    <div className="mt-4 flex gap-x-4">
                      <button
                        onClick={() => closeModal()}
                        className={`w-full py-3 px-2 rounded-md flex items-center justify-center border-2 border-[#590DA4] text-[#590DA4]`}
                      >
                        Cancel
                      </button>
                      <button className="text-white bg-fedacash-header text-center w-full py-3 px-2 rounded-md">
                        Proceed to payment
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <StatusDialog
        closeModal={closeStatusModal}
        isOpen={isStatusOpen}
        message={message}
      />
    </div>
  );
};
const ViewPlanModal = ({ items, closeViewModal, isViewOpen, selectedItem }) => {
  const singleItem = items?.find((item) => item?.title?.includes(selectedItem));
  // console.log(singleItem);
  return (
    <>
      <Transition appear show={isViewOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeViewModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-fedacash-header bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-xl transform overflow-hidden bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="flex gap-3 justify-evenly text-lg font-bold leading-6 text-[#475467]"
                  >
                    <span>{singleItem?.title}</span>
                    <button type="button" className="" onClick={closeViewModal}>
                      <AiOutlineClose />
                    </button>
                  </Dialog.Title>
                  <div className="flex flex-col justify-center items-center">
                    <img src={paymentImage} alt="" />
                  </div>
                  <div className="mt-2">
                    <p className="font-bold text-[#98A2B3] text-center">
                      Save and Pack Scheme{" "}
                    </p>
                  </div>
                  {/* <div className="mt-2 flex items-center justify-center">
                    <p className="number text-center font-bold p-3 bg-[#F6F8FA] rounded-full">
                      You are 10th
                    </p>
                  </div> */}

                  <div className="w-full text-sm font-semibold overflow-y-auto divide-y divide-black gap-y-3">
                    {singleItem?.fellowMembers?.map((item, index) => (
                      <div
                        className="flex items-center justify-between gap-x-16 px-6 py-2 overflow-y-auto"
                        key={index}
                      >
                        <div className="flex items-center gap-x-4 justify-between">
                          <p>{index + 1}</p>
                          <p className="capitalize">
                            {item.first_name} {item.last_name}
                          </p>
                        </div>
                        <p>{moment(item.created_at).format("DD-MMM-YY")}</p>
                      </div>
                    ))}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};


const EmptyState = ({ title, description }) => {
  return (
    <div className="flex items-center w-full justify-center bg-white mt-4 rounded-md">
      <div className="flex flex-col items-center justify-center py-12">
        <img src={emptyState} alt="" />
        <div className="flex flex-col justify-center items-center text-center max-w-xl">
          <h3>{title}</h3>
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
};
function durationToTime(durations) {
  switch (durations) {
    case "monthly":
      return "months";
    case "weekly":
      return "weeks";
    case "daily":
      return "days";
    case "annually":
      return "years";
    default:
      return null;
  }
}

const CreatePlan = ({}) => {
  const navigate = useNavigate();
  const [isStatusOpen, setIsStatusOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [planError, setPlanError] = useState({});

  const [plan, setPlan] = useState({
    title: "",
    total_savings_amount: "",
    amount_to_contribute: "",
    duration: "",
    commission_fee: "",
    size_of_savings_group: "",
    contribution_frequency: "",
    more_information: "",
    status: 1,
  });
  const [message, setMessage] = useState("");
  const onChange = (e) => {
    setPlan((plan) => ({ ...plan, [e.target.name]: e.target.value }));
  };

  function toggleCreatePlanModal() {
    setIsOpen((prev) => !prev);
  }
  function closeStatusModal() {
    setIsStatusOpen(false);
  }
  function openStatusModal() {
    setIsStatusOpen(true);
  }
  async function handleCreateSharedPlan(reference) {
    setLoading(true);
    try {
      const response = await CreateSharedPlan(plan);
      if (response?.SharedPlanResponse?.success) {
        setMessage(response?.SharedPlanResponse);
        const joinPlanResponse = await joinPlan({
          package_token: response?.SharedPlanResponse?.data?.token,
          amount_to_contribute: parseInt(
            response?.SharedPlanResponse?.data?.amount_to_contribute
          ),
          reference: reference.reference,
        });
        console.log(joinPlanResponse);
        setMessage(joinPlanResponse);
        openStatusModal();
        setLoading(false);
        toggleCreatePlanModal();
        navigate(".");
      }
    } catch (error) {
      console.log(error);
      // Handle errors here
    }
  }

  // console.log(singleItem)
  ///TODO:// extract charges calculation to a reusable function
  const onPaystackClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    // console.log("closed");
  };
  const config = {
    reference: new Date().getTime().toString(),
    email: UserObject.getUser().email,
    amount: parseInt(calculateFinalAmount(plan.amount_to_contribute) * 100), //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: PAYSTACK_KEY,
    channels: ["card"],
  };
  // console.log(config);
  // you can call this function anything
  const onSuccess = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    handleCreateSharedPlan(reference);
  };

  const initializePayment = usePaystackPayment(config);
  return (
    <>
      <button
        onClick={toggleCreatePlanModal}
        className="bg-white after:transition-all hover:drop-shadow-lg focus:border-none focus:outline-none"
      >
        <div>
          <div className="flex h-[145px] w-full flex-col items-center justify-center space-y-3 text-[#590DA4]">
            <span className="inline-block  rounded-md border-2 border-[#590DA4]">
              <BsPlus size={30} />
            </span>
            <span className="font-medium leading-[27px]">
              Create a group package
            </span>
          </div>
        </div>
      </button>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={toggleCreatePlanModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-fedacash-header bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full rounded-md max-w-xl transform overflow-hidden bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="flex gap-3 justify-evenly text-lg font-bold leading-6 text-[#475467]"
                  >
                    <span>Create Your Plan</span>
                    <button
                      type="button"
                      className=""
                      onClick={() => toggleCreatePlanModal()}
                    >
                      <AiOutlineClose />
                    </button>
                  </Dialog.Title>
                  <Form
                    className="w-full pt-6"
                    onSubmit={(event) => {
                      event.preventDefault();
                      initializePayment(onSuccess, onPaystackClose);
                    }}
                  >
                    <Input
                      label="Title"
                      placeholder="Title"
                      name="title"
                      normalize={true}
                      onChange={onChange}
                      value={plan?.title}
                      error={planError?.errors?.title}
                    />
                    <Input
                      label="Total Savings Amount"
                      name={"total_savings_amount"}
                      placeholder="Total Savings Amount"
                      // type="number"
                      normalize={true}
                      error={planError?.errors?.total_savings_amount}
                      options={{ numeral: true }}
                      onChange={onChange}
                      value={plan?.total_savings_amount}
                    />
                    <Input
                      label="Amount To Contribute"
                      placeholder="Amount To Contribute"
                      name={"amount_to_contribute"}
                      type="number"
                      normalize={true}
                      error={planError?.errors?.amount_to_contribute}
                      options={{ numeral: true, numeralDecimalScale: 2 }}
                      onChange={onChange}
                      value={plan?.amount_to_contribute}
                    />
                    <div>
                    </div>
                    <Input
                      label="duration(months)"
                      placeholder="duration"
                      name={"duration"}
                      type="number"
                      // options={{ numeral: true}}
                      normalize={true}
                      error={planError?.errors?.duration}
                      onChange={onChange}
                      value={plan?.duration}
                    />
                    <Input
                      label="Size of the savings group"
                      placeholder="Size of the savings group"
                      normalize={true}
                      name={"size_of_savings_group"}
                      type="number"
                      onChange={onChange}
                      value={plan?.size_of_savings_group}
                      error={planError?.errors?.size_of_savings_group}
                    />
                    <Input
                      label="Commission Fee"
                      placeholder="Commission Fee"
                      normalize={true}
                      name={"commission_fee"}
                      type="number"
                      onChange={onChange}
                      value={plan?.commission_fee}
                      error={planError?.errors?.commission_fee}
                    />
                    <p className="font-semibold text-lg">Duration</p>
                    <Select
                      label="Contribution Frequency"
                      name={"contribution_frequency"}
                      onChange={onChange}
                      value={plan?.contribution_frequency}
                      error={planError?.errors?.contribution_frequency}
                    >
                      <option>--Select Option--</option>
                      <option value="daily">Daily</option>
                      <option value="weekly">Weekly</option>
                      <option value="monthly">Monthly</option>
                      <option value="annually">Yearly</option>
                    </Select>
                    <TextArea
                      label="More Information"
                      name={"more_information"}
                      placeholder="More Information."
                      rows={3}
                      onChange={onChange}
                      value={plan?.more_information}
                    />
                    <Submit type="submit" isLoading={loading}>
                      Submit
                    </Submit>
                  </Form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <StatusDialog
        closeModal={closeStatusModal}
        isOpen={isStatusOpen}
        message={message}
      />
    </>
  );
};
