import Submit from "../../Components/Forms/Submit";
import SingleInput from "../../Components/Forms/SingleInput";
import {
  Link,
  Outlet,
  useFetcher,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useEffect } from "react";
import HeaderLogo from "../../Components/LandingPage/HeaderLogo";
import Input from "../../Components/Forms/Input";

const Verification = () => {
  const { Form, formAction, state, data } = useFetcher();
  let [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const { search } = useLocation();
  const search1 = new URLSearchParams(search);
  // console.log(search, search1);

  useEffect(() => {
    if (data?.success) {
      return navigate("/auth/login");
    }
    if (search1.get("email")) {
      searchParams.set("email", search1.get("email"));
      setSearchParams(searchParams);
    }
    if (search1.get("phone")) {
      searchParams.set("phone", search1.get("phone"));
      setSearchParams(searchParams);
    }
    // console.log(searchParams);
    searchParams.get("phone")
    const search = searchParams.toString();
    // console.log(search);
    return navigate("/auth/verify/resend?" + search); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className="w-full h-full mb-20">
      <HeaderLogo />
      <div className="md:mb-52 mt-10 mb-36">
        <Link to="/">
          <span className="text-[#590DA4] md:text-lg font-[900]">
            Go Back Home
          </span>
        </Link>
      </div>
      <div className="w-full">
        <h1 className="mb-2 text-2xl md:text-5xl font-bold">Verification</h1>
        <p className="mt-5 mb-10">
          A verification Code has been sent
          {/* <strong>{data?.data.email ?? data?.data.phone_number}</strong> */}
        </p>
        <Form action={formAction} method="post">
          <SingleInput name="verification_code" />
          <Input
            type="hidden"
            name={
              search1.get("phone")
                ? "phone_number"
                : search1.get("email")
                ? "email"
                : ""
            }
            value={search1.get("email") || search1.get("phone") || ""}
            error={data?.error?.email}
          />
          <Outlet />
          {/* <input
            type="hidden"
            name="phone_number"
            value={search1.get("phone")}
            error={data?.error?.email}
          /> */}
          <p className="text-red-600">{!data?.success && data?.message}</p>
          <Submit isLoading={state !== "idle"}>Verify Account</Submit>
        </Form>
      </div>
    </div>
  );
};

export default Verification;
