import PageHeader from "../../../Components/Dashboard/PageHeader";
import SupportImage from "../../../Assets/images/SupportImage.png";
import TextArea from "../../../Components/Forms/TextArea";
import Submit from "../../../Components/Forms/Submit";
import { useFetcher } from "react-router-dom";
import { useEffect } from "react";
import { useContext } from "react";
import ToastContext from "../../../Routes/ToastContext";
import Select from "../../../Components/Forms/Select";
import { useState } from "react";

const Support = ({ setState }) => {

    const { Form, data, state } = useFetcher();
    const { flashToast } = useContext(ToastContext)


    useEffect(() => {
        flashToast(data?.success ?? false, data?.message);
        if (data?.success) {
            setState(v => !v);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    return (
        <>
            <PageHeader>Help & Support</PageHeader>
            <div className="mt-10 grid grid-cols-12">
                <div className="hidden md:col-span-6 md:flex flex-col justify-center items-center">
                    <div className="md:px-32 px-20">
                        <img src={SupportImage} alt="" />
                        <p className="text-[#4B5768] text-[20px] text-center font-[600]">
                            We are available 24/7. Feel free to reach out.
                        </p>
                    </div>
                </div>
                <div className="col-span-12 md:col-span-6">
                    <div className="md:px-8">
                        <Form method="post" className="bg-white text-left flex flex-col items-start justify-start space-y-3  p-8 rounded-[16px]">
                            <Categories />
                            <TextArea
                                label="Message"
                                name="message"
                                placeholder="Type your message"
                                rows="4"
                                error={data?.errors?.message}
                            />

                            <Submit type="submit" isLoading={state !== 'idle'}>
                                Send Message
                            </Submit>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    );
};

const Categories = () => {

    const [cat, setCat] = useState('');
    const [subCat, setSubCat] = useState([]);
    const data = {
        'Category 1': [
            'Sub Category 1 1',
            'Sub Category 1 2'
        ],

        'Category 2': [
            'Sub Category 2 1',
            'Sub Category 2 2'
        ]
    };

    useEffect(() => {
        setSubCat(cat)
    }, [cat])



    return (
        <>
            <div className="w-full">
                <label className="mb-1 capitalize text-gray-700 text-base w-full" style={{ fontWeight: 700, fontSize: '18px' }}>
                    Categories
                </label>
                <Select name='category' onChange={e => setCat(e.target.value)}>
                    <option>Select Categories</option>
                    {
                        Object.keys(data)?.map((v, k) => (<option value={v} key={k}>{v}</option>))
                    }
                </Select>
            </div>
            <div className="w-full">
                <label className="mb-1 capitalize text-gray-700 text-base w-full" style={{ fontWeight: 700, fontSize: '18px' }}>
                    Sub Categories
                </label>
                <Select name="sub_category"  >
                    <option>Select Sub Categories</option>
                    {
                        data[subCat]?.map((v, k) => (<option value={v} key={k}>{v}</option>))
                    }
                </Select>
            </div>

        </>
    )
}

export default Support;
