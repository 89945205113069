import axios from "axios";
import UserObject from "../DataObjects/UserObject";

// const BASE_URL = "https://api.upload.io"
// Test APi = https://apitest.fedacash.com/api
const BASE_URL = "https://api.fedacash.com/api";
const DEVELOP_URL = "https://apitest.fedacash.com/api";
const http = axios.create({
  // baseURL: BASE_URL,
  baseURL: process.env.REACT_APP_VERCEL_ENV !== 'production' ? DEVELOP_URL : BASE_URL,
  // timeout: 5000,
  headers: {
    "Content-Type": "application/json",
  },
});

http.interceptors.request.use(
  (config) => {
    const user = UserObject.getUser();
    // console.log(user);
    if (user.token) {
      config.headers.Authorization = `Bearer ${user.token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default http;
