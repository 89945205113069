import { LoanTab } from "../../../Components/Dashboard/Loan/LoanTab";
import { LoanWalletCard } from "../../../Components/Dashboard/Loan/LoanWallet";

const LoanIndex = () => {
  return (
    <section className="space-y-4">
      <div className="">
        <LoanWalletCard module_name={`Loan`}/>
      </div>
      <LoanTab />
    </section>
  );
};

export default LoanIndex;
