import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
  Routes,
  ScrollRestoration,
} from "react-router-dom";
import LandingPage from "../Layouts/LandingPage";
import LandIndex from "../../src/Pages/LandingPage/Index";
import AuthLayout from "../Layouts/AuthLayout";
import Login from "../Pages/Auth/Login";
import AboutUs from "../Pages/AboutUs/AboutUs";
import ForgetPassword from "../Pages/Auth/ForgetPassword";
import Verification from "../Pages/Auth/Verification";
import ResetPassword from "../Pages/Auth/ResetPassword";
import PasswordReset from "../Pages/Auth/PasswordReset";
import PrivacyPolicy from "../Pages/PrivacyPolicy/PrivacyPolicy";
import Terms from "../Pages/Terms/Terms";
import ContactUs from "../Pages/ContactUs/ContactUs";
import Dashboard from "../Layouts/Dashboard";
import JoinList from "../Pages/App/JoinList/JoinList";
import {
  ForgotPasswordService,
  LoginService,
  RegisterService,
  ResendPasswordVerificationService,
  ResendVerificationService,
  ResetPasswordService,
  VerifyPasswordService,
  VerifyResetService,
  VerifyService,
} from "../Services/AuthServices";
import Register from "../Pages/Auth/Register";
import PasswordResetVerify from "../Pages/Auth/PasswordResetVerify";
import { JoinListService } from "../Services/MiscellaneousService";
import DashboardIndex from "../Pages/App/Dashboard/DashboardIndex";
import ResendVerification from "../Pages/Auth/ResendVerification";
import Transaction from "../Pages/App/Transaction/Transaction";
import DashboardController from "../Services/Dashboard/DashboardController";
import TransactionHistoryController from "../Services/Dashboard/TransactionHIstoryController";
import DashboardPanel from "../Components/Dashboard/Dashboard";
import TransactionView from "../Pages/App/Transaction/TransactionView";
import InvoiceCreate from "../Pages/App/Invoice/InvoiceCreate";
import Invoice from "../Pages/App/Invoice/Invoice";
import Settings from "../Pages/App/Setting/Settings";
import InvoiceService from "../Services/Dashboard/InvoiceService";
import TransactionCreate from "../Pages/App/Transaction/TransactionCreate";
import AuditLogService from "../Services/Dashboard/AuditLogService";
import Profile from "../Pages/App/Setting/Profile";
import KYCDetails from "../Pages/App/Setting/KYCDetails";
import AuditLogs from "../Pages/App/Setting/AuditLogs";
import Members from "../Pages/App/Setting/Members";
import SupportService from "../Services/Dashboard/SupportService";
import SupportIndex from "../Pages/App/Support/SupportIndex";
import FAQ from "../Pages/App/Support/FAQ";
import SupportWrapper from "../Pages/App/Support/SupportWrapper";
import FAQService from "../Services/Dashboard/FAQService";
import MemberService, { address_verification_status } from "../Services/Dashboard/MemberService";
import ProfileService, {
  ProfileLoader,
} from "../Services/Dashboard/ProfileService";
import Complete from "../Pages/Auth/Complete";
import OnboardService from "../Services/Dashboard/OnboardService";
import KYCController from "../Services/Dashboard/KYCController";
import CompleteLogin from "../Pages/App/JoinList/CompleteLogin";
import GeneralService from "../Services/Dashboard/GeneralService";
import Error404 from "../Pages/Errors/Error404";
import CooperativeIndex from "../Pages/App/Cooperative/CooperativeIndex";
import CooperativeService from "../Services/Dashboard/CooperativeService";
import Eligibility from "../Pages/Terms/Elegibility";
//landing page
import Homepage from "../Pages/App/landing";
import Layout from "../Pages/App/landing/layout";
import About from "../Pages/App/landing/about";
import CooperativeServices from "../Pages/App/landing/services/cooperative";
import InvoiceServices from "../Pages/App/landing/services/invoice";
import GenerateInvoice from "../Pages/App/landing/services/GenerateInvoice";
import Contact from "../Pages/App/landing/contact";
import SaveandGo from "../Pages/App/Cooperative/SaveandGo";
import SaveandPack from "../Pages/App/Cooperative/SaveandPack";
import { Planlist } from "../Services/Dashboard/SaveandGo";
import JoinSharedPlan from "../Pages/App/Cooperative/JoinSharedPlan";
import { SharedPlanLoader } from "../Services/Dashboard/JoinSharedPlanService";
import {
  CreateLoanRequest,
  LoanRequestLoader,
  SingleLoanRequestLoader,
} from "../Services/Dashboard/LoanRequestService";
import LoanIndex from "../Pages/App/Loan/index.loan";
import Repayment from "../Pages/App/Loan/Repayment";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import SalaryIndex from "../Pages/App/Salary/index.salary";
import { CompleteOnboard, SalaryAdvanceRequest, SalaryRequestLoader } from "../Services/Dashboard/SalaryAdvanceService";
import CompleteSalaryAdvance from "../Pages/Auth/SalaryAdvanceOnboard";

const queryClient = new QueryClient();

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={
        <>
          <ScrollRestoration />
          <Outlet />
        </>
      }
      errorElement={<Error404 />}
    >
      {/* <Route path="/" element={<LandingPage />}>
        <Route index exact element={<LandIndex />} />
        <Route exact path="about-us" element={<AboutUs />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/terms-and-conditions" element={<Terms />} />
        <Route
          exact
          path="/eligibility-and-criterial"
          element={<Eligibility />}
        />
        <Route exact path="/contact-us" element={<ContactUs />} />
      </Route> */}
      <Route path="/" element={<Layout />}>
        <Route index exact element={<Homepage />} />
        <Route path="about" exact element={<About />} />
        <Route
          path="services/cooperative"
          exact
          element={<CooperativeServices />}
        />
        <Route
          path="services/invoice-discount"
          exact
          element={<InvoiceServices />}
        />
        <Route
          path="services/generate-invoice"
          exact
          element={<GenerateInvoice />}
        />

        <Route path="contact" exact element={<Contact />} />
        <Route exact path="privacy-policy" element={<PrivacyPolicy />} />

        <Route exact path="terms-and-conditions" element={<Terms />} />
        <Route
          exact
          path="eligibility-and-criterial"
          element={<Eligibility />}
        />
      </Route>

      <Route path="/auth" element={<AuthLayout />}>
        <Route exact path="login" element={<Login />} action={LoginService} />
        <Route
          exact
          path="register"
          element={<Register />}
          action={RegisterService}
        />
        <Route
          exact
          path="forgot-password"
          element={<ForgetPassword />}
          action={ForgotPasswordService}
        />
        <Route
          exact
          path="verify"
          element={<Verification />}
          action={VerifyService}
        >
          <Route
            exact
            path="resend"
            element={<ResendVerification />}
            action={ResendVerificationService}
          />
        </Route>
        <Route
          exact
          path="complete/salaryadvance/:id"
          action={CompleteOnboard}
          element={<CompleteSalaryAdvance/>}
        />
        <Route
          exact
          path="complete/onboard/:id"
          action={OnboardService.apply().action}
          element={<Complete />}
        />
        <Route
          exact
          path="password-reset-verify"
          element={<PasswordResetVerify />}
          action={VerifyPasswordService}
        />
        <Route
          exact
          path="resend-password-verify"
          action={ResendPasswordVerificationService}
        />
        <Route
          exact
          path="reset-password"
          element={<ResetPassword />}
          action={ResetPasswordService}
        />
        <Route
          exact
          path="password-reset"
          element={<PasswordReset />}
          action={VerifyResetService}
        />
      </Route>

      <Route path="/dashboard" element={<AuthLayout />}>
        <Route
          exact
          path="apply"
          element={<JoinList />}
          action={JoinListService}
        />
        <Route
          exact
          path="complete"
          element={<CompleteLogin />}
          action={GeneralService.complete}
        />
      </Route>

      <Route
        path="/dashboard"
        element={<Dashboard />}
        loader={DashboardController.apply().loader}
        action={KYCController.apply().action}
      >
        <Route
          element={<DashboardIndex />}
          loader={TransactionHistoryController.dashboard}
        >
          <Route
            index
            element={<DashboardPanel />}
            loader={DashboardController.apply().loader}
          />
        </Route>
        <Route
          exact
          path="transaction"
          element={<Transaction />}
          loader={TransactionHistoryController.apply().loader}
        />
        <Route
          exact
          path="transaction/create"
          element={<TransactionCreate />}
          action={DashboardController.apply().action}
        />
        <Route
          exact
          path="transaction/:id"
          element={<TransactionView />}
          action={TransactionHistoryController.apply().action}
          loader={TransactionHistoryController.view}
        />
        <Route
          exact
          path="invoice"
          element={<Invoice />}
          action={InvoiceService.email}
          loader={InvoiceService.apply().loader}
        />
        <Route
          exact
          path="invoice/create"
          element={<InvoiceCreate />}
          action={InvoiceService.apply().action}
          loader={InvoiceService.template}
        />
        {/* <Route exact path="cooperative">
          <Route
            index
            element={<CooperativeIndex />}
            loader={CooperativeService.apply().loader}
            action={CooperativeService.apply().action}
          />
        </Route> */}
        <Route
          path="cooperative/saveandgo"
          element={<SaveandGo />}
          loader={Planlist(queryClient)}
          // action={CooperativeService.apply().action}
        />
        <Route
          path="cooperative/join/:token"
          element={<JoinSharedPlan />}
          loader={SharedPlanLoader}
          // action={CooperativeService.apply().action}
        />
        <Route
          path="cooperative/saveandpack"
          element={<SaveandPack />}
          loader={CooperativeService.apply().loader}
          action={CooperativeService.apply().action}
        />
        <Route
          path="loan/request"
          element={<LoanIndex />}
          loader={LoanRequestLoader(queryClient)}
          action={CreateLoanRequest(queryClient)}
        />
        <Route
          path="loan/request/:id"
          element={<Repayment />}
          loader={SingleLoanRequestLoader(queryClient)}
          // action={CooperativeService.apply().action}
        />
        <Route
          path="salary/request"
          element={<SalaryIndex/>}
          loader={SalaryRequestLoader(queryClient)}
          action={SalaryAdvanceRequest(queryClient)}
        />
        <Route exact path="settings" element={<Settings />}>
          <Route
            index
            element={<Profile />}
            loader={ProfileLoader}
            action={ProfileService.apply().action}
          />
          <Route
            path="members"
            element={<Members />}
            loader={MemberService.apply().loader}
            action={MemberService.apply().action}
          />
          <Route
            path="audit-logs"
            element={<AuditLogs />}
            loader={AuditLogService.apply().loader}
            action={AuditLogService.apply().action}
          />
          <Route
            path="kyc-details"
            element={<KYCDetails />}
            loader={address_verification_status}
            action={KYCController.apply().action}
          />
        </Route>
        <Route
          path="FAQ"
          exact
          element={<SupportWrapper />}
          action={SupportService.apply().action}
        >
          <Route
            exact
            index
            element={<FAQ />}
            loader={FAQService.apply().loader}
          />
        </Route>
        <Route
          exact
          path="help-and-support"
          element={<SupportWrapper />}
          action={SupportService.apply().action}
        >
          <Route
            exact
            index
            element={<SupportIndex />}
            loader={SupportService.apply().loader}
            action={SupportService.apply().action}
          />
        </Route>
      </Route>
    </Route>
  )
);

const Index = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
};

export default Index;
