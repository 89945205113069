import { motion, useInView } from "framer-motion";
import { useRef } from "react";

///
import manageInvoices from "../../../../Assets/images/manage-invoices.png";
import generateMask from "../../../../Assets/images/generate-mask.png";
import CTA from "../../../../Components/LandingPage/cta";
import { FADE_UP_ANIMATION_VARIANTS } from "../../../../utils/framer-variants";

const GenerateInvoice = () => {
  const invoiceRef = useRef(null);
  const isInView = useInView(invoiceRef, { once: true });
  return (
    <section>
      <section className="xl:px-12 px-4 container mx-auto lg:py-8 py-10">
        <motion.section
          initial="hidden"
          animate={"show"}
          viewport={{ once: true }}
          variants={{
            hidden: {},
            show: {
              transition: {
                staggerChildren: 0.15,
              },
            },
          }}
          className="flex flex-col-reverse  lg:flex-row gap-y-3 xl:gap-3 justify-center items-center lg:justify-evenly"
        >
          <motion.img
            src={generateMask}
            variants={FADE_UP_ANIMATION_VARIANTS}
            alt="Masked Background with our Logo"
            className="h-full lg:w-2/5 w-[195px]"
          />
          {/* <SliderComponent /> */}

          <div className="max-w-3xl lg:w-3/5 lg:text-left text-center">
            <div className="lg:text-[2.5rem] md:text-2xl text-xl font-bold leading-loose">
              <motion.h2
                variants={FADE_UP_ANIMATION_VARIANTS}
                className="text-fedacash-header"
              >
                Generate Invoice For free!{" "}
              </motion.h2>
            </div>

            <div className="flex flex-col gap-7 lg:mt-7 text-fedacash-textColor md:text-lg text-xs font-medium">
              <motion.p
                variants={FADE_UP_ANIMATION_VARIANTS}
                className="leading-[165%] "
              >
                Sign up on FedaCash, Log in and edit your Invoice. Tailor your
                invoices to match your brand identity. Add your company logo,
                choose from various color themes, and personalize the invoice
                layout to give your documents a professional touch. Download
                your invoice and forward to your customer seamlessly.
              </motion.p>
            </div>
          </div>
        </motion.section>
      </section>
      <motion.section
        initial="hidden"
        animate={isInView ? "show" : "hide"}
        viewport={{ once: true }}
        variants={{
          hidden: {},
          show: {
            transition: {
              staggerChildren: 0.15,
            },
          },
        }}
        className="xl:px-2 px-4 container mx-auto lg:py-6 py-8"
      >
        <div className="flex items-center justify-center mb-5">
          <motion.h3 className="md:text-base text-xs text-fedacash-header font-semibold shadow-header p-4 rounded-[30px] bg-customGray">
            OUR TARGET MARKET
          </motion.h3>
        </div>
        <section className="lg:px-20 px-6 py-8 mt-4 bg-white rounded-[32px] shadow-section">
          <div className="flex lg:flex-row flex-col gap-x-6 gap-y-4 items-center justify-between  py-8">
            <motion.img
              src={manageInvoices}
              alt="target Market"
              className="lg:w-1/2"
            />
            <div className="flex flex-col gap-3 max-w-xl lg:w-1/2">
              <motion.h3
                variants={FADE_UP_ANIMATION_VARIANTS}
                className="lg:text-4xl md:text-2xl text-sm font-semibold text-fedacash-header"
              >
                Manage Invoices
              </motion.h3>
              <motion.p
                variants={FADE_UP_ANIMATION_VARIANTS}
                ref={invoiceRef}
                className="lg:text-xl md:text-base text-[10px]"
              >
                Stay on top of your invoicing with our intuitive Manage Invoice
                feature. Easily create, track, and manage your invoices in one
                centralized platform. Keep a clear record of your transactions
                and streamline your billing process.
              </motion.p>
            </div>
          </div>
        </section>
      </motion.section>
      <CTA />
    </section>
  );
};

export default GenerateInvoice;
