import { createRef, useCallback, useState } from "react";

const useToggler = (state = false) => {
    const [value, setValue] = useState(state)
    const pointer = createRef();

    const handleClick = useCallback(() => {
        setValue(!value)
    }, [value])

    const setFalse = () => {
        setValue(v => false)
    }

    return [value, handleClick, pointer, setFalse]
}

export default useToggler;