import {
  BsFacebook,
  BsInstagram,
  BsPencil,
  BsTwitter,
  BsUpload,
  BsX,
} from "react-icons/bs";
import { AiOutlineMail, AiOutlinePhone, AiOutlineClose } from "react-icons/ai";
import { BiPaperPlane, BiPlus } from "react-icons/bi";
import { useFetcher, useLoaderData, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState, Fragment, Suspense } from "react";
import { Tab, Dialog, Transition } from "@headlessui/react";

import UserObject from "../../../DataObjects/UserObject";
import Password from "../../../Components/Forms/Password";
import Submit from "../../../Components/Forms/Submit";
import ToastContext from "../../../Routes/ToastContext";
import Input from "../../../Components/Forms/Input";
import IconInput from "../../../Components/Forms/IconInput";
import useTitle from "../../../Hooks/useTitle";
import { StatusDialog } from "../../../Components/Dashboard/Shared/StatusDialog";
import {
  UpdateUserBankInfo,
  VertifyBankAccount,
} from "../../../Services/Dashboard/ProfileService";
import Banks from "../Dashboard/Banks";

const Profile = () => {
  useTitle("Profile");
  const { bankDetails } = useLoaderData();

  const { Form, data, state } = useFetcher();
  const { flashToast } = useContext(ToastContext);
  const {
    first_name,
    last_name,
    phone_number,
    email,
    instagram_handle,
    twitter_handle,
    profile_photo,
    facebook_handle,
    kyc_tier
  } = UserObject.getUser();

  useEffect(() => {
    flashToast(data?.success ?? false, data?.message);
    if (data?.success && data?.data?.length <= 0) {
      // UserObject.from(data?.data).storeUser()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return (
    <div className="px-[40px] py-[31.52px] flex flex-col items-start divide-y divide-gray-500 w-full">
      <div className="w-full pb-[35.16px]">
        <div className="w-full grid grid-cols-5 md:gap-x-[56px] gap-5">
          <div className="col-span-full md:col-span-2 place-self-start">
            <h2 className="font-[900] text-[24px] leading-[29px] -tracking-[0.02em]">
              Personal Details
            </h2>
            <p className="font-[400] mt-[12px] text-[14px] leading-[21px]">
              Update your personal details
            </p>
          </div>
          <div className="col-span-full md:col-span-3 place-self-start ">
            <div className="inline-flex items-center justify-start space-x-[16px]">
              <div className="bg-center bg-no-repeat flex items-center overflow-hidden justify-center bg-cover w-[56px] h-[56px] bg-gray-500 rounded-full relative">
                {profile_photo && (
                  <img
                    src={profile_photo}
                    alt="profile"
                    className="w-full h-full border-none"
                  />
                )}
                {!profile_photo && (
                  <span className="text-2xl font-semibold tracking-tighter text-white">
                    {first_name[0]}
                    {last_name[0]}
                  </span>
                )}
                <button className="bg-purple-900 absolute flex rounded items-center p-0.5 right-0 bottom-0">
                  <BsUpload className="w-3 h-3 text-white " />
                </button>
              </div>
              <p className="font-[700] text-[20px] leading-[30px] ">
                {first_name} {last_name}
              </p>
            </div>
          </div>
        </div>
        <div className="w-full grid grid-cols-5 gap-5 md:gap-x-[56px] my-5">
          <div className="flex items-start justify-start mt-10 col-span-full md:col-span-2">
            <EditButton />
          </div>
          <div className="col-span-full md:col-span-3 place-self-start">
            <div className="grid grid-cols-1 gap-10 xl:grid-cols-2">
              <div className="flex flex-col items-start justify-start">
                <AiOutlineMail className="w-6 h-6 text-black mb-[13px]" />
                <p className="font-[400] text-[14px] leading-[21px] text-[#64748B]">
                  Email Address
                </p>
                <p className="font-[700] text-[18px] leading-[27px] overflow-x-auto xl:w-[150px]">{email}</p>
              </div>
              <div className="flex flex-col items-start justify-start w-full">
                <AiOutlinePhone className="w-6 h-6 text-black mb-[13px]" />
                <p className="font-[400] text-[14px] leading-[21px] text-[#64748B]">
                  Phone Number
                </p>
                <p className="font-[700] text-[18px] leading-[27px]">
                  {phone_number}
                </p>
              </div>
              <div className="flex flex-col items-start justify-start w-full">
                <BsInstagram className="w-6 h-6 text-black mb-[13px]" />
                <p className="font-[400] text-[14px] leading-[21px] text-[#64748B]">
                  Instagram Handle
                </p>
                <p className="font-[700] text-[18px] leading-[27px]">
                  {instagram_handle}
                </p>
              </div>
              <div className="flex flex-col items-start justify-start w-full">
                <BsTwitter className="w-6 h-6 text-black mb-[13px]" />
                <p className="font-[400] text-[14px] leading-[21px] text-[#64748B]">
                  Twitter Handle
                </p>
                <p className="font-[700] text-[18px] leading-[27px]">
                  {twitter_handle}
                </p>
              </div>
              <div className="flex flex-col items-start justify-start w-full">
                <BsFacebook className="w-6 h-6 text-black mb-[13px]" />
                <p className="font-[400] text-[14px] leading-[21px] text-[#64748B]">
                  Facebook Handle
                </p>
                <p className="font-[700] text-[18px] leading-[27px]">
                  {facebook_handle}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full pb-[35.16px] py-12">
        <div className="w-full grid grid-cols-5 md:gap-x-[56px] gap-5">
          <div className="col-span-full md:col-span-2 place-self-start">
            <h2 className="font-[900] text-[24px] leading-[29px] -tracking-[0.02em]">
              Bank Details
            </h2>
            <p className="font-[400] mt-[12px] text-[14px] leading-[21px]">
              Update your bank details here
            </p>
          </div>
          <div className="col-span-full md:col-span-2 place-self-start">
            <div className="w-full flex flex-col gap-y-4">
              <div className="flex items-start justify-start">
                <UpdateBankInformation />
              </div>
              <div className="w-full">
                <div className="w-full">
                  <ul className="space-y-3 h-[350px] overflow-y-scroll">
                    {
                    bankDetails?.data?.bank_object?.length !== 0 &&(
                       bankDetails?.data?.bank_object?.map((bank, index) => {
                          return (
                            <li
                              className="flex flex-col items-start justify-start bg-[#F6F8FA] px-4 py-3 rounded-md "
                              key={index}
                            >
                              <p className="text-lg font-medium">
                                {bank.account_number}
                              </p>
                              <p className="">{bank.bank_name}</p>
                              <p className="">{bank?.account_name}</p>
                            </li>
                          );
                        })
                      )
                    }
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full pt-[51.51px]">
        <div className="w-full grid grid-cols-5 md:gap-x-[56px]">
          <div className="col-span-full md:col-span-2 place-self-start">
            <h2 className="font-[900] text-[24px] leading-[29px] -tracking-[0.02em]">
              Reset Password
            </h2>
            <p className="font-[400] mt-[12px] text-[14px] leading-[21px]">
              You can reset your password here
            </p>
          </div>
          <div className="w-full col-span-full md:col-span-3 place-self-start ">
            <Form method="put" className="w-2/3">
              <Password label="Current password" name="old_password" />
              <Password label="New password" name="password" />
              <Password
                label="Confirm New Password"
                name="password_confirmation"
              />
              <Submit isLoading={state !== "idle"}>Reset Password</Submit>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

const EditButton = () => {
  const [state, setState] = useState(false);
  return (
    <>
      <button
        onClick={() => setState((v) => !v)}
        className="text-[#590DA4] flex font-semibold items-center justify-start space-x-2"
      >
        <BsPencil className="w-3 h-3 border-b border-[#590DA4]" />
        <span>Edit</span>
      </button>
      <div
        data-active={state}
        className=" data-[active=false]:hidden top-0 left-0 z-[999] flex items-start pt-10 justify-center fixed w-full h-screen overflow-y-auto bg-purple-700/70"
      >
        <div className="relative max-w-sm w-full bg-white py-8 rounded-[16px]">
          <button
            onClick={() => setState((v) => !v)}
            className="absolute bg-white rounded-full -left-3 -top-3"
          >
            <BsX className="w-6 h-6" />
          </button>
          <EditModal setState={setState} />
        </div>
      </div>
    </>
  );
};

const EditModal = ({ setState }) => {
  const { Form, data, state } = useFetcher();
  const { flashToast } = useContext(ToastContext);
  const {
    first_name,
    last_name,
    phone_number,
    email,
    instagram_handle,
    twitter_handle,
    profile_photo,
    facebook_handle,
  } = UserObject.getUser();

  useEffect(() => {
    flashToast(data?.success ?? false, data?.message);
    if (data?.success) {
      UserObject.from(data?.data).storeUser();
      setState((v) => !v);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <h1 className="px-6 text-xl font-semibold text-left">
        Edit Personal Details
      </h1>
      <div className="w-full mt-5">
        <div className="w-full text-left">
          <div className="px-6 py-4">
            <Form method="post" encType="multipart/form-data">
              <div className="flex items-center justify-start mb-5 space-x-2">
                <div
                  className="w-[72px] h-[72px] bg-gray-600 rounded-full"
                  style={{ backgroundImage: `url(${profile_photo})` }}
                ></div>
                <label className="flex items-center justify-center py-[6px] px-[12px] rounded-[8px] border border-gray-600">
                  <input className="hidden" type="file" name="profile_photo" />
                  <span className="font-[700] text-[11px] leading-[24px]">
                    Upload New Photo
                  </span>
                </label>
              </div>
              <Input
                normalize={true}
                name="first_name"
                placeholder="Enter your first name"
                defaultValue={first_name}
                label="First Name"
                error={data?.errors?.first_name}
              />
              <Input
                normalize={true}
                name="last_name"
                placeholder="Enter your last name"
                label="Last Name"
                defaultValue={last_name}
                error={data?.errors?.last_name}
              />
              <Input
                name="email"
                label="email"
                error={data?.errors?.email}
                placeholder="Enter your email address"
                value={email}
              />
              <Input
                label="Phone Number"
                name="phone_number"
                error={data?.errors?.phone_number}
                placeholder="Enter your phone number"
                value={phone_number}
              />
              <IconInput
                name="instagram_handle"
                label="instagram handle"
                LabelIcon={BsInstagram}
                error={data?.errors?.phone_number}
                options={{
                  prefix: "@",
                  lowercase: true,
                }}
                value={instagram_handle}
              />
              <IconInput
                name="twitter_handle"
                label="twitter handle"
                LabelIcon={BsTwitter}
                error={data?.errors?.phone_number}
                options={{
                  prefix: "@",
                  lowercase: true,
                }}
                value={twitter_handle}
              />
              <IconInput
                name="facebook_handle"
                label="facebook handle"
                LabelIcon={BsFacebook}
                error={data?.errors?.phone_number}
                options={{
                  prefix: "@",
                  lowercase: true,
                }}
                value={facebook_handle}
              />
              <div className="flex items-center justify-end w-full space-x-2">
                <button
                  onClick={() => {
                    setState((v) => !v);
                  }}
                  type="reset"
                  className="py-3 w-full px-4 rounded-md tracking-wider text-base min-h-[59px] font-bold border-[#590DA4] border text-[#590DA4] hover:text-white disabled:opacity-50 hover:bg-purple-900"
                >
                  Cancel
                </button>
                <Submit type="submit" isLoading={state !== "idle"}>
                  Submit
                </Submit>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

const UpdateBankInformation = () => {
  const user = UserObject.getUser();
  const { Form } = useFetcher();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = useState(0);
  //state for the api response message
  const [message, setMessage] = useState({});
  const [accountName, setAccountName] = useState("");

  const [loading, setLoading] = useState(false);

  //our values from the form
  const [bankCode, setBankCode] = useState("");
  const [formValues, setFormValues] = useState({
    account_number: "",
    bank_name: "",
  });
  ///error from form
  const [error, setError] = useState("");
  const toggleUpdateBankInfoModal = () => {
    setIsModalOpen((prev) => !prev);
  };
  const handleChange = (name, value) => {
    // console.log(name, value);
    setFormValues({ ...formValues, [name]: value });
  };
  const [isStatusOpen, setIsStatusOpen] = useState(false);
  function closeStatusModal() {
    setIsStatusOpen(false);
  }
  function openStatusModal() {
    setIsStatusOpen(true);
  }
  const NextPage = () => {
    if (page < 1) {
      setPage((prevValue) => prevValue + 1);
    }
  };
  const BankValidation = async () => {
    setLoading(true);
    VertifyBankAccount(formValues, bankCode).then((res) => {
      // console.log(res.VertifyBankAccountResponse);
      if (res.VertifyBankAccountResponse.success) {
        setLoading(false);
        setError("");
        setAccountName(
          res?.VertifyBankAccountResponse?.data?.data?.account_name
        );
        NextPage();
      } else {
        setLoading(false);
        // console.log(res?.VertifyBankAccountResponse);
        setAccountName("");
        setError(res?.VertifyBankAccountResponse?.message);
      }
    });
  };
  const SubmitBankInfo = async () => {
    setLoading(true);
    UpdateUserBankInfo(formValues, bankCode, accountName)
      .then((res) => {
        setLoading(false);
        console.log(bankCode)
        console.log(res.UpdateUserBankInfoResponse)
        setMessage(res.UpdateUserBankInfoResponse);
        openStatusModal();
        toggleUpdateBankInfoModal();
        navigate(".");
      })
      .catch((res) => {
        setLoading(false);
        setError(res.UpdateUserBankInfoResponse);
      });
  };

  const validateForm = () => {
    if (formValues.account_number === "") {
      setError("Please enter your account number");
    }
    if (formValues.bank_name === "") {
      setError("Please enter your account number");
    }
    if (
      formValues.account_number.length < 9 ||
      formValues.account_number.length > 10
    ) {
      setError("Please enter a valid account number");
    } else {
      // console.log(formValues, bankCode);
      BankValidation();
    }
  };
  return (
    <div>
      <button
        onClick={toggleUpdateBankInfoModal}
        className="flex items-center py-2 px-5 space-x-2 bg-[#120321] text-white rounded-lg font-semibold text-xs md:text-sm leading-[21px] "
      >
        <BiPlus className="w-6 h-6 " />
        <span>Add</span>
      </button>
      <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={toggleUpdateBankInfoModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-fedacash-header bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full rounded-md max-w-xl transform overflow-y-visible bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="flex gap-3 justify-evenly text-lg font-bold leading-6 text-[#475467]"
                  >
                    Add New Bank Details to your account
                    <button
                      type="button"
                      className=""
                      onClick={() => toggleUpdateBankInfoModal()}
                    >
                      <AiOutlineClose />
                    </button>
                  </Dialog.Title>
                  <div className="flex flex-col items-center justify-center">
                    {page === 0 ? (
                      <Form
                        onSubmit={(event) => event.preventDefault()}
                        className="py-4"
                      >
                        <div className="space-y-4">
                          <div>
                            <label className="text-sm md:text-lg font-bold">
                              Account Number
                            </label>
                            <input
                              placeholder="Account Number"
                              value={formValues.account_number}
                              name="account_number"
                              onChange={(e) =>
                                handleChange("account_number", e.target.value)
                              }
                              required
                              className="font-bold w-full text-[12px] leading-[18px] py-[11px] px-[19px] placeholder:text-[#b8c0cc] border-[1.5px] rounded-[8px] border-[#b8c0cc] focus:outline-none"
                            />
                          </div>
                          <Banks
                            top={false}
                            onChange={(e) =>
                              handleChange("bank_name", e.target.value)
                            }
                            label="Bank Name"
                            placeholder={
                              formValues.bank_name ?? "select a bank"
                            }
                            name="bank_name"
                            setBankCode={setBankCode}
                          />
                          {error !== "" && (
                            <p className="text-red-700 text-xs md:text-sm py-2 font-semibold">
                              {error}
                            </p>
                          )}
                          {accountName !== "" && (
                            <p className="text-green-700 text-xs md:text-sm py-2 font-semibold">
                              {formValues.account_name}
                            </p>
                          )}

                          <div className="mt-4">
                            <Submit
                              isLoading={loading}
                              onClick={() => {
                                validateForm();
                              }}
                            >
                              Proceed
                            </Submit>
                          </div>
                        </div>
                      </Form>
                    ) : (
                      <div className="py-4">
                        <div className="space-y-3 text-sm md:text-lg font-medium">
                          <p>{formValues.account_number}</p>
                          <p>{formValues.bank_name}</p>
                          <p>{accountName}</p>
                        </div>
                        <div className="mt-4">
                          <Submit
                            isLoading={loading}
                            onClick={() => {
                              SubmitBankInfo();
                            }}
                          >
                            Proceed
                          </Submit>
                        </div>
                      </div>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <StatusDialog
        closeModal={closeStatusModal}
        isOpen={isStatusOpen}
        message={message}
      />
    </div>
  );
};

export default Profile;
