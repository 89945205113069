import {useState} from "react";

const SingleInput = ({length = 1, ...props}) => {
    const arr = Array(4).fill(0);
    const handle = (e) => {
        e.target.value = e.target.value.replace(/[^0-9.]/g, "");
        if (!e.target.value.length && e.target.previousSibling) {
            e.target.previousSibling.focus();
            return;
        }

        e.target.value.length
        && e.target.nextSibling
        && e.target.nextSibling.focus();
    };

    const inp = ["", "", "", ""]
    const [value, setValue] = useState(inp);
    const [inValue, setInValue] = useState("")

    const handleChange = (e, i) => {
        e.target.value = e.target.value.replace(/[^0-9.]/g, "");
        value[i] = e.target.value ?? ""
        setValue(value);
        setInValue(value.join(""))
    };

    return (
        <div>
            <div className="mb-5 flex items-center justify-between md:pr-20">
                {arr.map((v, i) => (
                    <input
                        key={i}
                        type="text"
                        className="h-16 appearance-none w-16 text-xl text-center focus:outline-none active:border-purple-800 valid:border-purple-800 focus:border-purple-800 border font-bold border-gray-500/50 rounded-lg"
                        required={true}
                        maxLength="1"
                        minLength="1"
                        // pattern="[0-9]"
                        onInput={handle}
                        onChange={(e) => {
                            handleChange(e, i)
                        }}
                    />
                ))}
            </div>
            <input type="hidden" {...props} value={inValue}/>
        </div>
    );
};

export default SingleInput;
