import ResponseObject from "../../DataObjects/ResponseObject";
import UserObject from "../../DataObjects/UserObject";
import http from "../../Store/baseHttp";
import BaseController from "./BaseController";

class KYCController extends BaseController {
  async post({ request }) {
    try {
      let formData = Object.fromEntries(await request.formData());

      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + UserObject.getUser().token);

      const transData = new FormData();
      Object.keys(formData).map((v) => transData.append(v, formData[v]));
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: transData,
        redirect: "follow",
      };

      let response = await (
        await fetch("https://api.fedacash.com/api/kyc", requestOptions)
      ).json();

      return ResponseObject.from({ data: response });
      // return response;
    } catch (e) {
      return e?.response;
    }
  }

  async put({ request }) {
    try {
      let formData = Object.fromEntries(await request.formData());

      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + UserObject.getUser().token);

      const transData = new FormData();
      Object.keys(formData).map((v) => transData.append(v, formData[v]));

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: transData,
        redirect: "follow",
      };

      let response = await (
        await fetch("https://api.fedacash.com/api/kyc/resubmit", requestOptions)
      ).json();

      return ResponseObject.from({ data: response });
      // return response;
    } catch (e) {
      return e?.response;
    }
  }
}

export default KYCController;

export async function CooperativeKyc(data) {
  try {
    const { bvn, nin} = data;
    console.log(nin);
    let bvnRequest = await http.post("/kyc/verify/bvn", { number: bvn });

    let ninRequest = await http.post("/kyc/verify/nin", { number: nin });
    // console.log(nin);

    bvnRequest = ResponseObject.from(bvnRequest);
    ninRequest = ResponseObject.from(ninRequest);
    return {
      bvnRequest,
      ninRequest,
    };
  } catch (e) {
    return ResponseObject.from(e?.response);
  }
}

// Define the async function
export const uploadImage = async (imageFile) => {
  console.log(imageFile)
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + UserObject.getUser().token);
  // Create a FormData object
  const formData = new FormData();
  // Append the image file to the formData
  formData.append('customer_id', imageFile);
  //
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData,
    redirect: "follow",
    'Content-Type': 'image/png'
  };
  // Use axios.post to send the request
  try {
    // Specify the url, data, headers and transformRequest options
    // let Imageresponse = await axios.post('/kyc/upload/customer/id', formData, {
    //   headers: {
    //     'Content-Type': 'image/png'
    //   },
    // });
    let Imageresponse = await fetch(
      "https://api.fedacash.com/api/kyc/upload/customer/id",
      requestOptions
    );
    Imageresponse= ResponseObject.from(Imageresponse)
    // Return the response data
    return Imageresponse;
  } catch (error) {
    // Handle the error
    console.error(error);
    return ResponseObject.from(error);
  }
};

export async function verify_bvn(bvn) {
    try {
      if(!bvn || bvn.length === 0){
        return ''
      }
        let bvnRequest = await http.post("/kyc/verify/bvn", { number: bvn });
        bvnRequest = ResponseObject.from(bvnRequest);
        return bvnRequest;
    } catch (e) {
        return ResponseObject.from(e?.response);
    }
}
export async function verify_valid_id(id) {
  console.log(id)
  try {
    // if(!id || id.length === 0){
    //   return ''
    // }
      let validIdRequest = await http.post("/kyc/upload/customer/id",{id});
      validIdRequest = ResponseObject.from(validIdRequest);
      return validIdRequest;
  } catch (e) {
      return ResponseObject.from(e?.response);
  }
}
export async function verify_nin(nin) {
    try {
      if(!nin || nin.length === 0){
        return ''
      }
        let ninRequest = await http.post("/kyc/verify/nin", { number: nin });
        ninRequest = ResponseObject.from(ninRequest);
        return ninRequest;
    } catch (e) {
        return ResponseObject.from(e?.response);
    }
}
export async function verify_address(data) {
  console.log(data)
  try {
    if(!data){
      return ''
    }
      let addressRequest = await http.post("/kyc/verify/address", data);
      addressRequest = ResponseObject.from(addressRequest);
      return addressRequest;
  } catch (e) {
      return ResponseObject.from(e?.response);
  }
}
