import PageHeader from "../../../Components/Dashboard/PageHeader";
import Panel from "../../../Components/Dashboard/Panel";
import useTable from "../../../Components/Dashboard/Table/useTable";
import { BsChevronDown, BsThreeDots } from "react-icons/bs";
import PaidStatus from "../../../Components/Dashboard/PaidStatus";
import {
  Await,
  Link,
  useFetcher,
  useLoaderData,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import usePagination from "../../../Components/Dashboard/Table/usePagination";
import MoneyFormat from "../../../Components/Utils/MoneyFormat";
import { DatePicker, Dropdown, Popconfirm, Space, Typography } from "antd";
import { BiPaperPlane, BiSearch } from "react-icons/bi";
import { Suspense } from "react";
import InvoiceEmpty from "../../../Assets/images/InvoiceEmpty.png";
import { FiEyeOff } from "react-icons/fi";

const Transaction = () => {
  const { Table, RowHeader, ColumnHeader, Body, Column, Row } = useTable();
  const items = useLoaderData();
  const { Next, Prev, Button, Pagination, setPage, PageSize, Description } =
    usePagination();
  const { submit } = useFetcher();

  const [, setSearchParams] = useSearchParams({ dateFrom: "", dateTo: "" });
  const location = useLocation();
  const search = new URLSearchParams(location.search);

  const handleDateChange = (dateFrom, dateTo) => {
    const s = Object.fromEntries(search);
    setSearchParams({ ...s, dateFrom: dateFrom, dateTo: dateTo });
  };

  const sortBy = (key, value) => {
    const s = Object.fromEntries(search);
    setSearchParams({ ...s, sort_by: key, sort_type: value });
  };

  const sortByFunStatus = (value) => {
    const s = Object.fromEntries(search);
    setSearchParams({ ...s, fund_status: value });
  };
  const searchAction = (e) => {
    const s = Object.fromEntries(search);
    setSearchParams({ ...s, search: e.target.value });
  };

  const menus = [
    {
      key: 1,
      label: (
        <button
          className="flex items-center py-2 space-x-2"
          onClick={() => {
            sortByFunStatus(0);
          }}
        >
          <span>Fund Status</span>
          <PaidStatus value={0} />
        </button>
      ),
    },
    {
      key: 2,
      label: (
        <button
          className="flex items-center py-2 space-x-2"
          onClick={() => {
            sortByFunStatus(1);
          }}
        >
          <span>Fund Status</span>
          <PaidStatus value={1} />
        </button>
      ),
    },
    {
      key: 3,
      label: (
        <button
          className="flex items-center py-2 space-x-2"
          onClick={() => {
            sortByFunStatus(2);
          }}
        >
          <span>Fund Status</span>
          <PaidStatus value={2} />
        </button>
      ),
    },
    {
      key: 4,
      label: (
        <button
          className="flex items-center py-2 space-x-2"
          onClick={() => {
            sortByFunStatus(3);
          }}
        >
          <span>Fund Status</span>
          <PaidStatus value={3} />
        </button>
      ),
    },
  ];

  const filterMenu = [
    {
      key: 1,
      label: (
        <button
          className="py-2"
          onClick={() => {
            sortBy("created_at", "DESC");
          }}
        >
          Date Created
        </button>
      ),
    },
    {
      key: 2,
      label: (
        <button
          className="py-2"
          onClick={() => {
            sortBy("due_date", "DESC");
          }}
        >
          Due Date
        </button>
      ),
    },
  ];

  return (
    <div className="w-full">
      <PageHeader>Transaction history</PageHeader>
      <div className="md:max-w-lg my-4">
        <WalletCard />
      </div>
      <div className="flex flex-col items-center justify-between mt-5 mb-5 space-y-2 md:space-y-0 md:flex-row">
        <div className="items-center justify-start w-full lg:flex">
          <div className="flex items-center justify-between">
            <Dropdown
              menu={{
                items: menus,
                selectable: true,
                defaultSelectedKeys: ["3"],
              }}
              className="w-full"
            >
              <div className="border border-gray-300/50 py-2 px-4 bg-white rounded-[8px]">
                <Typography.Link>
                  <Space>
                    <div className="text-xs text-gray-500 whitespace-nowrap">
                      Paid Status
                    </div>
                    <BsChevronDown className="text-[#323A46]" />
                  </Space>
                </Typography.Link>
              </div>
            </Dropdown>
            <Dropdown
              menu={{
                items: filterMenu,
                selectable: true,
                defaultSelectedKeys: ["3"],
              }}
              className="w-full"
            >
              <div className="border border-gray-300/50 py-2 px-4 bg-white rounded-[8px]">
                <Typography.Link>
                  <Space>
                    <div className="text-xs text-gray-500">Filter</div>
                    <BsChevronDown className="text-[#323A46]" />
                  </Space>
                </Typography.Link>
              </div>
            </Dropdown>
          </div>
          <DatePicker.RangePicker
            className="py-2 w-full md:max-w-[291.83px] px-4 bg-white border-gray-300/50"
            onCalendarChange={(dates, dateString) => {
              const [dateFrom, dateTo] = dateString;
              handleDateChange(dateFrom, dateTo);
            }}
          />
        </div>
        <div
          className={`w-full md:max-w-[291.83px] h-[40px] bg-[#e7eaee] rounded-[8px] flex items-center justify-start`}
        >
          <BiSearch className="w-[15px] h-[15px] flex-none border-none ml-[15px] mr-[6.5px]" />
          <input
            type="text"
            placeholder="search"
            className="flex-1 focus:ring-transparent bg-transparent h-full w-full border-none focus:outline-none focus:border-transparent appearance-none placeholder:text-[#64748b] font-[500] leading-[21px] text-[14px]"
            onChange={searchAction}
          />
        </div>
      </div>
      <Panel>
        <Table>
          <RowHeader>
            <ColumnHeader>Company</ColumnHeader>
            <ColumnHeader>Date Created</ColumnHeader>
            <ColumnHeader>Due Date</ColumnHeader>
            <ColumnHeader>Fund Status</ColumnHeader>
            <ColumnHeader>KYC Status</ColumnHeader>
            <ColumnHeader>Amount</ColumnHeader>
            <ColumnHeader>Invoice Number</ColumnHeader>
            <ColumnHeader>Action</ColumnHeader>
          </RowHeader>
          <Body data={items}>
            {(data) =>
              data?.data?.data?.map((v, i) => (
                <Row key={i}>
                  <Column>{v.company.company_name}</Column>
                  <Column>{new Date(v.created_at).toDateString()}</Column>
                  <Column>{new Date(v.due_date).toDateString()}</Column>
                  <Column>
                    <FundStatus value={v.fund_status} />
                  </Column>
                  <Column>
                    <KYCStatus value={v.kyc_status} />
                  </Column>
                  <Column>
                    <div className="text-right">₦{MoneyFormat(v.amount)}</div>
                  </Column>
                  <Column>
                    <div className="text-center">{v.invoice_number}</div>
                  </Column>
                  <Column>
                    {parseInt(v.kyc_status) === 0 ? (
                      <Dropdown
                        trigger={["click"]}
                        menu={{
                          items: [
                            {
                              key: "1",
                              label: (
                                <Link
                                  className="text-sm text-blue-500"
                                  to={`/dashboard/transaction/${v.request_token}`}
                                >
                                  View Transaction
                                </Link>
                              ),
                            },
                            {
                              key: "2",
                              label: (
                                <Popconfirm
                                  title={`Delete Transaction`}
                                  onConfirm={() => {
                                    submit(
                                      { idle: true },
                                      {
                                        method: "delete",
                                        action:
                                          "/dashboard/transaction/" +
                                          v.request_token,
                                      }
                                    );
                                  }}
                                  okText={
                                    <span className="text-black">Yes</span>
                                  }
                                  className="text-black"
                                >
                                  Delete Transaction
                                </Popconfirm>
                              ),
                            },
                          ],
                        }}
                        placement="bottomRight"
                        arrow={{ pointAtCenter: true }}
                      >
                        <div className="flex items-center justify-center">
                          <BsThreeDots className="w-4 h-4" />
                        </div>
                      </Dropdown>
                    ) : (
                      <Dropdown
                        trigger={["click"]}
                        menu={{
                          items: [
                            {
                              key: "1",
                              label: (
                                <Link
                                  className="text-sm text-blue-500"
                                  to={`/dashboard/transaction/${v.request_token}`}
                                >
                                  View Transaction
                                </Link>
                              ),
                            },
                          ],
                        }}
                        placement="bottomRight"
                        arrow={{ pointAtCenter: true }}
                      >
                        <div className="flex items-center justify-center">
                          <BsThreeDots className="w-4 h-4" />
                        </div>
                      </Dropdown>
                    )}
                  </Column>
                </Row>
              ))
            }
          </Body>
        </Table>
        <TransactionEmptyState items={items} />
      </Panel>
      <div className="flex items-center justify-between mt-2">
        <Description data={items} />
        <PageSize numbers={[5, 10, 20, 50]} />
        <Pagination>
          <Suspense fallback={<p>Error Loading</p>}>
            <Await resolve={items.data} errorElement={<span>Loading</span>}>
              {(data) => {
                return (
                  <>
                    <Prev
                      onClick={() => {
                        setPage(1);
                      }}
                    />
                    {Array(data?.data?.last_page)
                      .fill(5)
                      .map((v, i) => {
                        return (
                          <Button
                            key={i}
                            isActive={data?.data?.current_page === i + 1}
                            onClick={() => {
                              setPage(i + 1);
                            }}
                          >
                            {i + 1}
                          </Button>
                        );
                      })}
                    <Next
                      onClick={() => {
                        setPage(data?.data?.last_page);
                      }}
                    />
                  </>
                );
              }}
            </Await>
          </Suspense>
        </Pagination>
      </div>
    </div>
  );
};

const TransactionEmptyState = ({ items }) => (
  <Suspense fallback={<div></div>}>
    <Await resolve={items?.data}>
      {(data) => {
        if (data?.data?.data.length <= 0) {
          return (
            <div className="bg-white rounded-[16px] min-h-[400px] md:min-h-[600px] text-center flex justify-center flex-col items-center">
              <img src={InvoiceEmpty} alt="Transactions" />
              <p className="mt-5 text-[#64748B]">
                You don’t have any transaction <br /> at the time
              </p>
              <Link to="/dashboard/transaction/create" className="mt-5">
                <span className="bg-black text-white px-6 py-3  rounded-[8px]">
                  + New Request
                </span>
              </Link>
            </div>
          );
        }
      }}
    </Await>
  </Suspense>
);

const KYCStatus = ({ value = 0 }) => {
  const type = {
    3: {
      color: "bg-blue-100 text-blue-600",
      value: "advanced",
    },
    1: {
      color: "bg-green-100 text-green-600",
      value: "Approved",
    },
    0: {
      color: "bg-yellow-100 text-yellow-600",
      value: "pending",
    },
    2: {
      color: "bg-red-100 text-red-600",
      value: "declined",
    },
    4: {
      color: "bg-green-500 text-green-900",
      value: "paid",
    },
  };

  return (
    <span
      className={`text-xs px-3 py-1 whitespace-nowrap rounded-full capitalize ${
        type[parseInt(value)].color
      }`}
    >
      {type[value].value}
    </span>
  );
};

const FundStatus = ({ value = 0 }) => {
  const type = {
    2: {
      color: "bg-blue-100 text-blue-600",
      value: "advanced",
    },
    0: {
      color: "bg-yellow-100 text-yellow-600",
      value: "pending",
    },

    1: {
      color: "bg-green-500 text-green-900",
      value: "paid",
    },
  };

  return (
    <span
      className={`text-xs px-3 py-1 whitespace-nowrap rounded-full capitalize ${
        type[parseInt(value)].color
      }`}
    >
      {type[value].value}
    </span>
  );
};

const WalletCard = () => {
  return (
    <div className="h-[150px] flex justify-between bg-gradient-to-r from-[#FAB2FF] to-[#6704E5] text-white rounded-[8px] pt-[20px] pb-[24px] px-[20px]">
      <div>
        <h2 className="font-bold leading-[24px]">Fedacash Cooperative</h2>
        <div className="font-semibold text-4xl leading-[32.5px] tracking-[-2%] flex items-center italic space-x-2 ">
          <span className="oldstyle-nums">₦</span>{" "}
          <FiEyeOff className="w-4 h-4" />
        </div>
      </div>
      {/* <button className="self-end flex items-center py-2 px-5 space-x-2 bg-[#120321] text-white rounded-lg font-bold text-[17px] leading-[21px]">
        <BiPaperPlane className="w-6 h-6 " />
        <span>Withdraw</span>
      </button> */}
    </div>
  );
};

export default Transaction;
