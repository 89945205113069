import useTitle from "../../../Hooks/useTitle";
import useTable from "../../../Components/Dashboard/Table/useTable";
import Panel from "../../../Components/Dashboard/Panel";
import PaidStatus from "../../../Components/Dashboard/PaidStatus";
import PageHeader from "../../../Components/Dashboard/PageHeader";
import {
  Await,
  Form,
  Link,
  Outlet,
  useFetcher,
  useLoaderData,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import MoneyFormat from "../../../Components/Utils/MoneyFormat";
import { DatePicker, Slider } from "antd";
import { Suspense, useContext, useEffect, useState, Fragment } from "react";
import Invoice from "../../../Assets/icons/invoice.png";
import UserObject from "../../../DataObjects/UserObject";
import Logo from "../../../Assets/logo/Logo.png";
import {
  MdCancel,
  MdCheck,
  MdOutlineAdd,
  MdOutlineDriveFolderUpload,
} from "react-icons/md";
import { BsX } from "react-icons/bs";
import Input from "../../../Components/Forms/Input";
import Submit from "../../../Components/Forms/Submit";
import ToastContext from "../../../Routes/ToastContext";
import useForm from "../../../Hooks/useForm";
import Categories from "./Categories";
import Countries from "./Countries";
import States from "./States";
import Banks from "./Banks";
import { Check, Property } from "../../../Components/ui/icon";
import { Transition, Tab, Dialog } from "@headlessui/react";
import {
  AiFillCheckCircle,
  AiFillCloseCircle,
  AiOutlineClose,
} from "react-icons/ai";
import {
  CooperativeKyc,
  VertifyBvn,
  VertifyNin,
  uploadImage,
  verify_address,
  verify_bvn,
  verify_nin,
  verify_valid_id,
} from "../../../Services/Dashboard/KYCController";
import { Spinner } from "flowbite-react";

// import axios from "axios";
// import ResponseObject from "../../../DataObjects/ResponseObject";

const DashboardIndex = () => {
  useTitle("Dashboard");
  const data = useLoaderData();

  const [, setSearchParams] = useSearchParams({ dateFrom: "", dateTo: "" });
  const location = useLocation();
  const search = new URLSearchParams(location.search);

  const [mandate, setMandate] = useState([]);
  const utilityBill = { file: mandate, setFile: setMandate };
  const [recentInvoice, setRecentInvoice] = useState([]);
  const cacForm = { file: recentInvoice, setFile: setRecentInvoice };
  const [bankStatement, setBankStatement] = useState([]);
  const directorValidId = { file: bankStatement, setFile: setBankStatement };
  const [cacC, setCacC] = useState([]);
  const cac = { file: cacC, setFile: setCacC };
  const [tab, setTab] = useState(false);
  const handleDateChange = (dateFrom, dateTo) => {
    const s = Object.fromEntries(search);
    setSearchParams({ ...s, dateFrom: dateFrom, dateTo: dateTo });
  };

  const isKycVertified = !UserObject.getUser()?.isKYCVerified();
  // if (process.env.REACT_APP_VERCEL_ENV !== 'production') {
  //   console.log('The app is running in development mode.');
  //   console.log(process.env.REACT_APP_VERCEL_ENV)
  // } else {
  //   console.log('The app is running in production mode.');
  //   console.log(process.env.REACT_APP_VERCEL_ENV)
  // }

  useEffect(() => {
    if (UserObject.getUser().organization?.kyc_verification_status !== "1") {
      UserObject.getUser().refresh();
    }
  }, []);

  return (
    <div className="w-full h-full min-h-screen">
      <div className="flex flex-col items-start justify-between mb-5 sm:flex-row">
        <div className="mb-10">
          <div className="flex md:flex-row flex-col md:gap-4">
            <PageHeader>Dashboard</PageHeader>
          </div>
          <span className="mt-3 font-[400] md:text-base text-sm text-[#64748B] leading-[24px]">
            {new Date().toDateString()}
          </span>
        </div>
        <div className="w-60">
          <DatePicker.RangePicker
            className="px-4 py-2 bg-white border-gray-300/50"
            onCalendarChange={(dates, dateString) => {
              const [dateFrom, dateTo] = dateString;
              handleDateChange(dateFrom, dateTo);
            }}
          />
        </div>
      </div>
      <Suspense fallback={<div>Loading</div>}>
        <Await resolve={data?.data}>
          {(dt) => {
            return (
              <>
                <Outlet />{" "}
                <Tab.Group>
                  <Tab.List
                    className={`w-full bg-white py-3 flex gap-3 md:text-lg text-sm font-bold justify-evenly`}
                  >
                    <Tab
                      className={`ui-selected:text-fedacash-header ui-not-selected:text-[#64748B]`}
                    >
                      Overview
                    </Tab>
                    <Tab
                      className={`ui-selected:text-fedacash-header ui-not-selected:text-[#64748B]`}
                    >
                      {isKycVertified
                        ? "Invoice Discount KYC "
                        : "Invoice Discount Transaction History"}
                    </Tab>
                  </Tab.List>
                  <Tab.Panels
                    className={`bg-white rounded-md py-10 xl:px-4 mt-4`}
                  >
                    <Tab.Panel>
                      <Overview />
                    </Tab.Panel>
                    <Tab.Panel>
                      {isKycVertified ? (
                        <EmptyState
                          cacForm={cacForm}
                          utilityBill={utilityBill}
                          directorValidId={directorValidId}
                          cac={cac}
                          tab={tab}
                        />
                      ) : (
                        <TransactionHistory data={data} />
                      )}
                    </Tab.Panel>
                  </Tab.Panels>
                </Tab.Group>
              </>
            );
          }}
        </Await>
      </Suspense>
    </div>
  );
};
const TransactionHistory = ({ data }) => {
  const { Table, RowHeader, ColumnHeader, Body, Column, Row } = useTable();

  return (
    <Panel className={`mt-4`}>
      <Table>
        <RowHeader>
          <ColumnHeader>Company</ColumnHeader>
          <ColumnHeader>Date Created</ColumnHeader>
          <ColumnHeader>Fund Status</ColumnHeader>
          <ColumnHeader>
            <p className="text-right">Amount</p>
          </ColumnHeader>
          <ColumnHeader>Action</ColumnHeader>
        </RowHeader>
        <Body data={data}>
          {(data) => {
            return data?.data?.data?.map((v, i) => {
              return (
                <Row key={i}>
                  <Column>{v.company.company_name}</Column>
                  <Column>{new Date(v.created_at).toDateString()}</Column>
                  <Column>
                    <PaidStatus value={v.status} />
                  </Column>
                  <Column>
                    <div className="text-right">₦{MoneyFormat(v.amount)}</div>
                  </Column>
                  <Column>
                    <Link
                      to={`/dashboard/transaction/` + v.request_token}
                      className="text-[#88C02C] cursor-pointer"
                    >
                      View More
                    </Link>
                  </Column>
                </Row>
              );
            });
          }}
        </Body>
      </Table>
    </Panel>
  );
};
const EmptyState = ({ utilityBill, cacForm, directorValidId, cac, tab }) => {
  const [state, setState] = useState(true);
  const { organization } = UserObject.getUser();
  const [reKyx, setReKyc] = useState(null);

  return (
    <div className="rounded-[16px] bg-white p-3 min-h-[50%] flex flex-col items-center justify-center w-full relative bottom-0 top-0 ">
      <div className="flex flex-col items-center justify-center w-full max-w-sm">
        {organization?.kyc_verification_status === "0" && (
          <img
            src={Invoice}
            alt="invoice"
            className="mb-[28.36px] aspect-auto "
          />
        )}
        {organization?.kyc_verification_status === "2" && (
          <svg
            width="78"
            height="78"
            viewBox="0 0 78 78"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M65.8125 29.25V56.0625H26.8125V7.3125H43.875V20.7188C43.875 25.428 47.697 29.25 52.4062 29.25H65.8125ZM51.1875 7.90238L65.2226 21.9375H52.4062C52.083 21.9375 51.773 21.8091 51.5445 21.5805C51.3159 21.352 51.1875 21.042 51.1875 20.7188V7.90238ZM24.375 0C23.0821 0 21.8421 0.513615 20.9279 1.42785C20.0136 2.34209 19.5 3.58207 19.5 4.875V14.625H10.9688C7.605 14.625 4.875 17.355 4.875 20.7188V71.9062C4.875 75.27 7.605 78 10.9688 78H51.1875C54.5513 78 57.2812 75.27 57.2812 71.9062V63.765C57.2816 63.6347 57.2751 63.5046 57.2618 63.375H68.25C69.5429 63.375 70.7829 62.8614 71.6971 61.9471C72.6114 61.0329 73.125 59.7929 73.125 58.5V21.5182C73.1247 20.2254 72.6109 18.9857 71.6966 18.0716L55.0534 1.42838C54.1394 0.514072 52.8996 0.000276107 51.6068 0H24.375ZM19.5 58.5V21.9375H12.1875V70.6875H49.9688V63.765C49.9688 63.6334 49.9736 63.5018 49.9882 63.375H24.375C23.0821 63.375 21.8421 62.8614 20.9279 61.9471C20.0136 61.0329 19.5 59.7929 19.5 58.5Z"
              fill="black"
            />
          </svg>
        )}
        {organization?.kyc_verification_status === "3" && (
          <svg
            width="78"
            height="78"
            viewBox="0 0 78 78"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M39 6.5C56.9725 6.5 71.5 21.0275 71.5 39C71.5 56.9725 56.9725 71.5 39 71.5C21.0275 71.5 6.5 56.9725 6.5 39C6.5 21.0275 21.0275 6.5 39 6.5ZM50.6675 22.75L39 34.4175L27.3325 22.75L22.75 27.3325L34.4175 39L22.75 50.6675L27.3325 55.25L39 43.5825L50.6675 55.25L55.25 50.6675L43.5825 39L55.25 27.3325L50.6675 22.75Z"
              fill="#FD0D1B"
            />
          </svg>
        )}

        <h3 className="text-[#590DA4] font-[700] text-[16px] leading-[24px] mt-[25px] ">
          Dear {UserObject.getUser()?.first_name}{" "}
          {UserObject.getUser()?.last_name}...
        </h3>
        {organization?.kyc_verification_status === "0" && (
          <p className="mb-[29.09px] text-center font-[700] text-[14px] leading-[21px]">
            You are a few steps away from logging your first request, please
            click here to complete your Corporate KYC registration.
          </p>
        )}
        {/* {organization?.kyc_verification_status === "2" ||
          (organization?.kyc_verification_status === "3" && (
            <p className="mb-[15.09px] text-center font-[700] text-[14px] leading-[21px]">
              Your corporate KYC Verification status is:
            </p>
          ))} */}
        {organization?.kyc_verification_status === "0" && (
          <button
            onClick={() => setState((v) => !v)}
            className="rounded-[8px] py-[9px] px-[16px] text-white bg-[#0D0F11]"
          >
            + Start Your KYC
          </button>
        )}
        {organization?.kyc_verification_status === "2" && (
          <>
            <p className="mb-[15.09px] text-center font-[700] text-[14px] leading-[21px]">
              Your corporate KYC Verification status is:
            </p>
            <span className="px-3 py-1 text-sm text-yellow-700 bg-yellow-200 rounded-full">
              Pending
            </span>
          </>
        )}
        {organization?.kyc_verification_status === "3" && (
          <>
            <p className="mb-[15.09px] text-center font-[700] text-[14px] leading-[21px]">
              Your corporate KYC Verification status is:
            </p>
            <span className="px-3 py-1 text-sm text-red-700 rounded-full">
              Rejected
            </span>
            <p className="mt-2 mb-6 text-sm">
              {organization.comments[0]?.comments ?? ""}
            </p>
            <button
              type="button"
              onClick={() => setReKyc(UserObject.getUser()?.organization)}
              className="rounded-[8px] py-[9px] mt-2 px-[16px] text-white bg-[#0D0F11]"
            >
              Re-Submit KYC
            </button>
          </>
        )}
        <KYC
          utilityBill={utilityBill}
          isHidden={state}
          handleState={setState}
          directorValidId={directorValidId}
          cacForm={cacForm}
          cac={cac}
        />
        <ReSubmitKYC v={reKyx} setV={setReKyc} />
      </div>
    </div>
  );
};

const Overview = () => {
  const navigate = useNavigate();

  //state to handle state modal
  const [isOpen, setIsOpen] = useState(false);
  function toggleModal() {
    setIsOpen((prev) => !prev);
  }
  const bvnVertification = UserObject.getUser()?.isBvnVertified();
  const ninVertification = UserObject.getUser()?.isNinVertified();
  const handleVerificationClick = (link) => {
    if (bvnVertification && ninVertification) {
      navigate(link);
    } else {
      toggleModal();
    }
  };

  return (
    <section className="w-full">
      <div className="flex flex-col items-center justify-center mb-4 mt-3">
        <h3 className="lg:text-xl text-lg font-bold">
          Welcome, {UserObject.getUser()?.first_name}
        </h3>
      </div>
      <div className="w-full flex flex-col gap-3 mt-6 md:border border-[#ECEFF3] rounded-xl py-8 px-6 text-[#36394A]">
        <div>
          <p className="font-bold lg:text-md text-sm my-2">
            Setup your FedaCash Account
          </p>
          <p className="font-medium md:text-sm text-xs">
            We have curated a list of items to get you up and running with your
            FedaCash account⚡️
          </p>
        </div>
        <div className="flex flex-col md:flex-row items-end justify-between p-4 gap-2 rounded-lg border border-[#ECEFF3]">
          <div className="flex gap-3 md:gap-6 ">
            <Check className={`shrink-0`} />
            <div className="lg:text-base md:text-sm text-sm">
              <p className="font-bold">Generate Invoice for Free</p>
              <p className="font-medium lg:text-sm md:text-xs text-[10px]">
                Explore Seamless Invoice Creation with Fedacash's Free Invoice
                Generation
              </p>
            </div>
          </div>

          <Link
            to={`invoice/create`}
            className="flex gap-2 lg:text-sm text-xs items-center py-2 px-3 w-fit rounded-md border font-bold border-[#ECEFF3]"
          >
            <MdOutlineAdd />
            Generate Invoice
          </Link>
        </div>
        <div className="flex flex-col md:flex-row items-end justify-between p-4 gap-2 rounded-lg border border-[#ECEFF3]">
          <div className="flex gap-3 md:gap-6 ">
            <Check className={`shrink-0`} />
            <div className="lg:text-base md:text-sm text-sm">
              <p className="font-bold">Get a Loan</p>
              <p className="font-medium lg:text-sm md:text-xs text-[10px]">
                Get a quick loan from our loan service
              </p>
            </div>
          </div>
          <button
            onClick={() => handleVerificationClick("loan/request")}
            className="flex gap-2 lg:text-sm text-xs items-center py-2 px-3 w-fit rounded-md border font-bold border-[#ECEFF3]"
          >
            <MdOutlineAdd />
            Loan Request
          </button>
        </div>
        <div className="flex flex-col md:flex-row justify-between items-end p-4 gap-x-2 gap-y-4 rounded-lg border border-[#ECEFF3]">
          <div className="flex gap-6">
            {" "}
            <Property className={`shrink-0`} />
            <div className="lg:text-base md:text-sm text-sm">
              <p className="font-bold">Join our Cooperative Society</p>
              <p className="font-medium lg:text-sm md:text-xs text-[10px]">
                Join Our Cooperative Society Today, pay your monthy Cooperative
                fee
              </p>
            </div>
          </div>
          <button
            onClick={() => handleVerificationClick("cooperative/saveandpack")}
            className="flex gap-2 lg:text-sm text-xs items-center py-2 px-3 w-fit rounded-md border font-bold border-[#ECEFF3]"
          >
            <MdOutlineAdd />
            Join
          </button>
        </div>
        <div className="flex flex-col md:flex-row items-end justify-between p-4 gap-x-2 gap-y-4 rounded-lg border border-[#ECEFF3]">
          <div className="flex gap-3 md:gap-6">
            {" "}
            <Slider className={`shrink-0`} />
            <div className="lg:text-base md:text-sm text-sm">
              <p className="font-bold">Create Your Invoice Discount Request</p>
              <p className="font-medium lg:text-sm md:text-xs text-[10px]">
                Kickstart your Invoice Discounting Requests
              </p>
            </div>
          </div>

          <Link
            to={`transaction/create`}
            className="flex gap-2 lg:text-sm text-xs items-center py-2 px-3 w-fit rounded-md border font-bold border-[#ECEFF3]"
          >
            <MdOutlineAdd />
            Create Request
          </Link>
        </div>
      </div>
      <CooperativeKYC isOpen={isOpen} toggleModal={toggleModal} />
    </section>
  );
};
export const KYC = ({
  isHidden,
  handleState,
  utilityBill,
  directorValidId,
  cacForm,
  cac,
}) => {
  const [state, setState] = useState("bus");
  const { flashToast } = useContext(ToastContext);
  const { email, phone_number, organization } = UserObject.getUser();
  const [apires, setApires] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { state: loading, data, submit } = useFetcher();

  const initialState = {
    business_name: organization?.name,
    registration_number: organization?.registration_number,
    category: organization?.category,
    email: email,
    address: organization?.address,
    phone_number: phone_number,
    country: organization?.country,
    state: organization?.state,
    city: organization?.city,
    bank_name: organization?.bank_name,
    account_number: organization?.account_number ?? 0,
    director_id: "",
    director_bvn: organization?.director_bvn,
  };
  const [form, setForm] = useForm(initialState);

  function ApiResponseHandler(data) {
    if (data) {
      if (data?.success) {
        flashToast(data?.success, data?.message);
        UserObject.getUser().refresh();
        handleState((v) => !v);
      }
      if (!data?.success) {
        flashToast(data?.success, data?.message);
      }
    }
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + UserObject.getUser().token);
    /////////////////create new form data
    const transData = new FormData();
    for (const key in form) {
      transData.append(key, form[key]);
    }
    transData.append("director_id", directorValidId.file[0]);
    transData.append("cac_form", cacForm.file[0]);
    transData.append("cac", cac.file[0]);
    transData.append("utility", utilityBill.file[0]);
    // for (const [key, value] of transData.entries()) {
    //   console.log(`${key}: ${value}`);
    // }
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: transData,
      redirect: "follow",
    };
    try {
      let response = await fetch(
        "https://api.fedacash.com/api/kyc",
        requestOptions
      );
      let data = await response.json();
      setApires(data);
      ApiResponseHandler(data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
    // console.log(response);
    // return ResponseObject.from({ data: response });
  };

  return (
    <div
      data-hidden={isHidden}
      className="fixed data-[hidden=true]:hidden z-[999] bg-purple-700/70 top-0 left-0 w-full h-screen overflow-y-auto flex items-start justify-center pt-20"
    >
      <div className="relative bg-white max-w-4xl rounded-[16px] p-10 m-10 w-full">
        <button
          type="button"
          onClick={() => {
            handleState((v) => !v);
          }}
          className="absolute p-1 text-gray-800 bg-white rounded-full -top-3 -left-3"
        >
          <BsX className="w-5 h-5" />
        </button>
        <img alt="logo" src={Logo} className="w-[140px] h-[46px]" />
        <p className="my-5">
          Welcome to FedaCash{" "}
          <span className="font-semibold">
            {UserObject.getUser().fullname()}
          </span>
          ! Join thousands of business owners with the chance to scale through
          in their business ventures while solving their cash trap problems with
          our agile solution.
        </p>
        <h2 className="my-5">Enter your KYC Details</h2>
        <div className="flex items-center justify-center w-full ">
          <div className="flex items-center justify-center max-w-sm px-6 py-6 space-x-3 ">
            <button
              type="button"
              onClick={() => {
                setState("bus");
              }}
              className="flex items-center space-x-3"
            >
              <div
                data-active={state === "invoice_details"}
                className="flex items-center rounded-full text-white data-[active=true]:bg-purple-700 justify-center w-10 h-10 bg-gray-500"
              >
                1
              </div>
              <p className="whitespace-nowrap">Business Details</p>
            </button>
            <hr className="w-full" />
            <button
              type="button"
              onClick={() => {
                setState("kyc");
              }}
              className="flex items-center space-x-3"
            >
              <div
                data-active={state === "kyc"}
                className="flex items-center data-[active=true]:bg-purple-700 rounded-full text-white justify-center w-10 h-10 bg-gray-500"
              >
                2
              </div>
              <p className="whitespace-nowrap">KYC Documents</p>
            </button>
          </div>
        </div>
        <form
          onSubmit={handleSubmit}
          method="post"
          encType="multipart/form-data"
        >
          <div className="w-full">
            <BusinessDetails
              data={data}
              setForm={setForm}
              isHidden={state === "bus"}
            />
            <KYCDocuments
              data={data}
              cac={cac}
              utilityBill={utilityBill}
              isHidden={state === "kyc"}
              directorValidId={directorValidId}
              cacForm={cacForm}
              setForm={setForm}
            />
          </div>
          <div className="flex items-center justify-end w-full space-x-3 col-span-full">
            <div
              data-hidden={!(state === "bus")}
              className="inline-flex space-x-3 data-[hidden=true]:hidden"
            >
              <button
                type="reset"
                onClick={() => {
                  handleState((v) => !v);
                }}
                className="px-10 py-5 text-sm font-bold text-purple-700 border border-purple-700 rounded-md "
              >
                Cancel
              </button>
              <Submit
                type="button"
                onClick={(e) => {
                  setState("kyc");
                }}
              >
                Continue
              </Submit>
            </div>
            <div
              data-hidden={!(state === "kyc")}
              className="inline-flex space-x-3 data-[hidden=true]:hidden"
            >
              <button
                type="button"
                onClick={() => {
                  setState("bus");
                }}
                className="px-10 py-5 text-sm font-bold tracking-wide text-purple-700 border border-purple-700 rounded-md "
              >
                Previous
              </button>
              {/* <Submit isLoading={loading !== "idle"}>Submit</Submit> */}
              <Submit isLoading={isLoading}>Submit</Submit>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

//TODO://move this component to a new file
//TODO://I know the function canbe better and be refactored but I was working with a hard time frame,Fidx this up later
export const CooperativeKYC = ({ isOpen, toggleModal }) => {
  const navigate = useNavigate();


  const {phone_number} = UserObject.getUser()

  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [bvnresponse, setBvnResponse] = useState(null);
  const [ninresponse, setNinResponse] = useState(null);
  const [addressresponse, setAddressResponse] = useState(null);
  const [addressError, setAddressError] = useState(null);
  const [validIdresponse, setValidIdResponse] = useState(null);
  const [formPosition, setFormPosition] = useState(1); //three states to determine the form position bvn|success
  const [states, setStates] = useState([]);

  const [validId, setValidId] = useState([]);
  const userValidId = { file: validId, setFile: setValidId };

  const [data, setData] = useState({
    bvn: "",
    nin: "",
    address: "",
    state: "",
    lga: "",

  });
  const [addressData, setAddressData] = useState({
    address: "",
    state: "",
    lga: "",
    landmark: "",    
    phone:phone_number ?? ""
  });
  // Validation function
  //TODO://add a more robust validation
  const validateAddressFields = (formData) => {
    const { state, lga, address, landmark } = formData;

    if (
      (state || lga || address || landmark) &&
      !(state && lga && address && landmark)
    ) {
      // At least one field is filled, but not all fields
      return "Please fill in all fields or leave them all empty.";
    }

    // All fields are either filled or empty
    return null; // No validation error
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setAddressData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const moveFormPosition = (delta) => {
    // Calculate the new position for the multistep form
    const newPosition = formPosition + delta;
    // Check if the new position is within the valid range// you can increase the number by addign more steps
    if (newPosition >= 1 && newPosition <= 3) {
      // Update the state
      setFormPosition(newPosition);
    }
    // Otherwise, do nothing (keep the current position)
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (bvnresponse?.success || ninresponse?.success) {
        // Log out the user and redirect them back to the login page
        UserObject.logOutUser();
        navigate("/auth/login");
      }
    }, 4000);
    return () => clearTimeout(timer);
  }, [bvnresponse?.success, ninresponse?.success]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const firstname = UserObject.getUser()?.firstname();
    const lastname = UserObject.getUser()?.lastname();
    try {
      const validationError = validateAddressFields(addressData);
      if (validationError) {
        setAddressError(validationError);
      } else {
        // Call your async function and await the response
        const BvnKycResponse = await verify_bvn(data.bvn);
        const NinKycResponse = await verify_nin(data.nin);
        const AddressKycResponse = await verify_address({
          first_name: firstname,
          last_name: lastname,
          ...addressData,
        });
        const validIdResponse = await uploadImage(validId);

        // Set the response in the state
        setNinResponse(NinKycResponse);
        setBvnResponse(BvnKycResponse);
        setValidIdResponse(validIdResponse);
        setAddressResponse(AddressKycResponse);
        setIsLoading(false);
        moveFormPosition(1);
        // Handle the response as needed
      }
      // console.log("Cooperative KYC Response:", bvnresponse, ninresponse)
    } catch (error) {
      // Handle any errors
      // console.error("Error:", error);
      setIsLoading(false);
    }
  };

  const bvnVertification = UserObject.getUser()?.isBvnVertified();
  const ninVertification = UserObject.getUser()?.isNinVertified();
  const customerIdStatus = UserObject.getUser()?.isCustomerIdStatus();
  const addressStatus = UserObject.getUser()?.isAdressVertified();

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={toggleModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-fedacash-header bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-2 md:p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full rounded-md max-w-xl transform overflow-hidden bg-white p-4 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="flex gap-3 justify-evenly text-lg font-bold leading-6 text-[#475467]"
                >
                  Cooperative KYC
                  <button
                    type="button"
                    className=""
                    onClick={() => {
                      toggleModal();
                      setFormPosition(1);
                    }}
                  >
                    <AiOutlineClose />
                  </button>
                </Dialog.Title>
                <div className="flex items-center justify-center">
                  <div className="flex flex-wrap items-center space-y-2 space-x-2 py-10 mx-auto">
                    <div className="flex items-center gap-x-2">
                      <div
                        className={`w-8 h-8 rounded-full${
                          formPosition === 1
                            ? " bg-fedacash-header text-white"
                            : " bg-gray-300 text-black"
                        } flex items-center justify-center`}
                      >
                        1
                      </div>
                      <span className="text-sm md:text-base">
                        Personal Details
                      </span>
                    </div>
                    <hr className="w-10 bg-fedacash-header" />
                    <div className="flex items-center gap-x-2 ">
                      <div
                        className={`w-8 h-8 rounded-full${
                          formPosition === 2
                            ? " bg-fedacash-header text-white"
                            : " bg-gray-300 text-black"
                        } flex items-center justify-center`}
                      >
                        2
                      </div>
                      <span className="text-sm md:text-base">Address</span>
                    </div>
                    <hr className="w-10 bg-fedacash-header md:block hidden" />
                    <div className="flex items-center gap-x-2">
                      <div
                        className={`w-8 h-8 rounded-full${
                          formPosition === 3
                            ? " bg-fedacash-header text-white"
                            : " bg-gray-300 text-black"
                        } flex items-center justify-center`}
                      >
                        3
                      </div>
                      <span className="text-sm md:text-base">Verification</span>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col px-4 md:px-12">
                  {formPosition === 1 ? (
                    <form
                      className="py-4 space-y-6"
                      onSubmit={(e) => {
                        moveFormPosition(1);
                        e.preventDefault();
                      }}
                    >
                      <div className="space-y-4">
                        {!bvnVertification && (
                          <div className="space-y-2">
                            <label className="font-bold">
                              <span>BVN</span>{" "}
                              <span className="text-red-700">*</span>
                            </label>
                            <input
                              placeholder="BVN"
                              name="bvn"
                              required
                              value={data.bvn}
                              onChange={(e) => handleChange(e)}
                              className="font-bold w-full text-[12px] leading-[18px] py-[11px] px-[19px] placeholder:text-[#b8c0cc] border-[1.5px] rounded-[8px] border-[#b8c0cc] focus:outline-none"
                            />
                          </div>
                        )}
                        {!ninVertification && (
                          <div className="space-y-2">
                            <label className="font-bold">NIN</label>
                            <input
                              placeholder="NIN"
                              name="nin"
                              value={data.nin}
                              onChange={(e) => handleChange(e)}
                              className="font-bold w-full text-[12px] leading-[18px] py-[11px] px-[19px] placeholder:text-[#b8c0cc] border-[1.5px] rounded-[8px] border-[#b8c0cc] focus:outline-none"
                            />
                          </div>
                        )}
                        {!customerIdStatus && (
                          <FileInput
                            label="Valid Id"
                            name="valid_id"
                            def={userValidId}
                          />
                        )}
                      </div>
                      <div className="mt-4">
                        <button
                          type="submit"
                          aria-label="next page"
                          title="next page"
                          className="text-white bg-fedacash-header text-center w-full py-2 px-2 rounded-md"
                        >
                          Next
                        </button>
                      </div>
                    </form>
                  ) : formPosition === 2 ? (
                    <form className="py-4 space-y-6" onSubmit={handleSubmit}>
                      <div className="space-y-4">
                        {!addressStatus && (
                          <div className="space-y-2">
                            <div className="space-y-2">
                              <States
                                states={states}
                                onChange={handleAddressChange}
                                label="State"
                                name="state"
                                placeholder="Search States"
                              />
                            </div>
                            <div className="space-y-2">
                              <label className="font-bold">LGA</label>
                              <input
                                placeholder="Local Government Area"
                                name="lga"
                                value={addressData.lga}
                                onChange={handleAddressChange}
                                className="font-bold w-full text-[12px] leading-[18px] py-[11px] px-[19px] placeholder:text-[#b8c0cc] border-[1.5px] rounded-[8px] border-[#b8c0cc] focus:outline-none"
                              />
                            </div>
                            <div className="space-y-2">
                              <label className="font-bold">Landmark</label>
                              <input
                                placeholder="Landmark"
                                name="landmark"
                                value={addressData.landmark}
                                onChange={handleAddressChange}
                                className="font-bold w-full text-[12px] leading-[18px] py-[11px] px-[19px] placeholder:text-[#b8c0cc] border-[1.5px] rounded-[8px] border-[#b8c0cc] focus:outline-none"
                              />
                            </div>
                            <div className="space-y-2">
                              <label className="font-bold">Address</label>
                              <input
                                placeholder="Address"
                                name="address"
                                value={addressData.address}
                                onChange={handleAddressChange}
                                className="font-bold w-full text-[12px] leading-[18px] py-[11px] px-[19px] placeholder:text-[#b8c0cc] border-[1.5px] rounded-[8px] border-[#b8c0cc] focus:outline-none"
                              />
                            </div>
                            {!phone_number && (
                              <div className="space-y-2">
                                <label className="font-bold">Phone Number</label>
                                <input
                                placeholder="Phone Number"
                                name="phone"
                                value={addressData.phone}
                                required
                                onChange={handleAddressChange}
                                className="font-bold w-full text-[12px] leading-[18px] py-[11px] px-[19px] placeholder:text-[#b8c0cc] border-[1.5px] rounded-[8px] border-[#b8c0cc] focus:outline-none"
                                />
                              </div>
                            )}
                            {addressError && (
                              <p className="text-red-700 font-semibold">
                                {addressError}
                              </p>
                            )}
                          </div>
                        )}
                      </div>
                      <div className="mt-4">
                        <button
                          type="submit"
                          className="text-white bg-fedacash-header text-center w-full py-2 px-2 rounded-md"
                        >
                          {!isLoading && "Submit"}
                          {isLoading && (
                            <div className="flex items-center justify-center">
                              <Spinner
                                color="info"
                                aria-label="It is loading"
                                title="Loading"
                              />
                              <span className="ml-2">Submitting...</span>
                            </div>
                          )}
                        </button>
                      </div>
                    </form>
                  ) : formPosition === 3 ? (
                    <div>
                      <div className="flex flex-col space-y-4 text-center">
                        {bvnresponse?.success || ninresponse?.success ? (
                          <p>You are required to login again</p>
                        ) : (
                          ""
                        )}
                        <div className="flex flex-col space-y-3">
                          {/* BVN Verification Response */}
                          {bvnresponse?.success ? (
                            <div className="flex items-center text-left gap-x-2">            
                              <AiFillCheckCircle
                                fill="#590DA4"
                                color="#fff"
                                size={30}
                                className="shrink-0"
                              />
                              <div className="text-fedacash-header">
                                <p className="text-lg font-semibold">
                                  BVN Verification Successful
                                </p>
                                <p>{bvnresponse?.message}</p>
                              </div>
                            </div>
                          ) : (
                            <div className="flex items-center text-left gap-x-2">
                              <MdCancel
                                fill="#c81e1e"
                                color="#fff"
                                size={30}
                                className="shrink-0"
                              />
                              <div className="text-red-700">
                                <p className="text-lg font-semibold">
                                  BVN Verification Failed
                                </p>
                                <p>{bvnresponse?.message}</p>
                              </div>
                            </div>
                          )}
                          {validIdresponse?.success ? (
                            <div className="flex items-center text-left gap-x-2">
                              <AiFillCheckCircle
                                fill="#590DA4"
                                color="#fff"
                                size={30}
                                className="shrink-0"
                              />
                              <div className="p-4 rounded-lg text-fedacash-header">
                                <p className="text-lg font-semibold">
                                  Id Verification Successful
                                </p>
                                <p>{validIdresponse?.message}</p>
                              </div>
                            </div>
                          ) : (
                            <div className="flex items-center text-left gap-x-2">
                              <MdCancel
                                fill="#c81e1e"
                                color="#fff"
                                size={30}
                                className="shrink-0"
                              />
                              <div className="text-red-700">
                                <p className="text-lg font-semibold">
                                  Id Verification Failed
                                </p>
                                <p>{validIdresponse?.message}</p>
                              </div>
                            </div>
                          )}
                          {/* NIN Verification Response */}
                          {ninresponse &&
                            (ninresponse?.success ? (
                              <div className="flex items-center text-left gap-x-2">
                                <AiFillCheckCircle
                                  fill="#590DA4"
                                  color="#fff"
                                  size={30}
                                  className="shrink-0"
                                />
                                <div className="p-4 rounded-lg text-fedacash-header">
                                  <p className="text-lg font-semibold">
                                    NIN Verification Successful
                                  </p>
                                  <p>{ninresponse?.message}</p>
                                </div>
                              </div>
                            ) : (
                              <div className="flex items-center text-left gap-x-2">
                                <MdCancel
                                  fill="#c81e1e"
                                  color="#fff"
                                  size={30}
                                  className="shrink-0"
                                />
                                <div className="text-red-700">
                                  <p className="text-lg font-semibold">
                                    NIN Verification Failed
                                  </p>
                                  <p>{ninresponse?.message}</p>
                                </div>
                              </div>
                            ))}
                          {addressresponse &&
                            (addressresponse?.success ? (
                              <div className="flex items-center text-left gap-x-2">
                                <AiFillCheckCircle
                                  fill="#590DA4"
                                  color="#fff"
                                  size={30}
                                  className="shrink-0"
                                />
                                <div className="p-4 rounded-lg text-fedacash-header">
                                  <p className="text-lg font-semibold">
                                    Address Verification Pending
                                    {/* {console.log(addressresponse)} */}
                                  </p>
                                  <p>{addressresponse?.message}</p>
                                </div>
                              </div>
                            ) : (
                              <div className="flex items-center text-left gap-x-2">
                                <MdCancel
                                  fill="#c81e1e"
                                  color="#fff"
                                  size={30}
                                  className="shrink-0"
                                />
                                <div className="text-red-700">
                                  <p className="text-lg font-semibold">
                                    Address Verification Failed
                                  </p>
                                  <p>{addressresponse?.message}</p>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                      <div className="mt-4 flex gap-4">
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md border border-transparent bg-[#590DA4] px-4 py-2 text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-[#590DA4] focus-visible:ring-offset-2"
                          onClick={()=>{
                            toggleModal()
                            setFormPosition(1)
                          }}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  ) : (
                    <p></p>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

const BusinessDetails = ({ data, setForm, isHidden }) => {
  const { email, phone_number, organization } = UserObject.getUser();
  const [states, setStates] = useState([]);

  return (
    <div
      className="grid grid-cols-1 sm:grid-cols-2 w-full gap-5 md:gap-10 data-[hidden=true]:hidden"
      data-hidden={!isHidden}
    >
      <Input
        onChange={setForm}
        label="Business Name"
        name="business_name"
        normalize={true}
        placeholder="Business Name"
        defaultValue={organization?.name}
        error={data?.errors?.business_name}
      />
      <Input
        onChange={setForm}
        label="Registration Number"
        name="registration_number"
        normalize={true}
        placeholder="Registration Number"
        defaultValue={organization?.registration_number}
        error={data?.errors?.registration_number}
      />
      <Categories
        onChange={setForm}
        label="Business Category"
        name="category"
        placeholder={organization?.category}
        error={data?.errors?.category}
      />
      <Input
        onChange={setForm}
        label="Email Address"
        name="email"
        type="email"
        defaultValue={email}
        normalize={true}
        placeholder="email"
        error={data?.errors?.email}
      />
      <div className="col-span-full">
        <Input
          onChange={setForm}
          label="Address"
          name="address"
          normalize={true}
          placeholder="Address"
          defaultValue={organization?.address}
          error={data?.error?.address}
        />
      </div>
      <Input
        onChange={setForm}
        label="Phone Number"
        name="phone_number"
        defaultValue={phone_number}
        normalize={true}
        placeholder="Phone number"
        error={data?.errors?.phone_number}
      />

      <Countries
        setStates={setStates}
        onChange={setForm}
        label="Country"
        name="country"
        placeholder={organization?.country}
        error={data?.errors?.country}
      />
      <States
        states={states}
        onChange={setForm}
        label="State"
        name="state"
        placeholder="Search States"
        error={data?.errors?.state}
      />
      <Input
        onChange={setForm}
        label="City"
        name="city"
        normalize={true}
        placeholder="City"
        defaultValue={organization?.city}
        error={data?.error?.city}
      />
      <Input
        onChange={setForm}
        label="Account Number"
        name="account_number"
        type="number"
        normalize={true}
        maxLength={10}
        defaultValue={organization?.account_number}
        placeholder="Enter account number"
        // options={{ number: true }}
        error={data?.errors?.account_number}
      />
      <Banks
        top={true}
        onChange={setForm}
        label="Bank Name"
        placeholder={organization?.bank_name}
        name="bank_name"
        error={data?.errors?.bank_name}
      />
    </div>
  );
};

const KYCDocuments = ({
  utilityBill,
  directorValidId,
  cacForm,
  cac,
  setForm,
  data,
  isHidden,
}) => {
  return (
    <div
      className="grid grid-cols-1 sm:grid-cols-2 w-full gap-5 md:gap-10 data-[hidden=true]:hidden"
      data-hidden={!isHidden}
    >
      <FileInput
        label="Director's Valid Id"
        name="director_id"
        def={directorValidId}
      />
      <FileInput
        label="Valid 3 Months Utility Bill"
        def={utilityBill}
        name="utility"
      />
      <FileInput label="CAC Certificate" name="cac" def={cac} />
      <Input
        onChange={setForm}
        label="BANK VERIFICATION NUMBER"
        name="director_bvn"
        error={data?.errors?.director_bvn}
        normalize={true}
        placeholder="Bank Verification Number"
        defaultValue={UserObject.getUser()?.organization?.director_bvn}
      />
      <FileInput
        label="Certified True Copy of the Form CAC/BN1"
        def={cacForm}
        name="cac_form"
      />
    </div>
  );
};

const ReSubmitKYC = ({ v, setV }) => {
  const [mandate, setMandate] = useState([]);
  const utilityBill = { file: mandate, setFile: setMandate };
  const [recentInvoice, setRecentInvoice] = useState([]);
  const cacForm = { file: recentInvoice, setFile: setRecentInvoice };
  const [bankStatement, setBankStatement] = useState([]);
  const directorValidId = { file: bankStatement, setFile: setBankStatement };
  const [cacC, setCacC] = useState([]);
  const cac = { file: cacC, setFile: setCacC };
  const { data, submit, state } = useFetcher();
  const { flashToast } = useContext(ToastContext);
  //check if response is loading and add the response to the state object
  const [apires, setApires] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  //handle the submit button
  function ApiResponseHandler(apires) {
    if (apires) {
      if (apires?.success) {
        flashToast(apires?.success, apires?.message);
        UserObject.getUser().refresh();
        setV(false);
      }
      if (!data?.success) {
        flashToast(apires?.success, apires?.message);
      }
    }
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + UserObject.getUser().token);
    const transData = new FormData();
    v.director_id_status === "2" &&
      transData.append("director_id", directorValidId?.file[0]);
    v.cac_form_status === "2" && transData.append("cac_form", cacForm?.file[0]);
    v.cac_status === "2" && transData.append("cac", cac.file[0]);
    v.utility_status === "2" &&
      transData.append("utility", utilityBill?.file[0]);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: transData,
      redirect: "follow",
    };
    try {
      let response = await fetch(
        "https://api.fedacash.com/api/kyc/resubmit",
        requestOptions
      );
      let data = await response.json();
      setApires(data);
      ApiResponseHandler(data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    v && (
      <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full min-w-full min-h-screen bg-purple-500/50">
        <div className="relative w-full max-w-sm p-6 bg-white rounded-md shadow-lg">
          <button
            className="absolute bg-white rounded-full -top-5 -left-5"
            onClick={() => {
              setV(false);
            }}
          >
            <BsX className="w-6 h-6 text-gray-500" />
          </button>
          <h3 className="text-[#101828] font-[500] text-[18px] leading-[28px]">
            Re-Upload Rejected KYC Document
          </h3>
          <form
            className="flex flex-col items-start justify-start w-full mt-5 space-y-5"
            // method={formMethod("PUT")}
            // encType="multipart/form-data"
            onSubmit={handleSubmit}
          >
            {v.director_id_status === "2" && (
              <FileInput
                label="Director's Valid Id"
                placeholder="Upload Your Director's Id"
                name="director_id"
                def={directorValidId}
              />
            )}
            {v.utility_status === "2" && (
              <FileInput
                label="Valid 3 Months Utility Bill"
                placeholder="Upload Your Utility Bill"
                def={utilityBill}
                name="utility"
              />
            )}
            {v.cac_status === "2" && (
              <FileInput
                label="CAC Certificate"
                name="cac"
                def={cac}
                placeholder="Upload Your CAC Certificate"
              />
            )}
            {v.cac_form_status === "2" && (
              <FileInput
                label="Certified True Copy of the Form CAC/BN1"
                def={cacForm}
                name="cac_form"
                placeholder="Upload Your CAC/BN1 Form"
              />
            )}
            {/* <Submit isLoading={loading !== "idle"}>Submit</Submit> */}
            <Submit isLoading={isLoading}>Submit</Submit>{" "}
          </form>
        </div>
      </div>
    )
  );
};

const FileInput = ({
  label,
  placeholder,
  note,
  def,
  multiple = false,
  ...props
}) => {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    def.setFile(files);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  return (
    <div className="inline-flex flex-col items-start justify-start w-full">
      <span className=" capitalize text-gray-700 text-base w-full text-[18px] font-[700]">
        {label}
      </span>
      <label className="w-full px-4 py-4 mt-2 text-base font-semibold tracking-wide bg-transparent border form-input focus:outline-none text-gray-800s focus:border-purple-800 placeholder:capitalize">
        <input
          type="file"
          className="hidden"
          onChange={(e) => {
            setFiles((v) =>
              multiple ? [...v, ...e.target.files] : [...e.target.files]
            );
          }}
          {...props}
        />
        <div className="flex items-center justify-between">
          <p className="text-gray-400">{placeholder}</p>
          <MdOutlineDriveFolderUpload className="w-6 h-6" />
        </div>
      </label>
      <p className="mt-[7.8px] font-[700] text-[14px] leading-[21px] text-[#A0ABBB]">
        {note}
      </p>
      <div className="flex flex-col items-start justify-start w-full mt-2 space-y-2">
        {files.map((v, i) => (
          <div
            key={i}
            className="flex flex-col items-start justify-start w-full"
          >
            <div className="flex items-center justify-between w-full">
              <p>{v.name}</p>
              <button className="focus:outline-none">
                <BsX className="w-4 h-4" />
              </button>
            </div>
            <div className="w-full h-2 bg-green-600 rounded-full"></div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default DashboardIndex;
