import invoiceOne from "../../../Assets/images/invoice-1.png";
import invoiceTwo from "../../../Assets/images/invoice-2.png";
import invoiceThree from "../../../Assets/images/invoice-3.png";
import invoiceFour from "../../../Assets/images/invoice-4.png";
import invoiceFive from "../../../Assets/images/invoice-5.png";

export const invoice = [
  {
    name: "Sign Up & onboard on FedaCash",
    description:
      " Seamless Sign-Up Process - Create an Account, Enter Your Details, and Submit with Ease.Follow us on all our social media handles.",
    image: invoiceOne,
  },
  {
    name: "Close your Sale and invoice your Client",
    description:
      "Generate an invoice detailing the amount due, payment terms, and any applicable discounts.",
    image: invoiceTwo,
  },
  {
    name: "Apply for Financing on FedaCash",
    description:
      " Apply for invoice discount through FedaCash by submitting your unpaid invoices and supporting documents. us on all our social media handles.",
    image: invoiceThree,
  },
  {
    name: "You receive Cash from FedaCash within 48 hours",
    description:
      "We review and verify your invoice. Once approved, you receive a cash advance of up to 80-90% of the total invoice value within 48 hours.",
    image: invoiceFour,
  },
  {
    name: "Your client pays us directly",
    description:
      "Your client remits the invoice payment directly to us as per the agreed-upon payment terms.",
    image: invoiceFive,
  },
];
