import {defer} from "react-router-dom";

class BaseController {
    static apply() {
        const controller = new this();

        return {
            loader: (v) => defer({data: controller?.index(v)}),
            action: (v) => {
                const {request} = v
                if (request.method === 'POST') {
                    return controller.post(v)
                }
                if (request.method === 'PUT') {
                    return controller.put(v)
                }
                if (request.method === 'PATCH') {
                    return controller.patch(v)
                }
                if (request.method === 'DELETE') {
                    return controller.destroy(v)
                }
                throw new Error('Invalid Request Method')
            }
        }
    }
}

export default BaseController;