///i used threee diffrent wallets across all components because I couldnt find a fast wy to impleement compositoon

import { useState } from "react";
import { useLoaderData } from "react-router-dom";
import WithdrawlForm from "../cooperative/withdrawalForm";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { formatNaira } from "../../Utils/FormatMoney";
import UserObject from "../../../DataObjects/UserObject";

//TODo://Make all wallet cards one
export const LoanWalletCard = ({module_name}) => {
  const [isVisible, setIsVisible] = useState(true);
  const data = useLoaderData();
  const handleClick = () => {
    setIsVisible(!isVisible);
  };
  const { paystack_customer_account } = UserObject.getUser();

  return (
    <div>
      <div className="h-[230px] lg:w-[60%] xl:w-[45%] flex flex-col gap-y-6 justify-between bg-gradient-to-r from-[#FAB2FF] to-[#6704E5] text-white rounded-[8px] pt-[20px] pb-[24px] px-[20px]">
        <div>
          <h2 className="font-bold leading-[24px]">{module_name}</h2>
          <div>
            <div className="font-semibold text-4xl leading-[32.5px] tracking-[-2%] flex items-center italic space-x-2 ">
              {isVisible ? formatNaira(0) : "*****"}
              <div onClick={handleClick}>
                <AiOutlineEyeInvisible size={32} />
              </div>{" "}
            </div>
            <div className="flex items-center gap-x-4 text-sm lg:text-base">
              <p>Book balance:</p>
              <div className="font-semibold leading-[32.5px]">
                {isVisible ? formatNaira(0) : "*****"}
              </div>
            </div>
            <div>
              <div className="flex items-center gap-x-4 text-sm lg:text-base">
                <p>Account Number:</p>
                <div className="font-semibold">
                  {paystack_customer_account
                    ? paystack_customer_account
                    : "*****"}
                </div>
              </div>
              <p>Bank Name</p>
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center">
          {/* <FundWallet/> */}
          <WithdrawlForm />
        </div>
      </div>
    </div>
  );
};
