import Cleave from "cleave.js/dist/cleave-react";

const Input = ({ label, icon, error, normalize, ...props }) => {
  return (
    <div className="flex flex-col justify-start items-start relative mb-5 max-h-fit">
      <label className="mb-1 capitalize text-gray-700 w-full text-lg font-bold">
        {label}
      </label>
      <div className="flex items-center mt-1 justify-start w-full border-none">
        {normalize ? (
          <input
            className="px-4 form-input focus:outline-none text-gray-800 tracking-wide border bg-transparent focus:border-purple-800 py-4 text-base placeholder:text-gray-400 font-semibold w-full"
            {...props}
          />
        ) : (
          <Cleave
            className="px-4 form-input focus:outline-none text-gray-800 sstracking-wide border bg-transparent focus:border-purple-800 py-4 text-base placeholder:text-gray-400 font-semibold w-full"
            {...props}
          />
        )}
        {icon && (
          <div className="absolute right-0 mx-2 pl-2 pr-1">{icon}</div>
        )}
      </div>
      <div
        className={`text-red-500 text-sm font-semibold tracking-wide ${
          error ? "" : "text-transparent"
        }`}
      >
        {Array.isArray(error) && error.length > 1 && (
          <ul className="list-disc ml-5">
            {error.map((e, k) => (
              <li key={k}>{e}</li>
            ))}
          </ul>
        )}
        {Array.isArray(error) && error.length === 1 && error}
      </div>
    </div>
  );
};

export default Input;
