import UserObject from "../../../DataObjects/UserObject";
import { AiOutlinePlus } from "react-icons/ai";
import { EmptyState } from "../EmptyState";
import emptyState from "../../../Assets/images/empty-state.png";
import Panel from "../Panel";
import  {LoanForm} from "./LoanForm";

export const LoanOverview = () => {
  return (
    <Panel>
      <EmptyState
        imagesrc={emptyState}
        text={`Dear ${
          UserObject.getUser()?.first_name
        } Start your Loan Request today.Don't wait any longer - take the first step towards achieving your goals`}
      >
        <LoanForm />
      </EmptyState>
    </Panel>
  );
};
