import React, { useState, useRef, useEffect } from "react";
import Marquee from "react-fast-marquee";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import { motion, useInView } from "framer-motion";
////file
import maskBg from "../../../Assets/images/mask-group.png";

import { MountainIcon, TargetIcon } from "../../../Components/ui/icon";
import Products from "../../../Components/LandingPage/products/index.products";
import Solutions from "../../../Components/LandingPage/solutions/index.solutions";
import TargetMarket from "../../../Components/LandingPage/TargetMarket";
import Faq from "../../../Components/LandingPage/faq/index.faq";
import CTA from "../../../Components/LandingPage/cta";
import Hero from "../../../Components/LandingPage/hero/index.hero";
import { FADE_UP_ANIMATION_VARIANTS } from "../../../utils/framer-variants";

const Homepage = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  return (
    <section>
      <Hero />
      <section className="bg-[#F1F8E7] py-4 md:block hidden ">
        <Marquee speed={140} className="">
          <h2 className="text-6xl text-fedacash-header drop-shadow">
            Create, send and manage invoices fast on FedaCash.
          </h2>
        </Marquee>
      </section>
      <section className="xl:px-2 px-6 container mx-auto lg:py-7 py-8">
        <motion.section
          className="flex lg:flex-row flex-col-reverse xl:gap-3 items-center justify-evenly"
          initial="hidden"
          animate={isInView ? "show" : "hide"}
          viewport={{ once: true }}
          variants={{
            hidden: {},
            show: {
              transition: {
                staggerChildren: 0.15,
              },
            },
          }}
        >
          <motion.img
            src={maskBg}
            variants={FADE_UP_ANIMATION_VARIANTS}
            alt="Masked Background with our Logo"
            className="lg:w-[40%] w-[252px] h-full mx-auto mt-5"
          />
          {/* <SliderComponent /> */}

          <div className="lg:w-[60%]">
            <h2 className="text-fedacash-header md:text-[2.5rem] text-xl font-bold md:text-left text-center">
              Who Are We?
            </h2>
            <div className="flex flex-col gap-7 mt-7 text-fedacash-textColor text-xs md:text-lg font-medium md:text-left text-center">
              <motion.p variants={FADE_UP_ANIMATION_VARIANTS}>
                FedaCash is a trusted financial partner committed to empowering
                the growth of small and micro enterprises across Nigeria.
              </motion.p>
              <motion.p variants={FADE_UP_ANIMATION_VARIANTS} ref={ref}>
                Recognizing the hurdles SMEs encounter when seeking conventional
                funding, we've designed a micro-working capital finance
                solution. Our goal is to provide fast and cost-effective
                funding, enabling businesses to manage inventory efficiently and
                overcome barriers to lasting success.
              </motion.p>
            </div>

            <div className="flex xl:max-w-3xl gap-3 mt-10">
              <div className="flex gap-3 items-start justify-start w-1/2">
                {" "}
                <MountainIcon width={100} height={120} />
                <div className="flex flex-col gap-y-3">
                  {" "}
                  <motion.h4
                    variants={FADE_UP_ANIMATION_VARIANTS} className="font-bold md:text-lg text-sm">Our Mission</motion.h4>
                  <motion.p
                    variants={FADE_UP_ANIMATION_VARIANTS}
                    className="md:text-base text-[8px]"
                  >
                    FedaCash exists to ensure the survival of NMSMEs in Africa,
                    by removing the cash constraints for them by leveraging
                    technology tools.
                  </motion.p>
                </div>
              </div>
              <div className="flex gap-3 items-start justify-start w-1/2">
                {" "}
                <TargetIcon width={100} height={120} />
                <div className="flex flex-col gap-y-3">
                  {" "}
                  <motion.h4
                    variants={FADE_UP_ANIMATION_VARIANTS}
                    className="font-bold md:text-lg text-sm"
                  >
                    Our Vision
                  </motion.h4>
                  <motion.p
                    variants={FADE_UP_ANIMATION_VARIANTS}
                    className="md:text-base text-[8px]"
                  >
                    To be among the top three NMSME-focused invoice financing
                    organization in Africa by 2026.
                  </motion.p>
                </div>
              </div>
            </div>
          </div>
        </motion.section>
      </section>
      {/* products section */}
      <div className="products-bg">
        <section className="xl:px-16 px-4 container mx-auto lg:py-7 py-8 my-8">
          <div className="flex items-center justify-center mb-6 mt-4">
            <h3 className="md:text-base text-xs text-white font-semibold shadow-header p-4 rounded-[30px] bg-customGray">
              OUR PRODUCTS
            </h3>
          </div>
          <Products />
        </section>
      </div>

      <section className="xl:px-2 px-4 container mx-auto lg:py-7 py-8">
        <div className="flex items-center justify-center mb-6">
          <h3 className="md:text-base text-xs text-fedacash-header font-semibold shadow-header p-4 rounded-[30px] bg-customGray">
            OUR SOLUTION
          </h3>
        </div>

        <div className="flex flex-col gap-3 items-center md:items-start md:justify-left my-10 ">
          <h3 className="text-fedacash-header lg:text-4xl md:text-2xl text-xl font-bold">
            Invoice Discounting
          </h3>
          <p className="lg:text-4xl md:text-2xl text-sm  font-medium">
            built for you in 5 easy steps
          </p>
        </div>
        <Solutions />
      </section>
      <section
        className="xl:px-20  px-4 container mx-auto lg:py-7 py-8"
        id="target-market"
      >
        <div className="flex items-center justify-center mb-5">
          <h3 className="md:text-base text-xs text-fedacash-header font-semibold shadow-header p-4 rounded-[30px] bg-customGray">
            OUR TARGET MARKET
          </h3>
        </div>
        <TargetMarket />
      </section>
      <section className="xl:px-2 px-4 container mx-auto lg:py-7 py-8">
        <div className="flex items-center justify-center mb-5">
          <h3 className="md:text-base text-xs text-fedacash-header font-semibold shadow-header p-4 rounded-[30px] bg-customGray">
            FAQ
          </h3>
        </div>
        <Faq />
      </section>
      <section className="lg:py-12">
        <CTA />
        <section className="xl:px-2 px-4 container mx-auto lg:px-10">
          <section className="flex flex-col justify-center items-center">
            <div className="flex lg:flex-row flex-col justify-evenly mt-1 w-full">
              <div className="mt-4 py-4 lg:w-1/3 flex flex-col justify-center items-center gap-1 text-[#475467] border-t-4 border-fedacash-header">
                <div className="flex gap-3 items-center text-black font-semibold">
                  <AiOutlineMail />
                  <p>Email Address</p>
                </div>
                <p> We&apos;d love to hear from you.</p>
                <p className="text-fedacash-header underline font-medium">
                support@fedacash.com
                </p>
              </div>
              <div className="mt-4 py-4 lg:w-1/3 flex flex-col justify-center items-center gap-1 text-[#475467] border-t-4 border-gray-200">
                <div className="flex gap-3 items-center text-black font-semibold">
                  <AiOutlinePhone />
                  <p>Phone Number</p>
                </div>
                <p> Get in touch with us.</p>
                <p className="text-fedacash-header underline font-medium">
                  +234802433200
                </p>
              </div>
              <div className="mt-4 py-4 lg:w-1/3 flex flex-col justify-center items-center gap-1 text-[#475467] border-t-4 border-gray-200">
                <div className="flex gap-3 text-center items-center text-black font-semibold">
                  <AiOutlineMail />
                  <p>Office Address</p>
                </div>
                <p className="text-center">
                  {" "}
                  Suite D-24, Dolphin Plaza, Corporation Drive,
                </p>
                <p>Dolphin Estate, Ikoyi, Lagos</p>
              </div>
            </div>
          </section>
        </section>
      </section>
    </section>
  );
};

export default Homepage;

const SliderComponent = () => {
  const [value, setValue] = useState(100000);

  const handleSliderChange = (e) => {
    const percentage = e.target.value / e.target.max;
    setValue(Math.floor(percentage * 10) * 100000);
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Value: {value}</h2>
      <input
        type="range"
        min="0"
        max="100"
        value={(value / 100000) * 10}
        className="w-full h-4 rounded-lg bg-blue-500 appearance-none focus:outline-none"
        onChange={handleSliderChange}
      />
    </div>
  );
};
