const NotificationItem = ({children, time, image}) => {
    return(
        <div className="flex items-center justify-start w-full">
            <div className="flex-none w-[56.69px] h-[56.69px] mr-[28.6px] p-[12.59px] rounded-full shadow-md shadow-gray-100">
                <img src={image} alt="icon" className="w-full h-full" />
            </div>
            <div className="flex-1 flex flex-col items-start justify-between">
                <p className="font-[700] text-[14px] leading-[21px] text-[#4b5768] whitespace-normal text-ellipsis">{children}</p>
                <p className="font-[500] text-[12px] leading-[18px] text-[#4b5768]" >{time}</p>
            </div>
        </div>
    )
}

export default NotificationItem;