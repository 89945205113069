import React, {useEffect} from "react";
import {Link, Outlet} from "react-router-dom";
import HeaderBg from "../../Assets/images/HeaderBg.png";

const Eligibility = () => {
    useEffect(() => {
        document.title = "Terms & Conditions | FedaCash SMEs";
    }, []);

    return (
        <div className=" h-full overflow-y-auto overflow-x-hidden w-full content-wrapper">
            <Outlet/>
            <div className="flex-grow">
                <div style={{marginTop: "0px"}}>
                    <header
                        className="p-20 md:px-80 md:py-28 text-center bg-[#350862]"
                        style={{
                            backgroundImage: `url(${HeaderBg})`,
                            backgroundPosition: "center center",
                            backgroundSize: "75%",
                            backgroundRepeat: "no-repeat",
                        }}
                    >
                        <p className="page-title-sub text-[#BD9EDB]">Terms & Condition</p>
                        <p className="page-title mt-4 text-[#fff]">
                           Membership & Eligibility
                        </p>
                        <p
                            className="md:px-28 mt-3 text-[#E7EAEE] text-center"
                            style={{fontWeight: 500, fontSize: "20px", lineHeight: "30px"}}
                        >
                            Join Our Cooperative, Open to All!
                            At Fedacash we believe in inclusivity and collaboration. Join our cooperative community and unlock a world of benefits and opportunities.
                        </p>
                    </header>

                    <section className="p-10 md:p-28">
                        <ul className="list-decimal tracking-tight flex flex-col w-full space-y-2">
                            <Content title="Eligibility Criteria">
                                <ul className="list-disc">
                                    <li>Individuals: Any individual who is at least 16 years old and legally competent can become a member of the cooperative in line with Laws of the Federal Republic of Nigeria.</li>
                                    <li>Businesses: Registered businesses, including sole proprietorships, partnerships, and limited liability companies, can also apply for membership.</li>
                                </ul>
                            </Content>
                            {/*2. Residency Requirement:*/}
                            {/*•Membership is open to both Nigerian residents provided they meet the other eligibility criteria.*/}

                            {/*3. Industry Affiliations:*/}
                            {/*•The cooperative is open to individuals and businesses from various industries and sectors, without any specific industry affiliation requirements.*/}
                            {/*•Members from diverse industries are encouraged to join, as it promotes a collaborative and inclusive business environment.*/}

                            {/*4. Compliance with Laws and Regulations:*/}
                            {/*•Prospective members must commit to comply with all relevant laws and regulations governing cooperatives in Nigeria, including but not limited to the Nigerian Cooperative Society Act and any subsequent amendments.*/}

                            {/*5. Application Process:*/}
                            {/*•To become a member, interested individuals or businesses must complete the membership application form provided by the cooperative.*/}
                            {/*•The application form should capture essential details such as personal or business information, contact details, and any relevant identification or registration numbers.*/}

                            {/*6. Approval Process:*/}
                            {/*•The cooperative's board of directors or an authorized committee will review and evaluate each membership application.*/}
                            {/*•Approval of membership is subject to satisfying the eligibility criteria and the successful completion of any required background checks or verifications.*/}

                            {/*7. Rights and Privileges:*/}
                            {/*1.Upon acceptance as a member, individuals or businesses will enjoy the rights and privileges granted to cooperative members as outlined in the cooperative's bylaws and rules.*/}

                            {/*8. Termination of Membership:*/}
                            {/*1.Membership may be terminated if a member fails to comply with the cooperative's bylaws, rules, or relevant laws and regulations.*/}
                            {/*2.Termination may also occur at the member's request or due to non-payment of required dues, fees, or contributions.*/}
                            <Content title="Residency Requirement">
                                <ul className="list-disc">
                                    <li>
                                        Membership is open to both Nigerian residents provided they
                                        meet the other eligibility criteria.
                                    </li>
                                </ul>
                            </Content>
                            <Content title="Industry Affiliations">
                                <ul className="list-disc">
                                    <li>
                                        The cooperative is open to individuals and businesses from
                                        various industries and sectors, without any specific
                                        industry affiliation requirements.
                                    </li>
                                    <li>
                                        Members from diverse industries are encouraged to join, as
                                        it promotes a collaborative and inclusive business
                                        environment.
                                    </li>
                                </ul>
                            </Content>
                            <Content title="Compliance with Laws and Regulations">
                                <ul className="list-disc">
                                    <li>
                                        Prospective members must commit to comply with all relevant
                                        laws and regulations governing cooperatives in Nigeria,
                                        including but not limited to the Nigerian Cooperative
                                        Society Act and any subsequent amendments.
                                    </li>
                                </ul>
                            </Content>
                            <Content title="Application Process">
                                <ul className="list-disc">
                                    <li>
                                        To become a member, interested individuals or businesses
                                        must complete the membership application form provided by
                                        the cooperative.
                                    </li>
                                    <li>
                                        The application form should capture essential details such
                                        as personal or business information, contact details, and
                                        any relevant identification or registration numbers.
                                    </li>
                                </ul>
                            </Content>
                            <Content title="Approval Process">
                                <ul className="list-disc">
                                    <li>
                                        The cooperative's board of directors or an authorized
                                        committee will review and evaluate each membership
                                        application.
                                    </li>
                                    <li>
                                        Approval of membership is subject to satisfying the
                                        eligibility criteria and the successful completion of any
                                        required background checks or verifications.
                                    </li>
                                </ul>
                            </Content>
                            <Content title="Rights and Privileges">
                                <ul className="list-disc">
                                    <li>
                                        Upon acceptance as a member, individuals or businesses will
                                        enjoy the rights and privileges granted to cooperative
                                        members as outlined in the cooperative's bylaws and rules.
                                    </li>
                                </ul>
                            </Content>
                            <Content title="Termination of Membership">
                                <ul className="list-disc">
                                    <li>
                                        Membership may be terminated if a member fails to comply
                                        with the cooperative's bylaws, rules, or relevant laws and
                                        regulations.
                                    </li>
                                    <li>
                                        Termination may also occur at the member's request or due
                                        to non-payment of required dues, fees, or contributions.
                                    </li>
                                </ul>
                            </Content>
                        </ul>
                    </section>
                </div>
            </div>
        </div>
    );
};


const Content = ({title, children}) => {
    return (
        <li>
            <h5 className="text-[#590DA4] font-semibold">{title}</h5>
            {children}
        </li>
    )
}

export default Eligibility;
