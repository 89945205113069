import React from "react";
import { Dropdown } from "antd";
import { BsThreeDots } from "react-icons/bs";
import moment from "moment/moment";

import Panel from "../Panel";
import { Body, Column, ColumnHeader, Row, RowHeader, Table } from "./Table";
import { formatNaira } from "../../Utils/FormatMoney";
import { ViewLoanModal } from "./ViewLoanModal";
import { LoanForm } from "./LoanForm";

const LoanTable = ({ loandata }) => {
  return (
    <Panel>
    <div className="flex justify-end my-3"><LoanForm/></div>
      <Table>
        <RowHeader>
          <ColumnHeader>Description</ColumnHeader>
          <ColumnHeader>Date Created</ColumnHeader>
          <ColumnHeader>Amount</ColumnHeader>
          <ColumnHeader>Approved Amount</ColumnHeader>
          <ColumnHeader>Duration</ColumnHeader>
          <ColumnHeader>Status</ColumnHeader>
          <ColumnHeader>Action</ColumnHeader>
        </RowHeader>
        <Body data={loandata}>
          {(data) =>
            data.map((item) => (
              <Row key={item.loan_token}>
                <Column>{item.description}</Column>
                <Column>
                  {moment(item.created_at).format("dddd, Do MMMM YYYY: h:mmA")}
                </Column>

                <Column>{formatNaira(item.amount)}</Column>
                <Column>{formatNaira(item?.approved_amount)}</Column>
                <Column>{item.duration} days</Column>
                <Column>
                    <span classname="text-xs text-green-600 px-2 py-1 rounded-md bg-gray-50">
                      {item.request_status}
                    </span>
                  </Column>
                <Column>
                  <Dropdown
                    trigger={["click"]}
                    menu={{
                      items: [
                        {
                          key: "1",
                          label: <ViewLoanModal loanModalData={item} />,
                        },
                      ],
                    }}
                    placement="bottomRight"
                    arrow={{ pointAtCenter: true }}
                  >
                    <div className="flex cursor-pointer items-center gap-x-1">
                      <BsThreeDots />
                    </div>
                  </Dropdown>
                </Column>
              </Row>
            ))
          }
        </Body>
      </Table>
    </Panel>
  );
};

export default LoanTable;
