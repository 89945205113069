import { Tab, Dialog, Transition } from "@headlessui/react";
import { AiOutlinePlus } from "react-icons/ai";

// import { LoanTab } from "../../../Components/Dashboard/Loan/LoanTab";
import { LoanWalletCard } from "../../../Components/Dashboard/Loan/LoanWallet";
import { SalaryTable } from "../../../Components/Dashboard/salary/SalaryTable";
import PageHeader from "../../../Components/Dashboard/PageHeader";

const SalaryIndex = () => {
  return (
    <section className="space-y-4">
      {/* <div className="">
        <LoanWalletCard module_name={`Salary Advance`} />
      </div> */}
      <div>
        <div className="py-4">
          <PageHeader className="text-lg font-medium">Salary Advance Request</PageHeader>
        </div>
        <SalaryTable/>
      </div>
      {/* <LoanTab /> */}
    </section>
  );
};

export default SalaryIndex;

