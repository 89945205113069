import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";
import moment from "moment/moment";

import { formatNaira } from "../../Utils/FormatMoney";
import loanImage from "../../../Assets/images/loan-form-image.png";
import { Link } from "react-router-dom";

export const ViewLoanModal = ({ loanModalData }) => {
  const [isOpen, setIsOpen] = useState(false);

  function toggleCreatePlanModal() {
    setIsOpen((prev) => !prev);
  }
  return (
    <>
      <button
        className="flex loanModalDatas-center justify-start space-x-1 text-sm font-medium focus:border-none focus:outline-none"
        onClick={toggleCreatePlanModal}
      >
        <span>View More</span>
      </button>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={toggleCreatePlanModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-fedacash-header bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full loanModalDatas-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full rounded-md max-w-2xl transform overflow-hidden bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="flex flex-col gap-y-3 justify-center text-center loanModalDatas-center text-lg font-bold leading-6 text-[#475467] py-4"
                  >
                    <span className="text-[#4B5768] font-medium text-lg md:text-xl">
                      Loan Request
                    </span>
                    {/* <hr className="bg-fedacash-header w-32 h-1" /> */}
                    <span className="text-sm md:text-lg font-medium">
                      How much Loan do you want to request for?
                    </span>
                  </Dialog.Title>
                  <div className="bg-[#EAECF0] rounded-2xl flex loanModalDatas-center justify-center py-4">
                    <img
                      src={loanImage}
                      alt=""
                      className="w-32 h-32"
                      width={288}
                      height={288}
                    />{" "}
                  </div>
                  <div className="space-y-4">
                    <div className="my-2 space-y-2">
                      <p className="mb-1 capitalize text-gray-700 w-full text-lg font-bold">
                        Description
                      </p>
                      <div className="px-4 form-input focus:outline-none text-gray-800 tracking-wide border bg-transparent focus:border-purple-800 py-4 text-base placeholder:text-gray-400 font-semibold w-full">
                        {loanModalData.description}
                      </div>
                    </div>
                    <div className="my-2 space-y-2">
                      <p className="mb-1 capitalize text-gray-700 w-full text-lg font-bold">
                        Requested Amount
                      </p>
                      <div className="px-4 form-input focus:outline-none text-gray-800 tracking-wide border bg-transparent focus:border-purple-800 py-4 text-base placeholder:text-gray-400 font-semibold w-full">
                        {formatNaira(loanModalData.amount)}
                      </div>
                    </div>
                    <div className="my-2 space-y-2">
                      <p className="mb-1 capitalize text-gray-700 w-full text-lg font-bold">
                        Approved Amount
                      </p>
                      <div className="px-4 form-input focus:outline-none text-gray-800 tracking-wide border bg-transparent focus:border-purple-800 py-4 text-base placeholder:text-gray-400 font-semibold w-full">
                        {formatNaira(loanModalData?.approved_amount)}
                      </div>
                    </div>
                    <div className="my-2 space-y-2">
                      <p className="mb-1 capitalize text-gray-700 w-full text-lg font-bold">
                        Date
                      </p>
                      <div className="px-4 form-input focus:outline-none text-gray-800 tracking-wide border bg-transparent focus:border-purple-800 py-4 text-base placeholder:text-gray-400 font-semibold w-full">
                        {" "}
                        {moment(loanModalData.created_at).format(
                          "dddd, Do MMMM YYYY: h:mmA"
                        )}
                      </div>
                    </div>
                    <div className="my-2 space-y-2">
                      <p className="mb-1 capitalize text-gray-700 w-full text-lg font-bold">
                        Status
                      </p>
                      <div className="px-4 form-input focus:outline-none text-gray-800 tracking-wide border bg-transparent focus:border-purple-800 py-4 text-base placeholder:text-gray-400 font-semibold w-full">
                        {loanModalData?.request_status}
                      </div>
                    </div>
                    {
                      loanModalData?.request_status ==='Declined'?(
                        <div className="my-2 space-y-2">
                        <p className="mb-1 capitalize text-gray-700 w-full text-lg font-bold">
                          Reason fo Decline
                        </p>
                        <div className="px-4 form-input focus:outline-none text-gray-800 tracking-wide border bg-transparent focus:border-purple-800 py-4 text-base placeholder:text-gray-400 font-semibold w-full">
                          {loanModalData?.reason}
                        </div>
                      </div>

                      ):("")
                    }
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
