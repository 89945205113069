import React from "react";
import emoji from "../../../Assets/images/contact-emoji.png";
import { Form } from "react-router-dom";
import { AiOutlineMail} from "react-icons/ai";
import { BsFillTelephoneFill } from "react-icons/bs";

const Contact = () => {
  return (
    <section>
      <section className="xl:px-2 px-4 container mx-auto lg:py-12 py-16 gap-y-4 hero-bg">
        <section className="flex justify-between">
          <div className="flex flex-col justify-center gap-y-8">
            <h2 className="lg:text-6xl md:text-4xl text-3xl font-semibold">
              Let&apos;s work{" "}
              <span className="text-fedacash-header">together!</span>
            </h2>
            <p className="lg:text-2xl md:text-lg text-base">
              Let us help you take the first step in Transforming your Business.
            </p>
          </div>
          <div>
            <img src={emoji} alt="Emoji" className="" />
          </div>
        </section>
        <section className="flex flex-col lg:flex-row xl:gap-3 justify-center lg:items-center gap-y-4 lg:justify-between">
          <Form className="flex flex-col gap-10 lg:w-1/2">
            <div className="flex flex-col gap-7 capitalize mt-7 text-fedacash-textColor text-lg font-medium">
              <div className="flex flex-wrap lg:flex-nowrap gap-4">
                <div className="flex flex-col gap-2 w-full">
                  <label className="leading-[150%] text-sm">first Name</label>
                  <input
                    className="h-10 p-4 border-2 rounded-md placeholder:text-xs placeholder:capitalize"
                    placeholder="first name"
                    name="firstName"
                  />
                </div>
                <div className="flex flex-col gap-2 w-full">
                  <label className="leading-[150%] text-sm">last Name</label>
                  <input
                    className="h-10 p-4 border-2 rounded-md placeholder:text-xs placeholder:capitalize"
                    placeholder="last name"
                    name="lastName"
                  />
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <label className="leading-[150%] text-sm">Email</label>
                <input
                  className="h-10 p-4 border-2 rounded-md placeholder:text-xs"
                  placeholder="youremail@example.com"
                  name="email"
                />{" "}
              </div>
              <div className="flex flex-col gap-2">
                <label className="leading-[150%] text-sm">Message</label>
                <textarea
                  name="message"
                  className="h-36 px-3.5 py-2.5 bg-white rounded-lg border border-gray-300 justify-start items-center gap-2 inline-flex"
                ></textarea>
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="rounded-xl w-full text-white bg-fedacash-header p-3"
              >
                Send
              </button>
            </div>
          </Form>
          <section className="lg:w-1/3">
            <div className="flex flex-col gap-y-2 font-medium mb-3">
              <div className=" flex justify-center items-center bg-[#DECFED] rounded-full w-10 px-2.5 py-1">
                <div className="bg-[#BD9EDB] rounded-full p-2 w-8">
                  <AiOutlineMail height={7} />
                </div>
              </div>
              <h4 className="text-lg">Email</h4>
              <p className="font-normal">Our friendly team is here to help.</p>
              <p className="text-fedacash-header">support@fedacash.com</p>
            </div>
            <div className="flex flex-col gap-y-2 font-medium">
              <div className=" flex justify-center items-center bg-[#DECFED] rounded-full w-10 px-2.5 py-1">
                <div className="bg-[#BD9EDB] rounded-full p-2 w-8">
                  <BsFillTelephoneFill height={7} />
                </div>
              </div>
              <h4 className="text-lg">Phone</h4>
              <p className="font-normal">Mon-Fri from 9am to 5pm.</p>
              <p className="text-fedacash-header">+2348024332000</p>
            </div>
          </section>
        </section>
      </section>
    </section>
  );
};

export default Contact;
