import { AiOutlineArrowRight } from "react-icons/ai";
import ctaIphone from "../../Assets/images/send.png";
import { Link } from "react-router-dom";
import { useRef } from "react";
import { motion, useInView } from "framer-motion";
import { FADE_UP_ANIMATION_VARIANTS } from "../../utils/framer-variants";

const CTA = () => {
  const ctaRef = useRef(null);
  const isInView = useInView(ctaRef, { once: true });
  return (
    <motion.section
      initial="hidden"
      animate={isInView ? "show" : ""}
      viewport={{ once: true }}
      variants={{
        hidden: {},
        show: {
          transition: {
            staggerChildren: 0.15,
          },
        },
      }}
    >

      <div className="mx-auto h-[90vh] lg:h-full flex flex-col lg:flex-row gap-y-2 justify-evenly items-center px-4 lg:px-24 lg:py-4 products-bg">
        <div className="max-w-2xl mt-4 flex flex-col gap-y-3 lg:gap-y-5 font-medium text-white">
          <motion.h2
            variants={FADE_UP_ANIMATION_VARIANTS}
            className="lg:text-5xl text-xl "
          >
            Transform your Payment Cycle
          </motion.h2>
          <motion.h4
            variants={FADE_UP_ANIMATION_VARIANTS}
            className="lg:text-2xl text-sm"
            ref={ctaRef}
          >
            Start Using FedaCash Today!
          </motion.h4>
          <Link
            to={`/auth/register`}
            className="flex gap-3 justify-evenly bg-white items-center w-64 text-fedacash-header rounded-3xl px-4 py-2"
          >
            Get Started For Free
            <AiOutlineArrowRight />
          </Link>
        </div>
        <div>
          <motion.img
            src={ctaIphone}
            variants={FADE_UP_ANIMATION_VARIANTS}
            alt="CTA"
          />
        </div>
      </div>
    </motion.section>
  );
};

export default CTA;
