const PAYSTACK_KEY =
  process.env.REACT_APP_VERCEL_ENV !== "production"
    ? process.env.REACT_APP_TEST_KEY
    : process.env.REACT_APP_LIVE_KEY;
//TODO:// fix this later into in function
const calculatePaystackCharges = (amount) => {
  const paystackCharges = amount > 1999 ? amount * 0.015 + 100 : amount * 0.015;
  return paystackCharges;
};
const calculateFinalAmount = (amount) => {
  const paystackCharges = amount > 1999 ? amount * 0.015 + 100 : amount * 0.015;

  return parseFloat(amount) + parseFloat(paystackCharges);
};

export { PAYSTACK_KEY, calculatePaystackCharges, calculateFinalAmount };
