import BaseController from "./BaseController";
import http from "../../Store/baseHttp";
import ResponseObject from "../../DataObjects/ResponseObject";

class CooperativeService extends BaseController {
  async index({ request, params }) {
    try {
      let packages = await http.get(
        "/cooperative/my/packages?sort_type=DESC&sort_by=id"
      );
      let transactionHistory = await http.get(
        "/cooperative/paystack/my/transactions"
      );
      let activePackages = await http.get("/cooperative/my/active/packages");
      let walletBalance = await http.get("/cooperative/my/wallet/total");

      packages = ResponseObject.from(packages);
      transactionHistory = ResponseObject.from(transactionHistory);
      activePackages = ResponseObject.from(activePackages);
      walletBalance = ResponseObject.from(walletBalance);
      return {
        packages,
        transactionHistory,
        activePackages,
        walletBalance
      };
    } catch (e) {
      return ResponseObject.from(e?.response);
    }
  }

  async post({ request }) {
    let formData = Object.fromEntries(await request.formData());
    // formData.contribution_amount = formData.contribution_amount.replace(/,/g, '');
    try {
      let response = await http.post("/cooperative/join", formData);

      response = ResponseObject.from(response);
      return response;
    } catch (e) {
      return ResponseObject.from(e?.response);
    }
  }

  // async put({ request }) {
  //     let formData = Object.fromEntries(await request.formData());
  //     formData.contribution_amount = formData.contribution_amount.replace(/,/g, '');
  //     try {
  //         let response = await http.put("/cooperative/change", formData);

  //         response = ResponseObject.from(response);
  //         return response;
  //     } catch (e) {
  //         return ResponseObject.from(e?.response);
  //     }
  // }
}

export default CooperativeService;

export const CreateSharedPlan = async (data) => {
  let SharedPlanResponse ;
  try {
    SharedPlanResponse = await http.post("/cooperative/create/package", data);
    SharedPlanResponse = ResponseObject.from(SharedPlanResponse);

    console.log(SharedPlanResponse);
    return {SharedPlanResponse}
  } catch (error) {
    console.error(error?.response);
    SharedPlanResponse = ResponseObject.from(error?.response);
    return {SharedPlanResponse}
  }
};

export async function joinPlan(data) {
  try {
    let response = await http.post("/cooperative/join", data);
    response = ResponseObject.from(response);
    return response;
  } catch (e) {
    return ResponseObject.from(e?.response);
  }
}