import React, { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { faq } from "./constants";
import { motion } from "framer-motion";
import { FADE_UP_ANIMATION_VARIANTS } from "../../../utils/framer-variants";

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const handleOpen = (index) => {
    setActiveIndex(index);
  };
  return (
    <div className="mt-8 xl:px-48 lg:px-32">
      {faq.map((item, index) => (
        <motion.div
          initial="hidden"
          animate={'show'}
          viewport={{ once: true }}
          variants={{
            hidden: {},
            show: {
              transition: {
                staggerChildren: 0.15,
              },
            },
          }}
          key={index}
          className="flex flex-col px-6 py-5 bg-[#decfed26] text-fedacash-header rounded-lg border border-[#590da41a] border-opacity-10 gap-6 backdrop-blur-sm mb-3"
          onClick={() => handleOpen(index)}
        >
          <div className="flex justify-between">
            <div className="text-black md:text-lg text-sm font-normal leading-7">
              {item.question}
            </div>
            <AiOutlinePlus />
          </div>
          {activeIndex === index && (
            <motion.div
              variants={FADE_UP_ANIMATION_VARIANTS}
              className="self-stretch md:text-lg text-xs font-medium"
            >
              {item.answer}
            </motion.div>
          )}
        </motion.div>
      ))}
    </div>
  );
};

export default Faq;
