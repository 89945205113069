import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";
import {
  useFetcher,
  useActionData,
  Form,
  useLoaderData,
} from "react-router-dom";
import {
  assert,
  object,
  string,
  nonempty,
  StructError,
  number,
  coerce,
  pattern,
  refine,
} from "superstruct";
import { usePaystackPayment } from "react-paystack";

import Input from "../../Forms/Input";
import Select from "../../Forms/Select";
import Submit from "../../Forms/Submit";
import loanImage from "../../../Assets/images/loan-form-image.png";
import { Link } from "react-router-dom";
import UserObject from "../../../DataObjects/UserObject";
import { StatusDialog } from "../Shared/StatusDialog";
import {
  CreateLoanRequest,
  SendLoanRequest,
} from "../../../Services/Dashboard/LoanRequestService";
import ToastContext from "../../../Routes/ToastContext";

// Define a custom type for numbers that are stored as strings
const NumberString = coerce(string(), number(), (value) => {
  parseFloat(value);
});
// Define a schema for positive numbers
const AccountNumber = pattern(string(), /^\d{10}$/);
const Positive = refine(NumberString, "positive", (value) => {
  if (value >= 1000 || value > 50000) {
    return true; // Return the value if it meets the condition
  } else {
    return "The amount must be at least 1000 or at most 50000 Naira"; // Throw an error otherwise
  }
});
const formSchema = object({
  amount: nonempty(Positive),
  description: nonempty(string(), "This Field is Required"),
  duration: nonempty(string()),
  repayment_amount: number(),
  interest_given: number(),
  bank_name: nonempty(string()),
  bank_code: string(),
  recipient_account_name: string(),
  recipient_account_number: AccountNumber,
});

export const LoanForm = () => {
  // const { organization } = UserObject.getUser()
  const PAYSTACK_CHARGES = 100;
  const { flashToast } = useContext(ToastContext);

  const { bankDetails } = useLoaderData();

  const { state, data } = useFetcher();
  let actionData = useActionData();

  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checkbox, setCheckbox] = useState(false);
  const [bankIndex, setBankIndex] = useState("");
  const [formValues, setFormValues] = useState({
    recipient_account_number: "",
    bank_code: "",
    bank_name: "",
    recipient_account_name: "",
  });
  const [plan, setPlan] = useState({
    amount: "",
    description: "",
    duration: "",
  });
  const [formError, setFormError] = useState({
    amount: "",
    description: "",
    duration: "",
  });
  const [message, setMessage] = useState("");
  const [maxLoanAmount, setMaxLoanAmount] = useState("");
  const [isStatusOpen, setIsStatusOpen] = useState(false);
  const [hasModalBeenShown, setHasModalBeenShown] = useState(false);
  //Payment Functions
  const paystackCharges =
    PAYSTACK_CHARGES > 1999
      ? PAYSTACK_CHARGES * 0.015 + 100
      : PAYSTACK_CHARGES * 0.015;
  const finalAmount =
    parseFloat(PAYSTACK_CHARGES) + parseFloat(paystackCharges);
  //paystack config
  const config = {
    reference: new Date().getTime().toString(),
    email:
      UserObject.getUser().email ??
      `${UserObject.getUser().phone_number}@fedacash.com`,
    amount: parseInt(finalAmount * 100), //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey:
      process.env.REACT_APP_VERCEL_ENV !== "production"
        ? process.env.REACT_APP_TEST_KEY
        : process.env.REACT_APP_LIVE_KEY,
    channels: ["card"],
  };
  // you can call this function anything
  const onSuccess = (reference) => {
    const { repaymentAmount, rate } = calculateInterest(
      plan.amount,
      plan?.duration
    );
    SendLoanRequest({
      ...plan,
      interest_given: rate.toString(),
      repayment_amount: repaymentAmount,
      recipient_account_name: formValues.account_name,
      recipient_account_number: formValues.account_number,
      bank_code: formValues.bank_code,
      bank_name: formValues.bank_name,
      card_test_charge: 100,
      reference: reference?.reference,
    }).then((response) => {
      toggleCreatePlanModal();
      openStatusModal();
      flashToast(
        response?.createLoanRequestResponse?.success,
        response?.createLoanRequestResponse?.message
      );
    });
  };
  const onPaystackClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    // console.log("closed");k
  };
  const initializePayment = usePaystackPayment(config);

  function validateForm() {
    const { repaymentAmount, rate } = calculateInterest(
      plan.amount,
      plan?.duration
    );
    function mapErrorsToFormState(errors) {
      const mappedErrors = {};
      errors.forEach((error) => {
        const { key, message } = error;
        mappedErrors[key] = message;
      });
      return mappedErrors;
    }
    try {
      // console.log('repaymentAmount', repaymentAmount)
      assert(
        {
          ...plan,
          interest_given: rate,
          repayment_amount: repaymentAmount,
          recipient_account_name: formValues.account_name,
          recipient_account_number: formValues.account_number,
          bank_code: formValues.bank_code,
          bank_name: formValues.bank_name,
        },
        formSchema
      );
      setFormError({});
      initializePayment(onSuccess, onPaystackClose);
    } catch (err) {
      if (err instanceof StructError) {
        const updatedFormError = mapErrorsToFormState(err.failures());
        setFormError((prevFormError) => ({
          ...prevFormError,
          ...updatedFormError,
        }));
        return updatedFormError;
      }
    }
  }
  function closeStatusModal() {
    setIsStatusOpen(false);
  }
  function openStatusModal() {
    setIsStatusOpen(true);
  }
  const onChange = (e) => {
    setPlan((plan) => ({ ...plan, [e.target.name]: e.target.value }));
  };

  function toggleCreatePlanModal() {
    setIsOpen((prev) => !prev);
  }
  function calculateInterest(amount, duration) {
    // Define the loan table without the 'period' property
    const loanTable = [
      { period: 14, minAmount: 1000, maxAmount: 50000, rate: 7 },
      { period: 14, minAmount: 50001, maxAmount: 100000, rate: 7 },
      { period: 14, minAmount: 100001, maxAmount: 250000, rate: 7 },
      { period: 30, minAmount: 1000, maxAmount: 50000, rate: 6 },
      { period: 30, minAmount: 50001, maxAmount: 100000, rate: 6 },
      { period: 30, minAmount: 100001, maxAmount: 150000, rate: 6 },
      { period: 30, minAmount: 150001, maxAmount: 250000, rate: 6 },
      { period: 60, minAmount: 1000, maxAmount: 50000, rate: 15 },
      { period: 60, minAmount: 50001, maxAmount: 100000, rate: 15 },
      { period: 60, minAmount: 100001, maxAmount: 150000, rate: 15 },
      { period: 60, minAmount: 150001, maxAmount: 250000, rate: 15 },
    ];

    // Find the matching loan entry
    const matchingLoan = loanTable.find((entry) => {
      return (
        entry.period === parseInt(duration) &&
        parseInt(amount) >= entry.minAmount &&
        parseInt(amount) <= entry.maxAmount
      );
    });
    if (matchingLoan) {
      // Calculate interest
      const interest = (amount * matchingLoan.rate) / 100;
      const rate = matchingLoan.rate;
      const repaymentAmount = interest + parseInt(amount);
      return { repaymentAmount, rate };
    } else {
      return "No matching loan entry found.";
    }
  }
  const addBankDetails = (bank, index) => {
    setBankIndex(index);
    for (const key in bank) {
      if (Object.hasOwnProperty.call(bank, key)) {
        const value = bank[key];
        // Update state for each key-value pair in the bankObject
        //dont edit this function it has a breaking change, becuse of the key it recives ffrom the bank object isnt the same as the values boject thank to our backend dev
        // console.log(key, value)
        setFormValues((formValue) => ({
          ...formValue,
          [key]: value,
        }));
      }
    }
  };
  const { repaymentAmount, rate } = calculateInterest(
    plan.amount,
    plan?.duration
  );
  return (
    <>
      <button
        className="flex items-center gap-2 mt-2 text-sm bg-black p-2 text-white rounded-md"
        onClick={toggleCreatePlanModal}
      >
        <AiOutlinePlus />
        <span>Loan Request</span>
      </button>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={toggleCreatePlanModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-fedacash-header bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full rounded-md max-w-2xl transform overflow-hidden bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="flex flex-col gap-y-3 justify-center text-center items-center text-lg font-bold leading-6 text-[#475467] py-4"
                  >
                    <span className="text-[#4B5768] font-medium text-lg md:text-xl">
                      Loan Request
                    </span>
                    <hr className="bg-fedacash-header w-32 h-1" />
                    <span className="text-sm md:text-lg font-medium">
                      How much Loan do you want to request for?
                    </span>
                  </Dialog.Title>
                  <div className="bg-[#EAECF0] rounded-2xl flex items-center justify-center py-4">
                    <img
                      src={loanImage}
                      alt=""
                      className="w-32 h-32"
                      width={288}
                      height={288}
                    />{" "}
                  </div>
                  <form
                    className="w-full pt-6"
                    method="post"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validateForm();
                    }}
                  >
                    <Input
                      label="Enter Amount"
                      placeholder="Enter Amount"
                      name="amount"
                      type="number"
                      normalize={true}
                      onChange={onChange}
                      value={plan?.amount}
                    />
                    <div className="mb-3">
                      {formError?.amount && (
                        <small className="text-red-700">
                          {formError?.amount}
                        </small>
                      )}
                    </div>

                    <Input
                      label="Enter Description"
                      placeholder="Enter Description"
                      name={"description"}
                      normalize={true}
                      type="text"
                      error={formError?.description}
                      onChange={onChange}
                      value={plan?.description}
                    />
                    <div className="mb-3">
                      {formError?.description && (
                        <small className="text-red-700">
                          {formError?.description}
                        </small>
                      )}
                    </div>
                    <p className="mb-1 capitalize text-gray-700 w-full text-lg font-bold">
                      Loan Duration
                    </p>
                    <Select
                      label="Loan Duration"
                      placeholder="Loan Duration"
                      name={"duration"}
                      error={formError?.duration}
                      onChange={onChange}
                      value={plan?.duration}
                    >
                      <option>--Select Option--</option>
                      <option value="14">14 Days</option>
                      <option value="30">30 Days</option>
                      <option value="60">60 Days</option>
                    </Select>
                    <div className="mb-3">
                      {formError?.duration && (
                        <small className="text-red-700">
                          {formError?.duration}
                        </small>
                      )}
                    </div>
                    <Input
                      label="Total Repayment Amount"
                      placeholder="Total Repayment Amount"
                      normalize={true}
                      name={"repayment_amount"}
                      type="number"
                      onChange={onChange}
                      value={repaymentAmount ?? 0}
                      // error={formError?.repayment_amount}
                    />
                    <Input
                      label="Interest"
                      placeholder="interest"
                      normalize={true}
                      name={"interest_given"}
                      type="number"
                      value={rate ?? 0}
                      // error={actionData?.errors?.interest_given}
                    />
                    {/* TODO://fix hidden input */}
                    {/* I used hidden inputs to send the data to the backend beeucse I tried using formdata and appending it but it didnt work */}
                    <div className="hidden">
                      <input
                        type="text"
                        className="border rounded-md p-2 mb-2"
                        name="bank_name"
                        value={formValues.bank_name}
                      />
                      <input
                        type="text"
                        className="border rounded-md p-2 mb-2"
                        name="bank_code"
                        value={formValues.bank_code}
                      />
                      <input
                        type="text"
                        className="border rounded-md p-2 mb-2"
                        name="recipient_account_name"
                        value={formValues.account_name}
                      />
                      <input
                        type="text"
                        className="border rounded-md p-2 mb-2"
                        name="recipient_account_number"
                        value={formValues.account_number}
                      />
                    </div>

                    <div className="space-y-3 max-h-[300px] overflow-y-auto">
                      <h3 className="text-lg font-medium">
                        Select your preferred bank account
                      </h3>
                      {bankDetails?.data?.bank_object?.length !== 0 ? (
                        bankDetails?.data?.bank_object?.map((bank, index) => {
                          return (
                            <div
                              className={`flex flex-row items-center gap-x-4 justify-start px-2 py-3 `}
                              key={index}
                              onClick={(e) => addBankDetails(bank, index)}
                            >
                              <div
                                className={`w-4 h-4 rounded-md ${
                                  bankIndex === index
                                    ? "bg-fedacash-header"
                                    : " border-2 border-fedacash-primary"
                                }`}
                              ></div>
                              <div
                                className={`w-full p-3 rounded-md ${
                                  bankIndex === index
                                    ? "bg-[#DECFED]"
                                    : "bg-[#F6F8FA]"
                                }`}
                              >
                                <p className="text-lg font-medium">
                                  {bank?.account_number}
                                  {/* {console.log(bank)} */}
                                </p>
                                <p className="">{bank?.bank_name}</p>
                                <p className="">{bank?.account_name}</p>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div>
                          <p>You haven't added any account with us</p>
                          <p>
                            Go to your{" "}
                            <Link
                              to={`/dashboard/settings`}
                              className="text-fedacash-header font medium cursor-pointer"
                            >
                              profile
                            </Link>{" "}
                            to add
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="mb-3">
                      {formError?.recipient_account_number && (
                        <small className="text-red-700">
                          {formError?.recipient_account_number}
                        </small>
                      )}
                    </div>

                    <div className="mb-3">
                      {formError?.recipient_account_name && (
                        <small className="text-red-700">
                          {formError?.recipient_account_name}
                        </small>
                      )}
                    </div>
                    <div className="flex gap-x-4 items-center py-4">
                      <input
                        type="checkbox"
                        className="rounded-md"
                        onChange={() => setCheckbox((prev) => !prev)}
                        checked={checkbox}
                      />
                      <p className="font-medium">
                        View and Accept{" "}
                        <Link
                          to={`/terms-and-conditions`}
                          target="_blank"
                          className="text-fedacash-header"
                        >
                          Terms & Conditions
                        </Link>
                      </p>
                    </div>
                    <div>
                      <p className="text-sm font-medium my-4">
                        {" "}
                        Note: you are required to fill in your card details,
                        where you will be debited the sum of 	&#8358;100 to test your
                        card. You will be refunded after the transaction.
                      </p>
                    </div>
                    <div className="flex items-end justify-end gap-x-4">
                      <button
                        onClick={toggleCreatePlanModal}
                        className="text-center w-full py-2 md:py-3 md:px-2 rounded-md text-sm lg:text-base disabled:opacity-50 border border-gray-300"
                      >
                        <span>Cancel</span>
                      </button>
                      <Submit
                        type="submit"
                        isLoading={state == "submitting" ? true : false}
                        disabled={!checkbox && state === "submitting"}
                      >
                        Submit
                      </Submit>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      {actionData?.createLoanRequestResponse ? (
        <StatusDialog
          closeModal={closeStatusModal}
          isOpen={isStatusOpen}
          message={actionData?.createLoanRequestResponse}
        />
      ) : (
        ""
      )}
    </>
  );
};
