import DataObject from "./DataObject";

class ResponseObject extends DataObject {
    success;
    errors;
    data;
    message;

    constructor(response) {
        super(response);
        const { data } = response ?? {};
        this.success = data?.success
        this.errors = data?.errors
        this.data = data?.data
        this.message = data?.message
    }

    isSuccessful() {
        return this.success;
    }
}

export default ResponseObject;