import React, { Fragment, useState } from "react";
import { Menu, Transition, Dialog } from "@headlessui/react";
import {
  AiFillCloseCircle,
  AiOutlineArrowRight,
  AiOutlineMenu,
} from "react-icons/ai";
import { BsChevronDown } from "react-icons/bs";
///file import
import logo from "../../../Assets/logo/Logo.png";
import { Link } from "react-router-dom";

const links = [
  { href: "about", label: "about us" },
  { href: "services/generate-invoice", label: "Generate Invoice" },
  { href: "services/invoice-discount", label: "Invoice Discounting" },
  { href: "services/cooperative", label: "Cooperatives" },
  { href: "contact", label: "contact us" },
];
const mobileLinks = [
  { href: "/about", label: "about us" },
  { href: "/services/generate-invoice", label: "Generate Invoice" },
  { href: "/services/invoice-discount", label: "Invoice Discounting" },
  { href: "/services/cooperative", label: "Cooperatives" },
  { href: "/auth/login", label: "Login" },
  { href: "/auth/register", label: "Sign Up" },
  { href: "/contact", label: "contact us" },
];
const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    console.log("Closing");
    setIsOpen((prev) => !prev);
    console.log("closed");
  }
  return (
    <section className="">
      <section className="xl:px-2 px-4 container mx-auto py-8 capitalize font-medium ">
        <Menu>
          <section className="flex justify-between gap-3">
            <Link to="/">
              <img
                src={logo}
                className="h-[35px] md:h-[48px] -mt-4"
                alt="FedaCash"
              />
            </Link>
            <div className="hidden xl:flex justify-evenly gap-6">
              {links.map((link, index) => (
                /* Use the `active` state to conditionally style the active item. */
                <Link to={link.href} className="px-1 py-1" key={index}>
                  {link.label}
                </Link>
              ))}
            </div>
            <div className="hidden xl:flex  gap-x-3 ">
              <div>
                <Link
                  to={`/auth/login`}
                  className="px-5 py-4 text-fedacash-header border border-fedacash-header rounded-xl"
                >
                  Login
                </Link>
              </div>
              <div>
                <Link
                  to={`/auth/register`}
                  className="px-5 py-4 text-white bg-fedacash-header rounded-xl flex gap-2 items-center -mt-4"
                >
                  Sign up
                  <AiOutlineArrowRight />
                </Link>
              </div>
            </div>
            <div className="block xl:hidden" onClick={openModal}>
              <AiOutlineMenu size={30} />
              {isOpen && (
                <MyModal
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  closeModal={closeModal}
                />
              )}
            </div>
          </section>
        </Menu>
      </section>
    </section>
  );
};

export default Navbar;

const MyModal = ({ isOpen, closeModal }) => {
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <div
            className="fixed inset-0 bg-[#590DA4] bg-opacity-25"
            aria-hidden="true"
          />
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto min:h-1/3">
            <div className="flex min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white py-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="p"
                    className="flex justify-end text-lg font-medium px-3"
                    onClick={closeModal}
                  >
                    <AiFillCloseCircle size={35} color="#590DA4" />
                  </Dialog.Title>
                  <div className="mt-4"></div>
                  <ul className="flex flex-col gap-3 font-medium capitalize">
                    {mobileLinks.map((link, index) => (
                      /* Use the `active` state to conditionally style the active item. */
                      <li
                        key={index}
                        className="px-4 py-2 text-lg border-b"
                        onClick={closeModal}
                      >
                        <a href={link.href} className="">
                          {link.label}
                        </a>
                      </li>
                    ))}
                  </ul>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
