import { motion, useInView } from "framer-motion";
import { useRef } from "react";
///
import { FADE_UP_ANIMATION_VARIANTS } from "../../../../utils/framer-variants";

import cooperativeMask from "../../../../Assets/images/cooperative-mask.png";
import loanRequest from "../../../../Assets/images/loan-request.png";
import transactionHistoryRreport from "../../../../Assets/images/transaction-history-report.png";
import CTA from "../../../../Components/LandingPage/cta";

const CooperativeServices = () => {
  const loanRef = useRef(null);
  const isLoaninView = useInView(loanRef, { once: true });
  const contributionRef = useRef(null);
  const isContributioninView = useInView(contributionRef, { once: true });
  return (
    <section>
      <section className="xl:px-12 px-4 container mx-auto lg:py-8 py-10">
        <motion.section
          initial="hidden"
          animate={"show"}
          viewport={{ once: true }}
          variants={{
            hidden: {},
            show: {
              transition: {
                staggerChildren: 0.15,
              },
            },
          }}
          className="flex flex-col-reverse  lg:flex-row gap-y-3 xl:gap-3 justify-center items-center lg:justify-evenly"
        >
          <img
            src={cooperativeMask}
            variants={FADE_UP_ANIMATION_VARIANTS}
            alt="Masked Background with our Logo"
            className="h-full lg:w-1/2 w-[195px]"
          />
          {/* <SliderComponent /> */}

          <div className="max-w-3xl lg:w-1/2 lg:text-left text-center">
            <h2
              variants={FADE_UP_ANIMATION_VARIANTS}
              className="lg:text-4xl md:text-2xl text-xl font-bold leading-loose"
            >
              Empower Your{" "}
              <span className="text-fedacash-header ">Business</span> through{" "}
              <span className="text-fedacash-header ">Collaboration</span>
            </h2>
            <div className="flex flex-col gap-7 mt-7 text-fedacash-textColor md:text-lg text-xs font-medium">
              <motion.p
                className="leading-[165%] "
                variants={FADE_UP_ANIMATION_VARIANTS}
              >
                Join Our{" "}
                <span className="text-fedacash-header">
                  Cooperative Society
                </span>{" "}
                Today! Our Cooperative Program is designed to provide accessible
                financial services that cater to the unique needs of collective
                enterprises. Choose from our range of Cooperative Packages –
                OMA, IMARA, MWAMBA, AKO. Specify your contribution amount, set
                the frequency and duration, and make your payment.
              </motion.p>
              <motion.p
                className="leading-[165%] "
                variants={FADE_UP_ANIMATION_VARIANTS}
              >
                Through our platform, we offer businesses a dependable way to
                harness group resources for sustained growth. By paying your
                monthly cooperative fee, you unlock a variety of benefits and
                opportunities that will drive your business forward.
              </motion.p>
            </div>
          </div>
        </motion.section>
      </section>
      <section className="xl:px-2 container mx-auto lg:py-24 py-16">
        <div className="flex items-center justify-center mb-5">
          <motion.h3
            variants={FADE_UP_ANIMATION_VARIANTS}
            className="md:text-base text-xs text-fedacash-header font-semibold shadow-header p-4 rounded-[30px] bg-customGray"
          >
            OUR TARGET MARKET
          </motion.h3>
        </div>
        <motion.section
          initial="hidden"
          animate={isLoaninView ? "show" : "hide"}
          viewport={{ once: true }}
          variants={{
            hidden: {},
            show: {
              transition: {
                staggerChildren: 0.15,
              },
            },
          }}
          className="lg:px-20 px-4 py-8 mt-4"
        >
          <div className="flex gap-3 items-center justify-evenly bg-white rounded-[32px] shadow-section py-8">
            <motion.img
              variants={FADE_UP_ANIMATION_VARIANTS}
              src={loanRequest}
              alt="target Market"
              className="w-1/2"
            />
            <div className="flex flex-col gap-3 max-w-xl w-1/2">
              <motion.h3
                variants={FADE_UP_ANIMATION_VARIANTS}
                className="lg:text-4xl md:text-2xl text-sm font-semibold text-fedacash-header"
              >
                Loan Request
              </motion.h3>
              <motion.p
                ref={loanRef}
                className="lg:text-xl md:text-base text-[10px]"
                variants={FADE_UP_ANIMATION_VARIANTS}
              >
                Achieve your goals by accessing the funds you need. Request a
                loan quickly and conveniently, tailored to your business needs.
                Your success is our priority, and we're dedicated to supporting
                your journey, all from the convenience of your browser or phone.
              </motion.p>
            </div>
          </div>
        </motion.section>
        <motion.section
          initial="hidden"
          animate={isContributioninView ? "show" : "hide"}
          viewport={{ once: true }}
          variants={{
            hidden: {},
            show: {
              transition: {
                staggerChildren: 0.15,
              },
            },
          }}
          className="lg:px-20 px-4 py-8 mt-4"
        >
          <div className="flex gap-3 items-center justify-evenly bg-white rounded-[32px] shadow-section py-14">
            <motion.img
              src={transactionHistoryRreport}
              alt="target Market"
              variants={FADE_UP_ANIMATION_VARIANTS}
              className="w-1/2"
            />
            <div className="flex flex-col gap-3 max-w-xl w-1/2">
              <motion.h3
                variants={FADE_UP_ANIMATION_VARIANTS}
                className="lg:text-4xl md:text-2xl text-sm font-semibold text-fedacash-header"
              >
                Manage Contributions
              </motion.h3>
              <motion.p
                ref={contributionRef}
                className="lg:text-xl md:text-base text-[10px]"
                variants={FADE_UP_ANIMATION_VARIANTS}
              >
                Stay in control of your financial journey with ease. Gain
                insights into your past Loan Requests, payments, and Cooperative
                Contributions. Stay informed about your financial activity and
                make well-informed decisions for the future growth of your
                business.
              </motion.p>
            </div>
          </div>
        </motion.section>
      </section>
      <CTA />
    </section>
  );
};

export default CooperativeServices;
