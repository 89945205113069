import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";
import { usePaystackPayment } from "react-paystack";
import { useNavigate } from "react-router-dom";

///
import walletimage from "../../../Assets/dashboard/EmptyStatewallet.svg";
import { StatusDialog } from "../Shared/StatusDialog";
import { LoanRepayment } from "../../../Services/Dashboard/LoanRequestService";
import UserObject from "../../../DataObjects/UserObject";

export const RepayLoanButton = ({ loan_token, repayment_amount }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [deposit, setDeposit] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState({});
  const [isStatusOpen, setIsStatusOpen] = useState(false);
  function closeStatusModal() {
    setIsStatusOpen(false);
  }

  function openStatusModal() {
    setIsStatusOpen(true);
  }
  function toggleModal() {
    setIsOpen((prev) => !prev);
  }
  const paystackCharges =
    deposit > 1999 ? deposit * 0.015 + 100 : deposit * 0.015;
  const finalAmount = parseFloat(deposit) + parseFloat(paystackCharges);
  const config = {
    reference: new Date().getTime().toString(),
    email: UserObject.getUser().email,
    amount: parseInt(finalAmount * 100), //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey:
      process.env.REACT_APP_VERCEL_ENV !== "production"
        ? process.env.REACT_APP_TEST_KEY
        : process.env.REACT_APP_LIVE_KEY,
  };

  const onSuccess = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    // console.log(reference);
    LoanRepayment({
      reference: reference?.reference,
      amount: parseFloat(deposit),
      loan_token: loan_token,
    }).then((res) => {
      setMessage(res);
      // console.log(res);
      openStatusModal();
      toggleModal();
      navigate(".");
    });
  };

  // you can call this function anything
  const onPaystackClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    // console.log("closed");
  };
  const initializePayment = usePaystackPayment(config);
  return (
    <div>
      <button
        onClick={toggleModal}
        name="fund wallet"
        className="flex items-center h-12 py-2 px-5 space-x-2 bg-fedacash-header text-white rounded-lg  font-semibold text-xs md:text-sm  leading-[21px] "
      >
        <AiOutlinePlus />
        <span>Pay</span>
      </button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={toggleModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-fedacash-header bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full rounded-md max-w-xl transform overflow-hidden bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="flex gap-3 justify-evenly text-lg font-bold leading-6 text-[#475467]"
                  >
                    Repay your Loan
                    <button
                      type="button"
                      className=""
                      onClick={() => toggleModal()}
                    >
                      <AiOutlineClose />
                    </button>
                  </Dialog.Title>
                  <div className="flex flex-col items-center justify-center">
                    <img src={walletimage} alt="" />
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        if (
                          parseFloat(deposit) > parseFloat(repayment_amount)
                        ) {
                          setError("You can not pay more than you owe");
                          return;
                        } else {
                          initializePayment(onSuccess, onPaystackClose);
                        }
                      }}
                      className="py-4 space-y-3"
                    >
                      <div className="flexl gap-x-3">
                        <h3 className="text-lg">Repayment Amount:</h3>
                        <h3 className="text-lg font-semibold">
                          {repayment_amount}
                        </h3>
                      </div>
                      <div>
                        <label>Enter Amount</label>
                        <input
                          placeholder="Amount"
                          value={deposit}
                          required
                          type="number"
                          onChange={(e) => setDeposit(e.target.value)}
                          className="font-bold w-full text-[12px] leading-[18px] py-[11px] px-[19px] placeholder:text-[#b8c0cc] border-[1.5px] rounded-[8px] border-[#b8c0cc] focus:outline-none"
                        />
                      </div>
                      {error && <p className="text-sm text-red-700">{error}</p>}
                      <div className="mt-4">
                        <button className="text-white bg-fedacash-header text-center w-full py-2 md:py-3 md:px-2 rounded-md text-sm lg:textbase">
                          Proceed to payment
                        </button>
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <StatusDialog
        closeModal={closeStatusModal}
        isOpen={isStatusOpen}
        message={message}
      />
    </div>
  );
};
