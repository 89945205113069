import http from "../../Store/baseHttp";
import ResponseObject from "../../DataObjects/ResponseObject";

export const JoinPlan = async (data) => {
  try {
    let joinPlanResponse = await http.post("/cooperative/join/saveandgo", data);
    joinPlanResponse = ResponseObject.from(joinPlanResponse);

    // console.log('network test',joinPlanResponse);
    return joinPlanResponse
  } catch (error) {
    console.error(error);
  }
};


export const PlanlistRequestQuery = (request) => ({
  queryKey: ["saveandgo"],
  queryFn: async () => {
    const url = new URL(request.url);
    try {
      let planListResponse = await http.get(
        "/cooperative/paystack/my/saveandgo/transactions" + url.search
      );
      let subscriptions = await http.get(
        "/cooperative/my/saveandgo/subscriptions" + url.search
      );
      let walletBalance = await http.get(
        "/cooperative/my/saveandgo/wallet/total"
      );
      let LoanStatus = await http.get(
        "/cooperative/loan/application/status"
      );
      planListResponse = ResponseObject.from(planListResponse);
      subscriptions = ResponseObject.from(subscriptions);
      walletBalance = ResponseObject.from(walletBalance);
      LoanStatus = ResponseObject.from(LoanStatus);
      return { planListResponse, walletBalance, subscriptions, LoanStatus};
    } catch (error) {
      // console.error(error);
      return ResponseObject.from(error?.response);
    }
  },
});
export const Planlist =
  (queryClient) =>
  async ({request, params}) => {
    const query = PlanlistRequestQuery(request);
    // console.log("query fn", query);
    // ⬇️ return data or fetch it
    return (
      queryClient.getQueryData(query.queryKey) ??
      (await queryClient.fetchQuery(query))
    );
  };
