import { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { usePaystackPayment } from "react-paystack";
import { useNavigate } from "react-router-dom";

///
import { StatusDialog } from "../Shared/StatusDialog";
import UserObject from "../../../DataObjects/UserObject";
import { JoinPlan } from "../../../Services/Dashboard/SaveandGo";

export const RenewPlan = ({ item }) => {
  const navigate = useNavigate();
  const [message, setMessage] = useState({});
  const [dueDate, setDueDate] = useState(false);
  const [isStatusOpen, setIsStatusOpen] = useState(false);
  function closeStatusModal() {
    setIsStatusOpen(false);
  }

  function openStatusModal() {
    setIsStatusOpen(true);
  }
  const checkDate = () => {
    // Get the numeric values of the startDate and endDate
    const currentDate = new Date();
    const endDate = new Date(item.end_date);
    if (currentDate > endDate) {
      // Return true if the current date is within the valid range
      setDueDate(false);
    }
    // Return false otherwise
    setDueDate(true);
  };
  //   console.log(checkDate());
  const paystackCharges =
    item?.amount_to_contribute > 1999
      ? item?.amount_to_contribute * 0.015 + 100
      : item?.amount_to_contribute * 0.015;
  const finalAmount =
    parseFloat(item?.amount_to_contribute) + parseFloat(paystackCharges);
  const config = {
    reference: new Date().getTime().toString(),
    email: UserObject.getUser().email,
    amount: parseInt(finalAmount * 100), //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey:
      process.env.REACT_APP_VERCEL_ENV !== "production"
        ? process.env.REACT_APP_TEST_KEY
        : process.env.REACT_APP_LIVE_KEY,
  };

  const onSuccess = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    JoinPlan({
      frequency_selection: item.frequency_selection,
      frequency_duration: item.frequency_duration,
      description: item.description,
      amount: item?.amount_to_contribute,
      reference: reference.reference,
    }).then((res) => {
      setMessage(res);
      openStatusModal();
    });
  };

  // you can call this function anything
  const onPaystackClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    // console.log("closed");
  };
  const initializePayment = usePaystackPayment(config);
  useEffect(() => {
    checkDate();
  }, []);
  return (
    <div>
      <button
        onClick={() => initializePayment(onSuccess, onPaystackClose)}
        disabled={dueDate}
        name="Renew Plan"
        className={`${
          dueDate ? "cursor-not-allowed" : "cursor-pointer"
        } flex items-center h-12 py-2 px-5 space-x-2 bg-fedacash-header text-white rounded-lg  font-semibold text-xs md:text-sm  leading-[21px] `}
      >
        <AiOutlinePlus />
        <span>Pay</span>
      </button>
      <StatusDialog
        closeModal={closeStatusModal}
        isOpen={isStatusOpen}
        message={message}
      />
    </div>
  );
};
