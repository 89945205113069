import React, { useRef } from "react";

import { motion, useInView } from "framer-motion";
//file imports
import aboutMask from "../../../Assets/images/about-mask.png";
import worldMap from "../../../Assets/images/world-map.png";
import { LightBulb } from "../../../Components/ui/icon";
import CTA from "../../../Components/LandingPage/cta";
import { FADE_UP_ANIMATION_VARIANTS } from "../../../utils/framer-variants";

const About = () => {
  const tractionRef = useRef(null);
  const isInView = useInView(tractionRef, { once: true });
  return (
    <motion.section>
      <motion.section
        initial="hidden"
        animate={"show"}
        viewport={{ once: true }}
        variants={{
          hidden: {},
          show: {
            transition: {
              staggerChildren: 0.15,
            },
          },
        }}
        className="flex-col items-center flex xl:px-2 px-4 container mx-auto"
      >
        <div className="py-12">
          <motion.div
            variants={FADE_UP_ANIMATION_VARIANTS}
            className="text-center lg:text-4xl md:text-2xl text-xl font-bold leading-10"
          >
            We are a Team committed to
          </motion.div>
          <motion.div
            variants={FADE_UP_ANIMATION_VARIANTS}
            className="h-12 text-center lg:text-4xl md:text-2xl text-fedacash-header text-xl font-bold leading-10"
          >
            empowering businesses in Africa{" "}
          </motion.div>
          <div className="lg:w-72 w-52 h-3.5 origin-top-left rotate-[-1.33deg] bg-fedacash-header bg-opacity-60"></div>
        </div>
        <div className="max-w-2xl text-center  lg:text-lg md:text-base text-xs font-medium">
          <motion.p variants={FADE_UP_ANIMATION_VARIANTS}>
            At FedaCash, we are a dedicated team working tirelessly to empower
            businesses across Africa. We firmly believe that accessible and
            affordable credit solutions are the driving force behind business
            growth. As such, our mission is to provide businesses with the
            credit availability they need to not just survive, but thrive.
          </motion.p>
        </div>
        <img src={worldMap} alt="" />
      </motion.section>
      {/* <Traction /> */}
      <motion.section
        className="xl:px-2 px-4 container mx-auto lg:py-24"
        initial="hidden"
        animate={isInView ? "show" : "hide"}
        viewport={{ once: true }}
        variants={{
          hidden: {},
          show: {
            transition: {
              staggerChildren: 0.15,
            },
          },
        }}
        ref={tractionRef}
      >
        <section className="flex flex-col-reverse lg:flex-row xl:gap-3 items-center justify-center lg:justify-evenly">
          <motion.img
            src={aboutMask}
            variants={FADE_UP_ANIMATION_VARIANTS}
            alt="Masked Background with our Logo"
            className="lg:w-1/2 w-[200px] h-full"
          />
          {/* <SliderComponent /> */}

          <div className="lg:w-1/2 max-w-3xl">
            <motion.h2
              variants={FADE_UP_ANIMATION_VARIANTS}
              className="text-fedacash-header md:text-[2.5rem] font-bold  text-center lg:text-left"
            >
              Who we are
            </motion.h2>
            <div className="flex flex-col text-center lg:text-left gap-7 mt-7 text-fedacash-textColor lg:text-lg md:text-base text-xs font-medium">
              <motion.p variants={FADE_UP_ANIMATION_VARIANTS}>
                Comprising a team of passionate young professionals, we bring a
                wealth of experience from our work as strategy consultants and
                our non-profit initiatives aimed at bolstering the capabilities
                of micro, small, and medium-sized enterprises (MSMEs) across the
                continent.
              </motion.p>
              <motion.p variants={FADE_UP_ANIMATION_VARIANTS} ref={tractionRef}>
                Our collective journey has illuminated a pressing issue - the
                challenge of working capital inadequacy. We witness how MSMEs
                often lose potential clients due to funds being locked in
                previous transactions, preventing them from seizing growth
                opportunities. This is where FedaCash steps in.
              </motion.p>
            </div>

            <div className="flex xl:max-w-2xl xl:flex-row gap-x-6 items-center justify-between mt-10">
              <LightBulb />
              <div>
                <motion.h3
                  className="font-bold lg:text-xl md:text-lg"
                  variants={FADE_UP_ANIMATION_VARIANTS}
                >
                  Our Solution
                </motion.h3>
                <motion.p
                  className="text-[#262B40] md:text-base text-xs"
                  variants={FADE_UP_ANIMATION_VARIANTS}
                >
                  We harness the power of digital technology to create a
                  cohesive support ecosystem that addresses the core challenges
                  faced by MSMEs. From cash flow management to unlocking new
                  avenues of growth, FedaCash is here to catalyze the success of
                  businesses and drive economic transformation in Africa.
                </motion.p>
              </div>
            </div>
          </div>
        </section>
      </motion.section>
      <section className="px-6 mt-8">
        <CTA />
      </section>
    </motion.section>
  );
};

export default About;
