import { getReasonPhrase } from "http-status-codes";
import { useParams } from "react-router-dom";
import errorimage from "../../Assets/images/404.png";
import { Link } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
const Error404 = () => {
  const params = useParams();
  let code = parseInt(params?.id?.replace(/\D/g, "")) ?? 404;
  if (!code) {
    code = 404;
  }
  return (
    <section className=" error-bg min-h-screen">
      <div className="py-12 mx-12">
        <Link to={`/`}>
          <p className="flex items-center gap-3 text-fedacash-header text-xl font-semibold">
            <AiOutlineArrowLeft/>
            Back to Home
          </p>
        </Link>
      </div>

      <div className='mx-12 flex'>
            <div className='flex items-center justify-center divide-x divide-purple-800 border-transparent bg-gradient-to-r from-pink-500 to-purple-500 bg-clip-text lg:text-3xl md:text-2xl text-xl font-bold text-transparent'>
                <span className=''>Error {code}</span>
                <span className='ml-3 px-2'>{getReasonPhrase(code)}</span>
            </div>
        </div>            
    </section>
  );
};

export default Error404;
