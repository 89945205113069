import { motion, useInView } from "framer-motion";
import { useRef } from "react";
///file iomport
import { FADE_UP_ANIMATION_VARIANTS } from "../../../../utils/framer-variants";
import invoiceMask from "../../../../Assets/images/invoice-mask.png";
import discountRequest from "../../../../Assets/images/discount-request.png";
import transactionHistoryRreport from "../../../../Assets/images/transaction-history-report.png";
import CTA from "../../../../Components/LandingPage/cta";

const InvoiceServices = () => {
  const discountRef = useRef(null);
  const isDiscountInView = useInView(discountRef, { once: true });
  const historyRef = useRef(null);
  const isHistoryInView = useInView(historyRef, { once: true });
  return (
    <section>
      <section className="xl:px-12 px-4 container mx-auto lg:py-8 py-10">
        <motion.section
          initial="hidden"
          animate={"show"}
          viewport={{ once: true }}
          variants={{
            hidden: {},
            show: {
              transition: {
                staggerChildren: 0.15,
              },
            },
          }}
          className="flex flex-col-reverse  lg:flex-row gap-y-3 xl:gap-3 justify-center items-center lg:justify-evenly"
        >
          <motion.img
            src={invoiceMask}
            alt="Masked Background with our Logo"
            className="h-full lg:w-2/5 w-[195px]"
          />
          {/* <SliderComponent /> */}

          <div className="max-w-3xl lg:w-3/5 lg:text-left text-center">
            <div className="lg:text-[2.5rem] md:text-2xl text-xl font-bold leading-loose py-2 text-fedacash-header">
              <h2 className="">Unlock Your Cash Flow Potential</h2>
            </div>

            <div className="flex flex-col gap-7 md:mt-7 text-fedacash-textColor md:text-lg text-xs font-medium">
              <motion.p
                variants={FADE_UP_ANIMATION_VARIANTS}
                className="leading-[165%]"
              >
                Running a business comes with its challenges, and{" "}
                <span className="text-fedacash-header">managing cash flow</span>{" "}
                shouldn&apos;t be one of them. FedaCash&apos;s Invoice
                Discounting empowers you to shift your focus from financial
                constraints to business growth.
              </motion.p>
              <motion.p
                variants={FADE_UP_ANIMATION_VARIANTS}
                className="leading-[165%]"
              >
                Here&apos;s how it works: We convert your unpaid invoices into
                readily available working capital. This means you can seize new
                opportunities, cover operational expenses, and drive success –
                all while we ensure your cash flow remains steady.
              </motion.p>
              <motion.p
                variants={FADE_UP_ANIMATION_VARIANTS}
                className="leading-[165%]"
              >
                No more waiting for delayed payments. With Invoice Discounting,
                you can harness the value of your outstanding invoices and
                propel your business forward. Let us handle the cash flow, so
                you can concentrate on what truly matters – running and
                expanding your business.
              </motion.p>
            </div>
          </div>
        </motion.section>
      </section>
      <section className="xl:px-2 px-4 container mx-auto lg:py-7 py-16">
        <div className="flex items-center justify-center mb-5">
          <h3 className="md:text-base text-xs text-fedacash-header font-semibold shadow-header p-4 rounded-[30px] bg-customGray">
            OUR TARGET MARKET
          </h3>
        </div>
        <motion.section
          initial="hidden"
          animate={isDiscountInView ? "show" : "hide"}
          viewport={{ once: true }}
          variants={{
            hidden: {},
            show: {
              transition: {
                staggerChildren: 0.15,
              },
            },
          }}
          className="lg:px-20 px-4 py-8 mt-4 bg-white rounded-[32px] shadow-section "
        >
          <div className="flex flex-col lg:flex-row gap-3 items-center justify-evenly py-8">
            <motion.img
              variants={FADE_UP_ANIMATION_VARIANTS}
              src={discountRequest}
              alt="target Market"
              className="lg:w-1/2"
            />
            <div className="flex flex-col gap-3 max-w-xl lg:w-1/2">
              <h3 className="lg:text-4xl md:text-2xl text-sm font-semibold text-fedacash-header">
                Discount Requests{" "}
              </h3>
              <motion.p
                variants={FADE_UP_ANIMATION_VARIANTS}
                className="lg:text-xl md:text-base text-[10px]"
                ref={discountRef}
              >
                Say goodbye to waiting for payment terms. Our Discount Requests
                feature lets you quickly submit your invoice discounting
                requests. Receive swift approvals and access the funds you need
                to drive your business forward – all without the hassle of
                delayed payments.
              </motion.p>
            </div>
          </div>
        </motion.section>
        <motion.section
          initial="hidden"
          animate={isHistoryInView ? "show" : "hide"}
          viewport={{ once: true }}
          variants={{
            hidden: {},
            show: {
              transition: {
                staggerChildren: 0.15,
              },
            },
          }}
          className="lg:px-20 px-6 py-8 mt-4 bg-white rounded-[32px] shadow-section"
        >
          <div className="flex lg:flex-row flex-col gap-3 items-center justify-evenly py-14">
            <motion.img
              variants={FADE_UP_ANIMATION_VARIANTS}
              src={transactionHistoryRreport}
              alt="target Market"
              className="lg:w-1/2"
            />
            <div className="flex flex-col gap-3 max-w-xl lg:w-1/2">
              <h3 className="lg:text-4xl md:text-2xl text-sm font-semibold text-fedacash-header">
                Transaction History{" "}
              </h3>
              <motion.p
                variants={FADE_UP_ANIMATION_VARIANTS}
                ref={historyRef}
                className="lg:text-xl md:text-base text-[10px]"
              >
                Gain valuable insights into your financial journey with our
                comprehensive Transaction History. Track your past invoices,
                payments, and discount requests effortlessly. Stay informed and
                make well-informed decisions for the future growth of your
                business.
              </motion.p>
            </div>
          </div>
        </motion.section>
      </section>
      <CTA />
    </section>
  );
};

export default InvoiceServices;
