import { Link, useFetcher, useNavigate } from "react-router-dom";
import Panel from "../../../Components/Dashboard/Panel";
import { useContext, useEffect, useState } from "react";
import FileUpload from "../../../Components/Forms/FileUpload";
import Input from "../../../Components/Forms/Input";
import TextArea from "../../../Components/Forms/TextArea";
import ToastContext from "../../../Routes/ToastContext";
import Submit from "../../../Components/Forms/Submit";
import { MdOutlineDriveFolderUpload } from "react-icons/md";
import { BsX } from "react-icons/bs";
import useForm from "../../../Hooks/useForm";
import TransacactionCompany from "./TransacactionComapny";
import moment from "moment";
import UserObject from "../../../DataObjects/UserObject";

const TransactionCreate = () => {
  const { data, state, submit } = useFetcher();
  const [tab, setTab] = useState("invoice_details");
  const { flashToast } = useContext(ToastContext);
  const navigate = useNavigate();

  const [invoice, setInvoice] = useState([]);
  const [apires, setApires] = useState([]);
  const [loading, setLoading] = useState(false);

  const [mandate, setMandate] = useState([]);
  const man = { file: mandate, setFile: setMandate };
  const [recentInvoice, setRecentInvoice] = useState([]);
  const reIn = { file: recentInvoice, setFile: setRecentInvoice };
  const [bankStatement, setBankStatement] = useState([]);
  const bkSt = { file: bankStatement, setFile: setBankStatement };

  const initialState = {
    invoice: "",
    invoice_number: "",
    company: "",
    amount: "0.00",
    due_date: "",
    service_category: "",
    service_rendered: "",
  };
  const [form, setForm] = useForm(initialState);

  function ApiResponseHandler(apires) {
    if (apires) {
      if (apires?.success) {
        flashToast(apires?.success, apires?.message);
        navigate("/dashboard/transaction");
      } else {
        flashToast(apires?.success, apires?.message);
        setTab("invoice_details");
      }
    }
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + UserObject.getUser().token);
    form.mandate_letter = man.file[0];
    form.bank_statement = bkSt.file[0];
    form.recent_invoices = reIn.file;
    form.due_date = moment(form.due_date).format("DD-MM-YYYY");

    const transData = new FormData();
    transData.append("invoice_number", form.invoice_number);
    transData.append("company", form.company);
    transData.append("due_date", form.due_date);
    transData.append(
      "amount",
      form.amount.replace("₦", "").replaceAll(",", "")
    );
    transData.append("service_category", form.service_category);
    transData.append("services_rendered", form.services_rendered);
    transData.append("mandate_letter", form.mandate_letter);
    transData.append("bank_statement", form.bank_statement);
    transData.append("invoice", invoice);
    form.recent_invoices.map((v, i) =>
      transData.append("recent_invoices[" + i + "]", v)
    );

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: transData,
      redirect: "follow",
    };
    try {
      let response = await fetch(
        "https://api.fedacash.com/api/request/create",
        requestOptions
      );
      let data = await response.json();
      setApires(data);
      ApiResponseHandler(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
    // console.log(response);
  };

  return (
    <div>
      <form method="post" action="" onSubmit={handleSubmit}>
        <Panel>
          <div className="flex items-center justify-center space-x-3 px-6 py-6">
            <button
              type="button"
              onClick={() => {
                setTab("invoice_details");
              }}
              className="flex items-center space-x-3"
            >
              <div
                data-active={tab === "invoice_details"}
                className="flex items-center rounded-full text-white data-[active=true]:bg-purple-700 justify-center w-10 h-10 bg-gray-500"
              >
                1
              </div>
              <p className="whitespace-nowrap">Invoice Details</p>
            </button>
            <hr className="w-full" />
            <button
              type="button"
              onClick={() => {
                setTab("kyc");
              }}
              className="flex items-center space-x-3"
            >
              <div
                data-active={tab === "kyc"}
                className="flex items-center data-[active=true]:bg-purple-700 rounded-full text-white justify-center w-10 h-10 bg-gray-500"
              >
                2
              </div>
              <p className="whitespace-nowrap">KYC</p>
            </button>
            <hr className="w-full" />
            <button
              type="button"
              onClick={() => {
                setTab("review_request");
              }}
              className="flex items-center space-x-3"
            >
              <div
                data-active={tab === "review_request"}
                className="flex items-center data-[active=true]:bg-purple-700 rounded-full text-white justify-center w-10 h-10 bg-gray-500"
              >
                3
              </div>
              <p className="whitespace-nowrap">Review Request</p>
            </button>
          </div>
        </Panel>
        <div className="mt-5"></div>
        <Panel>
          <div className="p-6">
            <InvoiceDetails
              tab={tab}
              data={data}
              setTab={setTab}
              setForm={setForm}
              invoice={invoice}
              setInvoice={setInvoice}
            />
            <KYC
              tab={tab}
              data={data}
              setTab={setTab}
              mandate={man}
              recentInvoice={reIn}
              bankStatement={bkSt}
            />
            <ReviewRequest
              tab={tab}
              data={data}
              setTab={setTab}
              state={state}
              form={form}
              mandate={man}
              recentInvoice={reIn}
              bankStatement={bkSt}
              apires={apires}
              loading={loading}
            />
          </div>
        </Panel>
      </form>
    </div>
  );
};

const InvoiceDetails = ({
  tab,
  data,
  setTab,
  setForm,
  invoice,
  setInvoice,
}) => {
  return (
    <div className={`${tab === "invoice_details" ? "" : "hidden"}`}>
      <h2 className="font-[600] text-[22px] leading-[21px] mb-5">
        New Request
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
        <div className="col-span-full flex items-center justify-center">
          <div className="max-w-sm">
            <FileUpload
              name="invoice"
              invoice={invoice}
              setInvoice={setInvoice}
            />
            <Input type="hidden" error={data?.error?.invoice} />
          </div>
        </div>
        <Input
          label="Invoice Number *"
          name="invoice_number"
          placeholder="Enter your invoice number"
          error={data?.errors?.invoice_number}
          onChange={setForm}
        />
        <div>
          <h1 className="font-[700] text-[16px] leading-[24px]">Company *</h1>
          <TransacactionCompany
            name="company"
            error={data?.errors?.amount}
            onChange={setForm}
          />
        </div>
        <Input
          label="Invoice Amount *"
          name="amount"
          placeholder="Enter invoice amount"
          error={data?.errors?.amount}
          onChange={setForm}
          options={{
            numeral: true,
            prefix: "₦",
            rawValueTrimPrefix: true,
            numeralDecimalScale: 2,
            swapHiddenInput: true,
          }}
        />
        <Input
          label="Due Date *"
          name="due_date"
          type="date"
          placeholder="Select Due Date"
          error={data?.errors?.due_date}
          onChange={setForm}
          normalize={true}
        />
        <Input
          label="Products/Services Offered *"
          name="service_category"
          type="text"
          placeholder="Enter services you rendered"
          error={data?.errors?.service_category}
          onChange={setForm}
          normalize={true}
        />
        <TextArea
          label="Products/Services Description *"
          name="services_rendered"
          placeholder="Enter the Products/Services you offered"
          onChange={setForm}
        />
        <Input type="hidden" error={data?.errors?.services_rendered} />
      </div>
      <div className="flex items-center justify-end space-x-3">
        <div className="col-span-full flex items-center justify-end space-x-3">
          <div className="w-[150px]">
            <Link
              to="/dashboard"
              className="py-3 w-full px-4 flex items-center justify-center rounded-md tracking-wider text-base min-h-[59px] font-bold border-2 border-[#590DA4] text-[#590DA4] hover:text-white disabled:opacity-50 hover:bg-purple-900 text-center"
            >
              <span>Cancel</span>
            </Link>
          </div>
          <div className="w-[150px]">
            <Submit
              type="button"
              onClick={() => {
                setTab("kyc");
              }}
            >
              Continue
            </Submit>
          </div>
        </div>
      </div>
    </div>
  );
};

const KYC = ({ tab, data, setTab, bankStatement, recentInvoice, mandate }) => {
  return (
    <div
      className={`${
        tab === "kyc" ? "" : "hidden"
      } grid grid-cols-1 md:grid-cols-2 gap-10`}
    >
      <FileInput
        type="file"
        label="Bank Statement *"
        name="bank_statement"
        placeholder="Upload your up-to-date Bank Statement"
        note="Note: File Must not exceed 10Mb"
        def={bankStatement}
      />
      <FileInput
        type="file"
        label="Mandate Letter *"
        name="mandate_letter"
        placeholder="Upload the Project Mandate Letter"
        error={data?.errors?.mandate_letter}
        note="Note: File Must not exceed 10Mb"
        def={mandate}
      />
      <FileInput
        type="file"
        label="Recent Invoices *"
        name="recent_invoice"
        placeholder="Upload Recent Invoices to this customer"
        error={data?.errors?.recent_invoice}
        note="Note: At least 3 invoice"
        multiple={true}
        def={recentInvoice}
      />
      <div className="col-span-full flex items-center justify-end space-x-3">
        <div className="w-[150px]">
          <button
            type="button"
            onClick={() => {
              setTab("invoice_details");
            }}
            className="py-3 w-full px-4 rounded-md tracking-wider text-base min-h-[59px] font-bold border-2 border-[#590DA4] text-[#590DA4] hover:text-white disabled:opacity-50 hover:bg-purple-900"
          >
            Back
          </button>
        </div>
        <div className="w-[150px]">
          <Submit
            type="button"
            onClick={() => {
              setTab("review_request");
            }}
          >
            Continue
          </Submit>
        </div>
      </div>
    </div>
  );
};

const FileInput = ({
  label,
  placeholder,
  note,
  def,
  multiple = false,
  ...props
}) => {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    def.setFile(files);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  return (
    <div className="inline-flex w-full items-start justify-start flex-col">
      <span className=" capitalize text-gray-700 text-base w-full text-[18px] font-[700]">
        {label}
      </span>
      <label className="px-4 mt-2 form-input focus:outline-none text-gray-800s tracking-wide border bg-transparent focus:border-purple-800 py-4 text-base  font-semibold placeholder:capitalize w-full">
        <input
          type="file"
          className="hidden"
          onChange={(e) => {
            setFiles((v) =>
              multiple ? [...v, ...e.target.files] : [...e.target.files]
            );
          }}
          {...props}
        />
        <div className="flex items-center justify-between">
          <p className="text-gray-400">{placeholder}</p>
          <MdOutlineDriveFolderUpload className="w-6 h-6" />
        </div>
      </label>
      <p className="mt-[7.8px] font-[700] text-[14px] leading-[21px] text-[#A0ABBB]">
        {note}
      </p>
      <div className="mt-2 flex flex-col items-start justify-start w-full space-y-2">
        {files.map((v, i) => (
          <div
            key={i}
            className="flex flex-col items-start justify-start w-full"
          >
            <div className="flex items-center justify-between w-full">
              <p>{v.name}</p>
              <button className="focus:outline-none">
                <BsX className="w-4 h-4" />
              </button>
            </div>
            <div className="h-2 w-full rounded-full bg-green-600"></div>
          </div>
        ))}
      </div>
    </div>
  );
};

const ReviewRequest = ({
  tab,
  setTab,
  data,
  state,
  form,
  bankStatement,
  mandate,
  recentInvoice,
  apires,
  loading,
}) => {
  //   const [showModal, setShowModal] = useState(false);

  //   const handleOpenModal = () => setShowModal(true);
  //   const handleCloseModal = () => setShowModal(false);
  return (
    <div className={`${tab === "review_request" ? "" : "hidden"}`}>
      {/* <>
        <button onClick={handleOpenModal}>Show Errors</button>
        {showModal && (
          <ErrorList errors={apires?.errors} onClose={handleCloseModal} />
        )}
      </>{" "} */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
        <div className="flex flex-col items-center justify-start">
          <h3 className="text-[#4b5768] font-[400] text-[16px] leading-[24px] ">
            Invoice Number
          </h3>
          <p className="text-[#0d0f11] font-[700] text-[20px] leading-[30px] ">
            {form.invoice_number}
          </p>
        </div>
        <div className="flex flex-col items-center justify-start">
          <h3 className="text-[#4b5768] font-[400] text-[16px] leading-[24px] ">
            Company
          </h3>
          <p className="text-[#0d0f11] font-[700] text-[20px] leading-[30px] ">
            {form.company}
          </p>
        </div>
        <div className="flex flex-col items-center justify-start">
          <h3 className="text-[#4b5768] font-[400] text-[16px] leading-[24px] ">
            Amount
          </h3>
          <p className="text-[#0d0f11] font-[700] text-[20px] leading-[30px] ">
            {form.amount}
          </p>
        </div>
        <div className="flex flex-col items-center justify-start">
          <h3 className="text-[#4b5768] font-[400] text-[16px] leading-[24px] ">
            Due date
          </h3>
          <p className="text-[#0d0f11] font-[700] text-[20px] leading-[30px] ">
            {form.due_date}
          </p>
        </div>
        <div className="flex flex-col items-center justify-start">
          <h3 className="text-[#4b5768] font-[400] text-[16px] leading-[24px] ">
            Service Category
          </h3>
          <p className="text-[#0d0f11] font-[700] text-[20px] leading-[30px] ">
            {form.service_category}
          </p>
        </div>
        <div className="flex flex-col items-center justify-start">
          <h3 className="text-[#4b5768] font-[400] text-[16px] leading-[24px] ">
            Service Description
          </h3>
          <p className="text-[#0d0f11] font-[700] text-[20px] leading-[30px] ">
            {form.services_rendered}
          </p>
        </div>
        <div className="flex flex-col items-center justify-start">
          <h3 className="text-[#4b5768] font-[400] text-[16px] leading-[24px] ">
            Bank Statement
          </h3>
          <div className="mt-2 flex flex-col items-start justify-start w-full space-y-2">
            {bankStatement.file.map((v, i) => (
              <div
                key={i}
                className="flex flex-col items-start justify-start w-full"
              >
                <div className="flex items-center justify-between w-full">
                  <p>{v.name}</p>
                  <button className="focus:outline-none">
                    <BsX className="w-4 h-4" />
                  </button>
                </div>
                <div className="h-2 w-full rounded-full bg-green-600"></div>
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col items-center justify-start">
          <h3 className="text-[#4b5768] font-[400] text-[16px] leading-[24px] ">
            Recent Invoice
          </h3>
          <div className="mt-2 flex flex-col items-start justify-start w-full space-y-2">
            {recentInvoice.file.map((v, i) => (
              <div
                key={i}
                className="flex flex-col items-start justify-start w-full"
              >
                <div className="flex items-center justify-between w-full">
                  <p>{v.name}</p>
                  <button className="focus:outline-none">
                    <BsX className="w-4 h-4" />
                  </button>
                </div>
                <div className="h-2 w-full rounded-full bg-green-600"></div>
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col items-center justify-start">
          <h3 className="text-[#4b5768] font-[400] text-[16px] leading-[24px] ">
            Mandate Letter
          </h3>
          <div className="mt-2 flex flex-col items-start justify-start w-full space-y-2">
            {mandate.file.map((v, i) => (
              <div
                key={i}
                className="flex flex-col items-start justify-start w-full"
              >
                <div className="flex items-center justify-between w-full">
                  <p>{v.name}</p>
                  <button className="focus:outline-none">
                    <BsX className="w-4 h-4" />
                  </button>
                </div>
                <div className="h-2 w-full rounded-full bg-green-600"></div>
              </div>
            ))}
          </div>
        </div>
        <div className="col-span-full flex items-center justify-end space-x-3">
          <div className="w-[150px]">
            <button
              type="button"
              onClick={() => {
                setTab("kyc");
              }}
              className="py-3 w-full px-4 rounded-md tracking-wider text-base min-h-[59px] font-bold border-2 border-[#590DA4] text-[#590DA4] hover:text-white disabled:opacity-50 hover:bg-purple-900"
            >
              Back
            </button>
          </div>
          <div className="w-[150px]">
            <Submit isLoading={loading}>Submit</Submit>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionCreate;

function ErrorList({ errors, onClose }) {
  if (!errors) {
    return null;
  }
  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <ul className="px-3 text-red-600 py-4 text-sm flex flex-col items-center">
          {Object.values(errors)
            .flat()
            .map((error, index) => (
              <li key={index}>{error}</li>
            ))}
        </ul>
      </div>
    </div>
  );
}
