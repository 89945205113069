export const faq = [
  {
    question: "How can FedaCash benefit my business?",
    answer:
      "FedaCash offers flexibility in accessing your cash – no more waiting for 30 days or longer. We also provide Free Invoice Generation and a Cooperative Society program.",
  },
  {
    question: "How quickly can I access my funds?",
    answer:
      "Your cash will be available within 48 hours or even sooner. We understand the urgency of your business needs.",
  },
  {
    question: "What services are available?",
    answer:
      "We provide free Generate Invoice functionality, as well as services for Cooperatives and Invoice Discounting.",
  },
  {
    question: "How can I join the Cooperative Society?",
    answer:
      "Joining is simple! Choose from our Cooperative Packages (OMA, IMARA, MWAMBA, AKO), select your contribution amount and frequency, and make your payment – it's as easy as ABC.",
  },
  {
    question: "How do I start using the Invoice Discounting feature?",
    answer:
      "Getting started is quick and straightforward. Provide us with your KYC Documents, and once verified, you can start using the feature right away.",
  },
  {
    question:
      "How long do I need to be a FedaCash member before I can access loans?",
    answer:
      "You can access your first loan just three months after you start saving in our cooperative scheme.",
  },
];
