import React, { useMemo, useState } from "react";
import Panel from "../../../Components/Dashboard/Panel";
import { Legend, Pie, PieChart, ResponsiveContainer } from "recharts";
import Submit from "../../../Components/Forms/Submit";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { formatNaira } from "../../../Components/Utils/FormatMoney";
import moment from "moment";
import { RepayLoanButton } from "../../../Components/Dashboard/Loan/RepayLoanButton";
import { useQuery } from "@tanstack/react-query";
import { loanRequestQuery } from "../../../Services/Dashboard/LoanRequestService";


function transformData(inputObject) {
  return [
      {
          name: "Amount Collected",
          amount: parseInt(inputObject.amount),
          fill: "#816999"
      },
      {
          name: "Amount Paid",
          amount: parseInt(inputObject.instalment_repayment),
          fill: "#590DA4"
      }
  ];
}

const Repayment = () => {
  const [loading, setLoading] = useState(false);
  // const data = useLoaderData()
  const params = useParams()

  // const { data } = useQuery();
  const { data } = useQuery(loanRequestQuery(params.id))
  // console.log('pagedata',data)
  const loanrequest = data?.data;

  const transformedData = useMemo(() => transformData(loanrequest), [loanrequest]);

  const navigate = useNavigate();
  return (
    <section>
      <Panel>
        <div>
          <h3 className="text-sm font-bold lg:px-4  leading-tight">
           {loanrequest?.description}
          </h3>
        </div>
        <div className="my-4 md:my-8">
          <PieChart width={330} height={350}>
            <Pie
              data={transformedData}
              dataKey="amount"
              nameKey="name"
              cx="50%"
              cy="50%"
              innerRadius={60}
              outerRadius={100}
              label
            />
            <Legend verticalAlign="bottom" height={36} />
          </PieChart>
        </div>
        <div className="inline-flex flex-col items-start justify-start gap-2.5 rounded border border-gray-200 bg-white px-4 py-6 text-sm font-medium leading-none text-gray-500">
            <div className="inline-flex items-center justify-center gap-2.5">
              <div className="flex items-center justify-start gap-4">
                <div className="inline-flex flex-col items-start justify-start">
                  <div className="text-violet-900 text-lg font-bold leading-tight">
                    Loan Repayment
                  </div>
                </div>
              </div>
            </div>
            <div className="inline-flex items-start justify-start gap-2.5">
              <div className="w-56  md:w-80">
                Requested Amount
              </div>
              <div className="w-36 ">
                {formatNaira(loanrequest?.amount)}
              </div>
            </div>
            <div className="inline-flex items-start justify-start gap-2.5">
              <div className="w-56  md:w-80">
               Approved Amount
              </div>
              <div className="w-36 ">
                {formatNaira(loanrequest?.approved_amount)}
              </div>
            </div>
            <div className="inline-flex items-start justify-start gap-2.5">
              <div className="w-56  md:w-80">
                Total Repayment Amount
              </div>
              <div className="w-36 ">
                {formatNaira(loanrequest?.repayment_amount)}
              </div>
            </div>
            <div className="inline-flex items-start justify-start gap-2.5">
              <div className="w-56  md:w-80">
                Amount Paid
              </div>
              <div className="w-36 ">
                {formatNaira(loanrequest?.instalment_repayment)}
              </div>
            </div>
            {/* <div className="inline-flex items-start justify-start gap-2.5">
              <div className="w-56  md:w-80">
                Amount Owed
              </div>
              <div className="w-36 ">
                15,000
              </div>
            </div> */}
            <div className="inline-flex items-start justify-start gap-2.5">
              <div className="w-56  md:w-80">
                Interest Rate
              </div>
              <div className="w-36 ">
                {loanrequest?.interest_given ?? ""}%
              </div>
            </div>
            <div className="inline-flex items-start justify-start gap-2.5">
              <div className="w-56  md:w-80">
                Repayment Date
              </div>
              <div className="w-36 ">
                {moment(loanrequest?.repayment_date).format(
                  "dddd, Do MMMM YYYY:h:mmA"
                )}
              </div>
            </div>
            <div className="inline-flex items-start justify-start gap-2.5">
              <div className="w-56  md:w-80">
                Late payment fees
              </div>
              <div className="w-36 ">
               -
              </div>
            </div>
            {/* <div className="inline-flex items-start justify-start gap-2.5">
              <div className="w-56  md:w-80">
                Grace period
              </div>
              <div className="w-36 ">
                2 weeks
              </div>
            </div> */}
          </div>
        <div className="my-4 md:w-1/3 flex items-end justify-end gap-x-4">
          <button
            onClick={()=>navigate(-1)}
            className="text-center w-full py-2 md:py-3 md:px-2 rounded-md text-sm lg:text-base disabled:opacity-50 border border-gray-300"
          >
            <span>back</span>
          </button>
          <RepayLoanButton loan_token={loanrequest?.loan_token} repayment_amount={loanrequest?.repayment_amount}/>
        </div>
      </Panel>
    </section>
  );
};

export default Repayment;


