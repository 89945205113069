import Logo from "../../../Assets/logo/Logo.png";
import Input from "../../../Components/Forms/Input";
import Submit from "../../../Components/Forms/Submit";
import { Link, useFetcher, useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import AuthContext from "../../../Routes/AuthContext";
import ProtectedRoute from "../../../Routes/ProtectedRoute";
import Categories from "../Dashboard/Categories";
import Countries from "../Dashboard/Countries";
import UserObject from "../../../DataObjects/UserObject";
import ToastContext from "../../../Routes/ToastContext";

const CompleteLogin = () => {
  const { data, state, Form, formAction } = useFetcher();
  const { getUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const { flashToast } = useContext(ToastContext);

  useEffect(() => {
    flashToast(data?.success, data?.message);
    if (data?.success) {
      UserObject.getUser().refresh();
      navigate("/dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.data]);

  return (
    <ProtectedRoute>
      <Link to="/">
        <img
          src={Logo}
          style={{ height: "48px", marginTop: "50px" }}
          alt="FedaCash Logo"
          className="block lg:hidden"
        />
      </Link>
      <div className="mb-20">
        <h1
          className="mb-8 mt-14"
          style={{ fontSize: "44px", fontWeight: 800 }}
        >
          Welcome Back
        </h1>
        <p className="font-[500] text-[22px] leading-[36px] text-[#4b5768]">
          Hi,{" "}
          <strong>
            {getUser()?.first_name} {getUser()?.last_name}!
          </strong>{" "}
        </p>
        <p className="mt-2 text-gray-700 md:font-[33px] font-[16px]">
          Please provide the details below <br />
          to complete your signup
        </p>

        <Form action={formAction} method="post" className="mt-10">
          <Input
            disabled={true}
            name="email"
            error={data?.errors?.email}
            label="Email Address"
            style={{ textAlign: "right" }}
            normalize={true}
            placeholder={UserObject?.getUser()?.email}
          />
          <Input
            name="company_name"
            label="Business Name"
            normalize={true}
            error={data?.errors?.company_name}
          />
          <Countries
            label="Country"
            name="country_of_operation"
            placeholder="Country"
            error={data?.errors?.country}
          />
          <span className="inline-block mt-4"></span>
          <Categories
            top={true}
            label="Business Category"
            name="business_category"
            error={data?.errors?.business_category}
          />
          <Submit isLoading={state !== "idle"}>Proceed</Submit>
        </Form>
      </div>
    </ProtectedRoute>
  );
};

export default CompleteLogin;
