import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";

export function StatusDialog({ closeModal, isOpen, message }) {
  // console.log(message);
  let paymentStatus;
  function getMessage(message) {
    if (message?.success) {
      paymentStatus = "Request Succesful";
    } else if (message.errors) {
      if (Array.isArray(message.errors)) {
        paymentStatus = message.errors.join(", ");
      } else {
        const errorKeys = Object.keys(message.errors);
        const errorMessages = errorKeys.map(
          (key) => `${key}: ${message.errors[key].join(", ")}`
        );
        paymentStatus = errorMessages.join(", ");
      }
    } else {
      paymentStatus = "Payment Failed";
    }
  }
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-center text-lg font-medium leading-6 text-gray-900"
                  >
                    {paymentStatus}
                  </Dialog.Title>
                  <div className="flex flex-col items-center justify-center space-y-4 text-center">
                    {message.success ? (
                      <AiFillCheckCircle
                        fill="#590DA4"
                        color="#fff"
                        size={80}
                      />
                    ) : (
                      <AiFillCloseCircle
                        fill="#FF0000"
                        color="#fff"
                        size={80}
                      />
                    )}

                    <p>{message.message}</p>
                  </div>
                  <div className="mt-4 flex gap-4">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-[#590DA4] px-4 py-2 text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-[#590DA4] focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
