import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// import Swiper core and required modules
import { Autoplay } from 'swiper/modules';
// Import Swiper styles
import "swiper/css";
import "swiper/css/autoplay";

///
import { invoice } from "./constants";

const Solutions = () => {
  return (
    <Swiper modules={[Autoplay]} autoplay={{ delay: 6800 }}>
      {invoice.map((invoice, index) => (
        <SwiperSlide>
          <section key={index} className="px-6 mt-4">
            <div className="flex lg:flex-row flex-col gap-6 items-center justify-evenly">
              <img src={invoice.image} alt="invoice" />
              <div className="max-w-xl md:text-left text-center">
                <h3 className="text-fedacash-header md:text-2xl text-base font-bold mb-4">
                  {invoice.name}
                </h3>
                <p className="text-[#98A2B3] md:text-xl text-sm">
                  {invoice.description}
                </p>
              </div>
            </div>
          </section>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Solutions;
