import React from "react";
import { Dropdown } from "antd";
import { BsThreeDots } from "react-icons/bs";

import Panel from "../Panel";
import { Body, Column, ColumnHeader, Row, RowHeader, Table } from "./Table";
import { formatNaira } from "../../Utils/FormatMoney";
import { Link, useLoaderData } from "react-router-dom";
import moment from "moment";
import { RepayLoanButton } from "./RepayLoanButton";
import { useQuery } from "@tanstack/react-query";
import { mainLoanRequestQuery } from "../../../Services/Dashboard/LoanRequestService";

const LoanRepayment = () => {
  const { data, isLoading } = useQuery(mainLoanRequestQuery())
  const paidLoans = data?.paidLoans?.data?.data;
  // console.log(paidLoans);
  return (
    <Panel>
      <Table>
        <RowHeader>
          <ColumnHeader>descriptions</ColumnHeader>
          <ColumnHeader>Date Created</ColumnHeader>
          <ColumnHeader>Approved Amount</ColumnHeader>
          <ColumnHeader>Repayment Amount</ColumnHeader>
          <ColumnHeader>Status</ColumnHeader>
          <ColumnHeader>Repay</ColumnHeader>
          <ColumnHeader>Action</ColumnHeader>
        </RowHeader>
        {paidLoans?.length < 1 ? (
          "No Data Available"
        ) : (
          <Body data={paidLoans}>
            {(data) =>
              data.map((item) => (
                <Row key={item.id}>
                  <Column>{item.description}</Column>
                  <Column>
                    {moment(item.created_at).format(
                      "dddd, Do MMMM YYYY: h:mmA"
                    )}
                  </Column>
                  <Column>{formatNaira(item.approved_amount)}</Column>
                  <Column>{formatNaira(item.repayment_amount)}</Column>

                  <Column>
                    <span classname="text-xs text-green-600 px-2 py-1 rounded-md bg-gray-50">
                      {item.request_status}
                    </span>
                  </Column>
                  <Column>
                    <RepayLoanButton />
                  </Column>
                  <Column>
                    {" "}
                    <Dropdown
                      trigger={["click"]}
                      menu={{
                        items: [
                          {
                            key: "1",
                            label: (
                              <Link
                                to={`/dashboard/loan/request/${item.id}`}
                                className="flex items-center justify-start space-x-1 text-sm font-medium focus:border-none focus:outline-none"
                              >
                                <span>View More</span>
                              </Link>
                            ),
                          },
                        ],
                      }}
                      placement="bottomRight"
                      arrow={{ pointAtCenter: true }}
                    >
                      <div className="flex cursor-pointer items-center gap-x-1">
                        <BsThreeDots />
                      </div>
                    </Dropdown>
                  </Column>
                </Row>
              ))
            }
          </Body>
        )}
      </Table>
    </Panel>
  );
};

export default LoanRepayment;
