
import { useContext } from "react";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import { BsCheck, BsX } from "react-icons/bs";
///
import ToastContext from "../../../Routes/ToastContext";
import useModal from "../../../Hooks/useModal";
const Toast = () => {
  const {
    toast: { isSuccess, message },
    clearToast,
  } = useContext(ToastContext);

  const { Wrapper, Body, Footer } = useModal(false);
  let paymentStatus;
  function getMessage(message) {
    if (message?.success) {
      paymentStatus = "Request Succesful";
    } else if (message.errors) {
      if (Array.isArray(message.errors)) {
        paymentStatus = message.errors.join(", ");
      } else {
        const errorKeys = Object.keys(message.errors);
        const errorMessages = errorKeys.map(
          (key) => `${key}: ${message.errors[key].join(", ")}`
        );
        paymentStatus = errorMessages.join(", ");
      }
    } else {
      paymentStatus = "Payment Failed";
    }
  }
  if (!message) {
    return <></>;
  }
  return (
    <Wrapper zIndex="z-[999999]">
      <Body>
        <div className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
          <h3 className="text-center text-lg font-medium leading-6 text-gray-900">
            {paymentStatus}
          </h3>
          <div className="flex flex-col items-center justify-center space-y-4 text-center">
            {isSuccess ? (
              <AiFillCheckCircle fill="#590DA4" color="#fff" size={80} />
            ) : (
              <AiFillCloseCircle fill="#FF0000" color="#fff" size={80} />
            )}

            <p>{message}</p>
          </div>
          <div className="mt-4 flex gap-4">
            <button
              type="button"
              className="inline-flex w-full justify-center rounded-md border border-transparent bg-[#590DA4] px-4 py-2 text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-[#590DA4] focus-visible:ring-offset-2"
              onClick={clearToast}
            >
              {isSuccess ? "Close" : "Try Again"}
            </button>
          </div>
        </div>
      </Body>
    </Wrapper>
  );
};

export default Toast;
