import { redirect } from "react-router-dom";
import ResponseObject from "../../DataObjects/ResponseObject";
import UserObject from "../../DataObjects/UserObject";
import http from "../../Store/baseHttp";

export const SharedPlanLoader = async ({ request, params }) => {
  try {
    let SharedPlanResponse = await http.get(
      `/cooperative/packages/${params?.token}`
    );
    SharedPlanResponse = ResponseObject.from(SharedPlanResponse);
    return { SharedPlanResponse };
  } catch (error) {
    console.error(error);
    let SharedPlanResponse = ResponseObject.from(error?.response);
    return { SharedPlanResponse };
  }
};
