import ResponseObject from "../../DataObjects/ResponseObject";
import http from "../../Store/baseHttp";
import BaseController from "./BaseController";

class ProfileService extends BaseController {
  async post({ request }) {
    let formData = Object.fromEntries(await request.formData());
    try {
      let response = await http.post("/user/profile/update", formData, {
        headers: {
          "Content-type": "multipart/form-data",
        },
      });
      response = ResponseObject.from(response);
      return response;
    } catch (e) {
      return ResponseObject.from(e?.response);
    }
  }

  async put({ request }) {
    let formData = Object.fromEntries(await request.formData());
    try {
      let response = await http.put("/user/password/change", formData);
      response = ResponseObject.from(response);
      return response;
    } catch (e) {
      return ResponseObject.from(e?.response);
    }
  }
}

export default ProfileService;

export const ProfileLoader = async ({}) => {
  try {
    let bankDetails = await http.get("user/list-bank-details");
    bankDetails = ResponseObject.from(bankDetails);
    return { bankDetails };
  } catch (error) {
    console.error(error);
    return ResponseObject.from(error?.response);
  }
};

export const UpdateUserBankInfo = async (data,bankCode,accountName) => {
  let UpdateUserBankInfoResponse;
  try {
    let UpdateUserBankInfoRequest = await http.patch(
      "/user/update-bank-details",
      {...data, bank_code: bankCode, account_name:accountName}
    );
    // console.log(data, bankCode, accountName)
    UpdateUserBankInfoResponse = ResponseObject.from(UpdateUserBankInfoRequest);
    // console.log(UpdateUserBankInfoResponse);
    return { UpdateUserBankInfoResponse };
  } catch (error) {
    console.error(error);
    UpdateUserBankInfoResponse = ResponseObject.from(error?.response);
    return { UpdateUserBankInfoResponse };
  }
};
export const VertifyBankAccount = async (data,bankcode) => {
  try {
    let VertifyBankAccountRequest = await http.post(
      "/user/resolve/bankaccount",
      { account_number: data.account_number, bank_code:bankcode }
    );
    let VertifyBankAccountResponse = ResponseObject.from(VertifyBankAccountRequest);
    return { VertifyBankAccountResponse };
  } catch (error) {
    let VertifyBankAccountResponse = ResponseObject.from(error?.response);
    return { VertifyBankAccountResponse };
  }
};
