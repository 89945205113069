import http from "../../Store/baseHttp";
import ResponseObject from "../../DataObjects/ResponseObject";

export const InitiateWithdrawal = async (data) => {
  try {
    let withdrawalResponse = await http.post(
      "/cooperative/withdrawal/request",
      data
    );
    withdrawalResponse = ResponseObject.from(withdrawalResponse);

    // console.log(withdrawalResponse);
    return { withdrawalResponse };
  } catch (error) {
    console.error(error);
    let withdrawalResponse = ResponseObject.from(error?.response);
    return { withdrawalResponse };
  }
};
export const VertifyPassword = async (password) => {
  try {
    let passwordVertifyResponse = await http.post(
      "/cooperative/check/password",
      { input_password: password }
    );
    passwordVertifyResponse = ResponseObject.from(passwordVertifyResponse);
    return { passwordVertifyResponse };
  } catch (error) {
    let passwordVertifyResponse = ResponseObject.from(error?.response);
    return { passwordVertifyResponse };
  }
};
