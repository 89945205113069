import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import UserObject from "../DataObjects/UserObject";
import { removeFromStore } from "../Helpers/Storage";

const ProtectedRoute = ({ children }) => {
    const navigate = useNavigate()

    useEffect(() => {
        const user = UserObject.getUser()

        if (!user?.token) {
            navigate("/auth/login")
            return
        }

        if (!user.isVerified()) {
            removeFromStore('user')
            navigate("/auth/verify")
            return
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return children
}

export default ProtectedRoute;