import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

///file import
import product from "../../../Assets/images/full-product.png";
import cooperative from "../../../Assets/images/cooperative-hero-image.png";
// Import Swiper styles
import "swiper/css";
import "swiper/css/autoplay";

const Hero = () => {
  return (
    <section className="xl:px-2 px-4 hero-bg">
      <Swiper
        slidesPerView={1}
        loop={true}
        modules={[Autoplay]}
        autoplay={{ delay: 7000 }}
      >
        <SwiperSlide>
          <section className="flex flex-col justify-center items-center gap-6 py-6">
            <div className="flex-col justify-start items-center inline-flex">
              <div className="text-center text-fedacash-dark lg:text-4xl text-2xl font-bold leading-10">
                Turn your Invoices into
              </div>
              <div className="text-fedacash-header text-center lg:text-4xl text-2xl font-bold leading-10">
                Quick Cash{" "}
              </div>
              <div className="w-36 h-3.5 origin-top-left rotate-[-1.33deg] bg-fedacash-lime bg-opacity-60"></div>
            </div>
            <div className="max-w-2xl text-center md:text-lg text-xs font-medium">
              <p>
                Experience the wonder of turning your unpaid invoices into
                instant cash, ensuring your business stays on the move. Our
                user-friendly technology simplifies invoice and inventory
                financing, minimizing waiting times for payments and giving your
                working capital a boost.
              </p>
            </div>
            <section className="shadow">
              <img src={product} alt="Product screen" width="700" height="500"/>
            </section>
          </section>
        </SwiperSlide>
        <SwiperSlide>
          <section className="flex flex-col justify-center items-center gap-6 py-10">
            <div className="flex-col justify-start items-center inline-flex">
              <div className="text-center text-fedacash-dark lg:text-4xl text-2xl  font-bold leading-10">
                Increase your savings with our{" "}
              </div>
              <div className="text-fedacash-header h-12 text-center lg:text-4xl text-2xl  font-bold leading-10">
                Cooperatives
              </div>
              <div className="w-32 h-3.5 origin-top-left rotate-[-1.33deg] bg-fedacash-lime bg-opacity-60"></div>
            </div>
            <div className="max-w-2xl text-center md:text-lg text-xs font-medium">
              <p>
                We offer businesses across Nigeria a reliable platform to
                maximize group resources for growth. With an affordable
                membership fee. Unleash the potential of Cooperative Savings and
                Investment Income.
              </p>
            </div>
            <section className="shadow">
              <img src={cooperative} alt="Product screen" />
            </section>
          </section>
        </SwiperSlide>
      </Swiper>
    </section>
  );
};

export default Hero;
