import { Tab, Dialog, Transition } from "@headlessui/react";
import { useLoaderData } from "react-router-dom";

///file import
import { LoanOverview } from "./Overview";
import LoanRepayment from "./LoanRepayment";
import LoanTable from "./LoanTable";
import { useQuery } from "@tanstack/react-query";
import { mainLoanRequestQuery } from "../../../Services/Dashboard/LoanRequestService";

export const LoanTab = () => {
  // const data = useLoaderData();
  const { data, isLoading } = useQuery(mainLoanRequestQuery())

  // console.log('hello page',isLoading)
  return (
    <Tab.Group>
      <Tab.List
        className={`flex border justify-evenly h-16 text-xs md:text-base w-full bg-white font-bold`}
      >
        <Tab
          className={`ui-selected:border-fedacash-header ui-selected:border-b-2 w-1/2`}
        >
          Loan Request
        </Tab>
        <Tab
          className={`ui-selected:border-fedacash-header ui-selected:border-b-2 w-1/2`}
        >
          Loan Payment
        </Tab>
      </Tab.List>
      <Tab.Panels>
        <Tab.Panel className={`overview`}>
          <section>
            {data?.LoanRequestResponse?.data?.data?.length < 1 ? (
              <LoanOverview />
            ) : (
              <LoanTable loandata={data?.LoanRequestResponse?.data?.data} />
            )}
          </section>
        </Tab.Panel>
        <Tab.Panel>
          <section className="">
            <LoanRepayment />
          </section>
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  );
};
