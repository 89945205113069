import React from "react";
import { Dropdown } from "antd";
import { BsThreeDots } from "react-icons/bs";
import moment from "moment/moment";

import Panel from "../Panel";
import { formatNaira } from "../../Utils/FormatMoney";
// import { ViewLoanModal } from "./ViewLoanModal";
import {
  Body,
  Column,
  ColumnHeader,
  Row,
  RowHeader,
  Table,
} from "../Loan/Table";
import { SalaryAdvanceForm } from "./SalaryAdvanceForm";
import { useQuery } from "@tanstack/react-query";
import { SalaryRequestQuery } from "../../../Services/Dashboard/SalaryAdvanceService";

export const SalaryTable = ({}) => {
  const { data, isLoading } = useQuery(SalaryRequestQuery());
  // console.log(data, isLoading, "salary table");
  const salaryTableData = data?.LoanRequestResponse?.data?.data;
  // console.log("salaryTableData", salaryTableData);
  return (
    <Panel>
      <div className="flex justify-end my-3">
        <SalaryAdvanceForm />
      </div>
      <Table>
        <RowHeader>
          <ColumnHeader>Description</ColumnHeader>
          <ColumnHeader>Date Created</ColumnHeader>
          <ColumnHeader>Amount</ColumnHeader>
          <ColumnHeader>Approved Amount</ColumnHeader>
          <ColumnHeader>Repayment Amount</ColumnHeader>
          <ColumnHeader>Duration</ColumnHeader>
          <ColumnHeader>Status</ColumnHeader>
        </RowHeader>
        <Body data={salaryTableData}>
          {(data) => {
            if (isLoading) return <div>Loading..</div>;
            return data.map((item) => (
              <Row key={item.id}>
                <Column>{item.description}</Column>
                <Column>
                  {moment(item.created_at).format("dddd, Do MMMM YYYY: h:mmA")}
                </Column>

                <Column>{formatNaira(item.amount)}</Column>
                <Column>{formatNaira(item?.approved_amount)}</Column>
                <Column>{formatNaira(item?.repayment_amount)}</Column>
                <Column>{item.duration} days</Column>
                <Column>
                  <span classname="text-xs text-green-600 px-2 py-1 rounded-md bg-gray-50">
                    {item.request_status}
                  </span>
                </Column>
              </Row>
            ));
          }}
        </Body>
      </Table>
    </Panel>
  );
};
